import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { storeCustomerAction } from './storeCustomerSlice';

import * as customerApiLib from '../lib/storeCustomerApi';

function* handleList({ payload }) {
  try {
    const { lastKey, customerList } = yield call(customerApiLib.list, payload);

    yield put(storeCustomerAction.listSuccess({ lastKey, customerList }));
  } catch (err) {
    yield put(storeCustomerAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const customerInfo = yield call(customerApiLib.detail, payload);

    yield put(storeCustomerAction.detailSuccess({ customerInfo }));
  } catch (err) {
    yield put(storeCustomerAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(customerApiLib.add, payload);

    yield put(storeCustomerAction.addSuccess());
  } catch (err) {
    yield put(storeCustomerAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(customerApiLib.edit, payload);

    yield put(storeCustomerAction.editSuccess());
  } catch (err) {
    yield put(storeCustomerAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(customerApiLib.remove, payload);

    yield put(storeCustomerAction.removeSuccess());
  } catch (err) {
    yield put(storeCustomerAction.removeFailure('Remove Error'));
  }
}

function* handleSingupResend({ payload }) {
  try {
    yield call(customerApiLib.signupResend, payload);
    yield put(storeCustomerAction.signupResendSuccess());
  } catch (err) {
    yield put(storeCustomerAction.signupResendFailure('Singup Resend Error'));
  }
}

export function* watchList() {
  yield takeLatest(storeCustomerAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(storeCustomerAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(storeCustomerAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(storeCustomerAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(storeCustomerAction.remove, handleRemove);
}

export function* watchSignupResend() {
  yield takeLatest(storeCustomerAction.signupResend, handleSingupResend);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchSignupResend),
  ]);
}

export default rootSaga;
