import * as apiLib from './api';

export const list = async ({ deviceId, startKey, limit }) => {
  try {
    const data = {
      deviceId,
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/scrshot/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, scrshotList: result.scrshotList };
    } else {
      throw Object.assign(new Error('scrshot list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ deviceId, regDt }) => {
  try {
    const data = {
      deviceId,
      regDt,
    };

    const result = await apiLib.fetchOperationApi('/scrshot/detail', data);
    if (result.resultFlag) {
      return result.scrshotInfo;
    } else {
      throw Object.assign(new Error('scrshot detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ deviceId, regDt }) => {
  try {
    const data = {
      deviceId,
      regDt,
    };

    const result = await apiLib.fetchOperationApi('/scrshot/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('scrshot add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ deviceId, regDt }) => {
  try {
    const data = {
      deviceId,
      regDt,
    };

    const result = await apiLib.fetchOperationApi('/scrshot/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('scrshot edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ deviceId, regDt }) => {
  try {
    const data = { deviceId, regDt };

    const result = await apiLib.fetchOperationApi('/scrshot/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('scrshot remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const removeAll = async ({ deviceId, startKey, limit }) => {
  try {
    const data = {
      deviceId,
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/scrshot/remove-all', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('scrshot remove all error'), {
        status: 401,
      });
    }
  } catch (err) {
    throw err;
  }
};
