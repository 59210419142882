import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface SpacePayload {
  spaceId: string;
  spaceNm: string;
  filterValue: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface SpaceInfo {
  spaceId: string;
  spaceNm: string;
  regDt: string;
}

interface SpaceState {
  pageInfo: PageInfo;
  spaceList: Array<SpaceInfo>;
  spaceInfo: SpaceInfo;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const spaceInitialState: SpaceState = {
  pageInfo: {},
  spaceList: [],
  spaceInfo: {},
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: SpaceState, { payload }: PayloadAction<SpacePayload>) => {
    state.filterValue = payload.filterValue;
  },
  list: (state: SpaceState, { payload }: PayloadAction<SpacePayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: SpaceState, { payload }: PayloadAction<SpaceState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey =>
          pageKey.startKey &&
          pageKey.startKey.spaceId === state.pageInfo.curStartKey.spaceId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.spaceList = payload.spaceList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: SpaceState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: DeviceState) => {
    state.pageInfo = {};
    state.spaceList = [];
  },
  detail: (state: SpaceState, { payload }: PayloadAction<SpacePayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: SpaceState,
    { payload }: PayloadAction<SpaceState>,
  ) => {
    state.spaceInfo = payload.spaceInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: SpaceState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: SpaceState, action: PayloadAction<string>) => {
    state.spaceInfo = {};
  },
  add: (state: SpaceState, { payload }: PayloadAction<SpacePayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: SpaceState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: SpaceState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: SpaceState, { payload }: PayloadAction<SpacePayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: SpaceState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: SpaceState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: SpaceState, { payload }: PayloadAction<SpacePayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: SpaceState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: SpaceState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'space',
  initialState: spaceInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: SpaceState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectSpaceList = createDraftSafeSelector(
  (state: SpaceState) => state.spaceList,
  (state: SpaceState) => state.filterValue,
  (spaceList, filterValue) => {
    if (filterValue.trim() === '') {
      return spaceList.slice().sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
    }

    return spaceList
      .filter(space => {
        let nameFilter = false;

        if (space.spaceNm.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }

        return nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
  },
);

const selectSpaceInfo = createDraftSafeSelector(
  (state: SpaceState) => state.spaceInfo,
  spaceInfo => spaceInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: SpaceState) => state.actionResult,
  (state: SpaceState) => state.isLoading,
  (state: SpaceState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const spaceSelector = {
  pageInfo: state => selectPageInfo(state[SPACE]),
  spaceList: state => selectSpaceList(state[SPACE]),
  spaceInfo: state => selectSpaceInfo(state[SPACE]),
  status: state => selectStatus(state[SPACE]),
};

export const SPACE = slice.name;
export const spaceReducer = slice.reducer;
export const spaceAction = slice.actions;
