import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ActiveItemState {
  activePlaylistId: string;
  activeFrameId: string;
  activeLayerId: string;
  isLoading: boolean;
  error: string | null;
}

const ActiveItemInitialState: ActiveItemState = {
  activePlaylistId: '',
  activeFrameId: '',
  activeLayerId: '',
  isLoading: false,
  error: null,
};

const setActivePlaylistId = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.activePlaylistId = payload;
};

const initActiveItemState = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.activePlaylistId = '';
  state.activeFrameId = '';
  state.activeLayerId = '';
};

const setActiveFrameId = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.activeFrameId = payload;
};

const setActiveLayerId = (
  state: ActiveItemState,
  { payload }: PayloadAction<string>,
) => {
  state.activeLayerId = payload;
};

const slice = createSlice({
  name: 'activeItem',
  initialState: ActiveItemInitialState,
  reducers: {
    initActiveItemState,
    setActivePlaylistId,
    setActiveFrameId,
    setActiveLayerId,
  },
});

const selectAll = createDraftSafeSelector(
  (state: ActiveItemState) => state.activePlaylistId,
  (state: ActiveItemState) => state.activeFrameId,
  (state: ActiveItemState) => state.activeLayerId,
  (state: ActiveItemState) => state.isLoading,
  (state: ActiveItemState) => state.error,
  (activePlaylistId, activeFrameId, activeLayerId, isLoading, error) => ({
    activePlaylistId,
    activeFrameId,
    activeLayerId,
    isLoading,
    error,
  }),
);

const selectPlaylistId = createDraftSafeSelector(
  (state: ActiveItemState) => state.activePlaylistId,
  activePlaylistId => activePlaylistId,
);

const selectFrameId = createDraftSafeSelector(
  (state: ActiveItemState) => state.activeFrameId,
  activeFrameId => activeFrameId,
);

const selectLayerId = createDraftSafeSelector(
  (state: ActiveItemState) => state.activeLayerId,
  activeLayerId => activeLayerId,
);

export const activeItemSelector = {
  all: state => selectAll(state[ACTIVEITEM]),
  playlistId: state => selectPlaylistId(state[ACTIVEITEM]),
  frameId: state => selectFrameId(state[ACTIVEITEM]),
  layerId: state => selectLayerId(state[ACTIVEITEM]),
};

export const ACTIVEITEM = slice.name;
export const activeItemReducer = slice.reducer;
export const activeItemAction = slice.actions;
