import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { storeGoodsAction } from './storeGoodsSlice';

import * as goodsApiLib from '../lib/storeGoodsApi';

function* handleList({ payload }) {
  try {
    const { lastKey, goodsList } = yield call(goodsApiLib.list, payload);

    yield put(storeGoodsAction.listSuccess({ lastKey, goodsList }));
  } catch (err) {
    yield put(storeGoodsAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const goodsInfo = yield call(goodsApiLib.detail, payload);

    yield put(storeGoodsAction.detailSuccess({ goodsInfo }));
  } catch (err) {
    yield put(storeGoodsAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(goodsApiLib.add, payload);

    yield put(storeGoodsAction.addSuccess());
  } catch (err) {
    yield put(storeGoodsAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(goodsApiLib.edit, payload);

    yield put(storeGoodsAction.editSuccess());
  } catch (err) {
    yield put(storeGoodsAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(goodsApiLib.remove, payload);

    yield put(storeGoodsAction.removeSuccess());
  } catch (err) {
    yield put(storeGoodsAction.removeFailure('Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(storeGoodsAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(storeGoodsAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(storeGoodsAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(storeGoodsAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(storeGoodsAction.remove, handleRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
