import * as apiLib from './api';

export const list = async () => {
  try {
    const result = await apiLib.fetchOperationApi('/template/list', {});

    if (result.resultFlag) {
      return result.templateList;
    } else {
      throw Object.assign(new Error('template list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ templateId }) => {
  try {
    const data = {
      templateId,
    };

    const result = await apiLib.fetchOperationApi('/template/detail', data);

    if (result.resultFlag) {
      return result.templateInfo;
    } else {
      throw Object.assign(new Error('template list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ templateId }) => {
  try {
    const data ={
      templateId,
    };

    const result = await apiLib.fetchOperationApi('/template/add', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('template add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const addFrame = async ({ frame }) => {
  try {
    const data = {
      frame,
    };

    const result = await apiLib.fetchOperationApi('/template/frame-add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('frame add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ templateId, updateTemplate }) => {
  try {
    const data = {
      templateId,
      updateTemplate,
    };

    const result = await apiLib.fetchOperationApi('/template/edit', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('template edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ templateIdList }) => {
  try {
    const data = {
      templateIdList,
    };

    const result = await apiLib.fetchOperationApi('/template/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('template remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const thumbnailUpload = async ({ templateId, fileNm, fileType }) => {
  try {
    const data = { templateId, fileNm, fileType };

    const result = await apiLib.fetchOperationApi('/template/thumbnail-presign-upload', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('folder error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};