import * as apiLib from './api';

export const list = async (data) => {
  try {
    const result = await apiLib.fetchOperationApi('/store-customer/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, customerList: result.customerList };
    } else {
      throw Object.assign(new Error('customer list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ customerId }) => {
  try {
    const data = {
      customerId: customerId,
    };

    const result = await apiLib.fetchOperationApi('/store-customer/detail', data);
    if (result.resultFlag) {
      return result.customerInfo;
    } else {
      throw Object.assign(new Error('customer detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async (data) => {
  try {
    const result = await apiLib.fetchOperationApi('/store-customer/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async (data) => {
  try {
    const result = await apiLib.fetchOperationApi('/store-customer/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ customerId }) => {
  try {
    const data = { customerId };

    const result = await apiLib.fetchOperationApi('/store-customer/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const signupResend = async ({ customerId }) => {
  try {
    const data = { customerId };

    const result = await apiLib.fetchOperationApi(
      '/store-customer/signup-resend',
      data,
    );
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account signup resend error'), {
        status: 401,
      });
    }
  } catch (err) {
    throw err;
  }
};
