import { createAsyncThunk } from '@reduxjs/toolkit';
import * as apiLib from '../lib/api';

export const remoteUrl = createAsyncThunk('device/remoteUrl', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const data = {
      deviceId: payload.deviceId,
    };

    const result = await apiLib.fetchOperationApi('/device/remote-url', data);
    if (result.resultFlag) {
      return result.remoteUrl;
    } else {
      throw Object.assign(new Error('device remote url error'), { status: 401 });
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});
