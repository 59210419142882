import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ScrshotPayload {
  deviceId: string;
  regDt: string;
}

interface PageKeyInfo {
  deviceId: string;
  regDt: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface ScrshotInfo {
  deviceId: string;
  regDt: string;
  scrshotFile: string;
}

interface ScrshotState {
  pageInfo: PageInfo;
  scrshotList: Array<ScrshotInfo>;
  scrshotInfo: ScrshotInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const scrshotInitialState: ScrshotState = {
  pageInfo: {
    rowsPerPage: 10,
    curPage: 1,
    curStartKey: null,
    curLastKey: null,
    pageKeyList: [],
  },
  scrshotList: [],
  scrshotInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  list: (state: ScrshotState, { payload }: PayloadAction<ScrshotPayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (
    state: ScrshotState,
    { payload }: PayloadAction<ScrshotState>,
  ) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey =>
          pageKey.startKey &&
          pageKey.startKey.regDt === state.pageInfo.curStartKey.regDt,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.scrshotList = payload.scrshotList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: ScrshotState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: ScrshotState, action: PayloadAction<string>) => {
    state.pageInfo = {
      rowsPerPage: 10,
      curPage: 1,
      curStartKey: null,
      curLastKey: null,
      pageKeyList: [],
    };
    state.scrshotList = [];
  },
  detail: (state: ScrshotState, { payload }: PayloadAction<ScrshotPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: ScrshotState,
    { payload }: PayloadAction<ScrshotState>,
  ) => {
    state.scrshotInfo = payload.scrshotInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: ScrshotState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: ScrshotState, action: PayloadAction<string>) => {
    state.scrshotInfo = {};
  },
  add: (state: ScrshotState, { payload }: PayloadAction<ScrshotPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: ScrshotState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: ScrshotState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: ScrshotState, { payload }: PayloadAction<ScrshotPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: ScrshotState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: ScrshotState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: ScrshotState, { payload }: PayloadAction<ScrshotPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: ScrshotState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: ScrshotState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  removeAll: (state: ScrshotState, { payload }: PayloadAction<ScrshotPayload>) => {
    state.actionResult = 'REMOVE_ALL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeAllSuccess: (state: ScrshotState) => {
    state.actionResult = 'REMOVE_ALL_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeAllFailure: (state: ScrshotState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ALL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'scrshot',
  initialState: scrshotInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: PlaceState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectScrshotList = createDraftSafeSelector(
  (state: PlaceState) => state.scrshotList,
  scrshotList => scrshotList,
);

const selectScrshotInfo = createDraftSafeSelector(
  (state: PlaceState) => state.scrshotInfo,
  scrshotInfo => scrshotInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: ScrshotState) => state.actionResult,
  (state: ScrshotState) => state.isLoading,
  (state: ScrshotState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const scrshotSelector = {
  pageInfo: state => selectPageInfo(state[SCRSHOT]),
  scrshotList: state => selectScrshotList(state[SCRSHOT]),
  scrshotInfo: state => selectScrshotInfo(state[SCRSHOT]),
  status: state => selectStatus(state[SCRSHOT]),
};

export const SCRSHOT = slice.name;
export const scrshotReducer = slice.reducer;
export const scrshotAction = slice.actions;
