import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IPlaceAuthInfo {
  placeAuthId: string;
}

interface PlaceAuthState {
  placeAuthList: IPlaceAuthInfo[];
  placeAuthInfo: IPlaceAuthInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: PlaceAuthState = {
  placeAuthList: [],
  placeAuthInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.placeAuthList = [];
    state.placeAuthInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.placeAuthList = payload.placeAuthList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.placeAuthInfo = payload.placeAuthInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  detailClear: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.placeAuthInfo = {};
  },
  add: (state: PlaceAuthState, { payload }: PayloadAction<CsutomerPlaceAuthState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: PlaceAuthState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: PlaceAuthState, { payload }: PayloadAction<CsutomerPlaceAuthState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: PlaceAuthState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: PlaceAuthState, { payload }: PayloadAction<CsutomerPlaceAuthState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: PlaceAuthState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: PlaceAuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'placeAuth',
  initialState: InitialState,
  reducers: reducers,
});

const selectplaceAuthList = createDraftSafeSelector(
  (state: PlaceAuthState) => state.placeAuthList,
  (placeAuthList) => placeAuthList
);

const selectplaceAuthInfo = createDraftSafeSelector(
  (state: PlaceAuthState) => state.placeAuthInfo,
  placeAuthInfo => placeAuthInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PlaceAuthState) => state.isLogin,
  (state: PlaceAuthState) => state.actionResult,
  (state: PlaceAuthState) => state.isLoading,
  (state: PlaceAuthState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const placeAuthSelector = {
  placeAuthList: state => selectplaceAuthList(state[PLACEAUTH]),
  placeAuthInfo: state => selectplaceAuthInfo(state[PLACEAUTH]),
  status: state => selectStatus(state[PLACEAUTH]),
};

export const PLACEAUTH = slice.name;
export const placeAuthReducer = slice.reducer;
export const placeAuthAction = slice.actions;
