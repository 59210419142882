import * as apiLib from './api';

export const list = async () => {
    try {
        const result = await apiLib.fetchOperationApi('/package/list', {});

        if (result.resultFlag) {
            return result.packageList;
        } else {
            throw Object.assign(new Error('package list error'), { status: 401 });
        }
    } catch (err) {
        throw err;
    }
};

export const detail = async ({ packageId }) => {
    try {
        const data = {
            packageId,
        };
        console.log(data);

        const result = await apiLib.fetchOperationApi('/package/detail', data);

        if (result.resultFlag) {
            return result.packageInfo;
        } else {
            throw Object.assign(new Error('app detail error'), { status: 401 });
        }
    } catch (err) {
        throw err;
    }
};

export const add = async ({ appId }) => {
    try {
        const data = {
            appId,
        };

        const result = await apiLib.fetchOperationApi('/app/add', data);

        if (result.resultFlag) {
            return result;
        } else {
            throw Object.assign(new Error('app add error'), { status: 401 });
        }
    } catch (err) {
        throw err;
    }
};

export const edit = async ({ packageId, updatePackage }) => {
    try {
        const data = {
            packageId,
            updatePackage,
        };

        const result = await apiLib.fetchOperationApi('/package/edit', data);

        if (result.resultFlag) {
            return true;
        } else {
            throw Object.assign(new Error('app edit error'), { status: 401 });
        }
    } catch (err) {
        throw err;
    }
};

export const remove = async ({ appIdList }) => {
    try {
        const data = {
            appIdList,
        };

        const result = await apiLib.fetchOperationApi('/app/remove', data);

        if (result.resultFlag) {
            return true;
        } else {
            throw Object.assign(new Error('app remove error'), { status: 401 });
        }
    } catch (err) {
        throw err;
    }
};

export const updateSpaceIdList = async ({ packageId, spaceIdList }) => {
    try {
        const data = {
            packageId,
            spaceIdList,
        };

        const result = await apiLib.fetchOperationApi('/package/update/space-list', data);

        if (result.resultFlag) {
            return true;
        } else {
            throw Object.assign(new Error('app remove error'), { status: 401 });
        }
    } catch (err) {
        throw err;
    }
};
