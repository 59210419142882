import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IMenuInfo {
  menuId: string;
  menuPid: string;
  menuNm: string;
  menuDesc: string;
  useYn: string;
  regDt: string;
  regId: string;
  modDt: string;
  modId: string;
}

interface MenuState {
  menuList: IMenuInfo[];
  menuInfo: IMenuInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: MenuState = {
  menuList: [],
  menuInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.menuList = [];
    state.menuInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.menuList = payload.menuList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.menuInfo = payload.menuInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  detailClear: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.menuInfo = {};
  },
  add: (state: MenuState, { payload }: PayloadAction<CsutomerMenuState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: MenuState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: MenuState, { payload }: PayloadAction<CsutomerMenuState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: MenuState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: MenuState, { payload }: PayloadAction<CsutomerMenuState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: MenuState, { payload }: PayloadAction<MenuState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: MenuState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: MenuState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'menu',
  initialState: InitialState,
  reducers: reducers,
});

const selectmenuList = createDraftSafeSelector(
  (state: MenuState) => state.menuList,
  (menuList) => menuList
);

const selectmenuInfo = createDraftSafeSelector(
  (state: MenuState) => state.menuInfo,
  menuInfo => menuInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: MenuState) => state.isLogin,
  (state: MenuState) => state.actionResult,
  (state: MenuState) => state.isLoading,
  (state: MenuState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const menuSelector = {
  menuList: state => selectmenuList(state[MENU]),
  menuInfo: state => selectmenuInfo(state[MENU]),
  status: state => selectStatus(state[MENU]),
};

export const MENU = slice.name;
export const menuReducer = slice.reducer;
export const menuAction = slice.actions;
