import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface TemplatePayload {
  templateId: string;
  templateNm: string;
}

interface TemplateInfo {
  templateId: string;
  templateNm: string;
  regDt: string;
}

interface TemplateState {
  templateList: Array<TemplateInfo>;
  templateInfo: TemplateInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const templateInitialState: TemplateState = {
  templateList: [],
  templateInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  initTemplateState: (state: TemplateState) => {
    state.templateList = [];
    state.templateInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
  list: (state: TemplateState, { payload }: PayloadAction<TemplatePayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: TemplateState, { payload }: PayloadAction<TemplateState>) => {
    state.templateList = payload.templateList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: TemplateState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detail: (state: TemplateState, { payload }: PayloadAction<TemplatePayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: TemplateState,
    { payload }: PayloadAction<TemplateState>,
  ) => {
    state.templateInfo = payload.templateInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: TemplateState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: TemplateState, action: PayloadAction<string>) => {
    state.templateInfo = {};
  },
  add: (state: TemplateState, { payload }: PayloadAction<TemplatePayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: TemplateState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: TemplateState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: TemplateState, { payload }: PayloadAction<TemplatePayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: TemplateState, { payload: { templateInfo } }: PayloadAction<TemplatePayload>) => {
    state.templateInfo = templateInfo;
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: TemplateState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: TemplateState) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: TemplateState, { payload: { templateList } }: PayloadAction<TemplatePayload>) => {
    state.templateList = templateList;
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: TemplateState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  changeResolution: (state: TemplateState, { payload: { key, value } }: PayloadAction<TemplatePayload>) => {
    state.templateInfo[key] = value;
  },
  uploadThumbnail: (state: TemplateState) => {
    state.actionResult = 'ADD_THUMBnail';
    state.isLoading = true;
  },
  uploadThumbnailSuccess: (state: TemplateState, { payload: { templateInfo } }: PayloadAction<TemplatePayload>) => {
    state.templateInfo = templateInfo;
    state.actionResult = 'ADD_THUMBnail_OK';
    state.isLoading = false;
    state.error = null;
  },
  uploadThumbnailFailure: (state: TemplateState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_THUMBnail_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
};

const slice = createSlice({
  name: 'template',
  initialState: templateInitialState,
  reducers: reducers,
});

const selectTemplateList = createDraftSafeSelector(
  (state: TemplateState) => state.templateList,
  templateList => templateList,
);

const selectTemplateInfo = createDraftSafeSelector(
  (state: TemplateState) => state.templateInfo,
  templateInfo => templateInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: TemplateState) => state.actionResult,
  (state: TemplateState) => state.isLoading,
  (state: TemplateState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const templateSelector = {
  templateList: state => selectTemplateList(state[TEMPLATE]),
  templateInfo: state => selectTemplateInfo(state[TEMPLATE]),
  status: state => selectStatus(state[TEMPLATE]),
};

export const TEMPLATE = slice.name;
export const templateReducer = slice.reducer;
export const templateAction = slice.actions;
