import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface BrandPayload {
  brandId: string;
  spaceId: string;
  brandNm: string;
  filterValue: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface BrandInfo {
  brandId: string;
  spaceId: string;
  brandNm: string;
  regDt: string;
}

interface BrandState {
  pageInfo: PageInfo;
  brandList: Array<BrandInfo>;
  brandInfo: BrandInfo;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const brandInitialState: BrandState = {
  pageInfo: {},
  brandList: [],
  brandInfo: {},
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: BrandState, { payload }: PayloadAction<BrandPayload>) => {
    state.filterValue = payload.filterValue;
  },
  list: (state: BrandState, { payload }: PayloadAction<BrandPayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: BrandState, { payload }: PayloadAction<BrandState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey =>
          pageKey.startKey &&
          pageKey.startKey.brandId === state.pageInfo.curStartKey.brandId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.brandList = payload.brandList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: BrandState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: DeviceState) => {
    state.pageInfo = {};
    state.brandList = [];
  },
  detail: (state: BrandState, { payload }: PayloadAction<BrandPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: BrandState,
    { payload }: PayloadAction<BrandState>,
  ) => {
    state.brandInfo = payload.brandInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: BrandState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: BrandState, action: PayloadAction<string>) => {
    state.brandInfo = {};
  },
  add: (state: BrandState, { payload }: PayloadAction<BrandPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: BrandState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: BrandState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: BrandState, { payload }: PayloadAction<BrandPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: BrandState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: BrandState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: BrandState, { payload }: PayloadAction<BrandPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: BrandState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: BrandState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'brand',
  initialState: brandInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: BrandState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectBrandList = createDraftSafeSelector(
  (state: BrandState) => state.brandList,
  (state: BrandState) => state.filterValue,
  (brandList, filterValue) => {
    if (filterValue.trim() === '') {
      return brandList.slice().sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
    }

    return brandList
      .filter(brand => {
        let nameFilter = false;

        if (brand.brandNm.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }

        return nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
  },
);

const selectBrandInfo = createDraftSafeSelector(
  (state: BrandState) => state.brandInfo,
  brandInfo => brandInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: BrandState) => state.actionResult,
  (state: BrandState) => state.isLoading,
  (state: BrandState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const storeBrandSelector = {
  pageInfo: state => selectPageInfo(state[STORE_BRAND]),
  brandList: state => selectBrandList(state[STORE_BRAND]),
  brandInfo: state => selectBrandInfo(state[STORE_BRAND]),
  status: state => selectStatus(state[STORE_BRAND]),
};

export const STORE_BRAND = slice.name;
export const storeBrandReducer = slice.reducer;
export const storeBrandAction = slice.actions;
