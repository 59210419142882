import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface PlacePayload {
  placeId: string;
  placeNm: string;
  filterValue: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface PlaceInfo {
  placeId: string;
  placeNm: string;
  placeEmail: string;
  imagePath: string;
  imageSize: string;
  regDt: string;
}

interface PlaceState {
  pageInfo: PageInfo;
  placeList: Array<PlaceInfo>;
  placeInfo: PlaceInfo;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const placeInitialState: PlaceState = {
  pageInfo: {},
  placeList: [],
  placeInfo: {},
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.filterValue = payload.filterValue;
  },
  list: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey => pageKey.startKey && pageKey.startKey.placeId === state.pageInfo.curStartKey.placeId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.placeList = payload.placeList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: PlaceState) => {
    state.pageInfo = {};
    state.placeList = [];
  },
  detail: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeInfo = payload.placeInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: PlaceState, action: PayloadAction<string>) => {
    state.placeInfo = {};
  },
  add: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: PlaceState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: PlaceState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: PlaceState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'place',
  initialState: placeInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: PlaceState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectPlaceList = createDraftSafeSelector(
  (state: PlaceState) => state.placeList,
  (state: PlaceState) => state.filterValue,
  (placeList, filterValue) => {
    if (filterValue.trim() === '') {
      return placeList.slice().sort((l, r) => {
        return dayjs(l.regDt).isSame(r.regDt) ? 0 : dayjs(l.regDt).isAfter(r.regDt) ? -1 : 1;
      });
    }

    return placeList
      .filter(place => {
        let nameFilter = false;

        if (place.placeNm?.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }

        return nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return dayjs(l.regDt).isSame(r.regDt) ? 0 : dayjs(l.regDt).isAfter(r.regDt) ? -1 : 1;
      });
  },
);

const selectPlaceInfo = createDraftSafeSelector(
  (state: PlaceState) => state.placeInfo,
  placeInfo => placeInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PlaceState) => state.actionResult,
  (state: PlaceState) => state.isLoading,
  (state: PlaceState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const placeSelector = {
  pageInfo: state => selectPageInfo(state[PLACE]),
  placeList: state => selectPlaceList(state[PLACE]),
  placeInfo: state => selectPlaceInfo(state[PLACE]),
  status: state => selectStatus(state[PLACE]),
};

export const PLACE = slice.name;
export const placeReducer = slice.reducer;
export const placeAction = slice.actions;
