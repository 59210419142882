import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as appApiLib from '../lib/appApi';

import { appAction } from './appSlice';

function* handleList({ payload }) {
  try {
    const appList = yield call(appApiLib.list, payload);

    yield put(appAction.listSuccess({ appList }));
  } catch (err) {
    yield put(appAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload: { appId } }) {
  try {
    const appInfo = yield call(appApiLib.detail, { appId });

    yield put(appAction.detailSuccess({ appInfo }));
  } catch (err) {
    console.log(err);
    yield put(appAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload: { appId } }) {
  try {
    yield call(appApiLib.add, { appId });

    yield put(appAction.addSuccess());

    const proxy = new Date().getTime();
    sessionStorage.setItem(
      proxy,
      JSON.stringify({ appId }),
    );

  } catch (err) {
    yield put(appAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload: { appId, updateApp } }) {
  try {
    yield call(appApiLib.edit, { appId, updateApp });

    const appInfo = yield call(appApiLib.detail, { appId });

    yield put(appAction.editSuccess({ appInfo }));
  } catch (err) {
    console.log(err);
    yield put(appAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload: { appIdList } }) {
  try {
    yield call(appApiLib.remove, { appIdList });

    const appList = yield call(appApiLib.list, { });

    yield put(appAction.removeSuccess({ appList }));
  } catch (err) {
    console.log(err);
    yield put(appAction.removeFailure('Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(appAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(appAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(appAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(appAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(appAction.remove, handleRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
