import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { storeBrandAction } from './storeBrandSlice';

import * as brandApiLib from '../lib/storeBrandApi';

function* handleList({ payload }) {
  try {
    const { lastKey, brandList } = yield call(brandApiLib.list, payload);

    yield put(storeBrandAction.listSuccess({ lastKey, brandList }));
  } catch (err) {
    yield put(storeBrandAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const brandInfo = yield call(brandApiLib.detail, payload);

    yield put(storeBrandAction.detailSuccess({ brandInfo }));
  } catch (err) {
    yield put(storeBrandAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(brandApiLib.add, payload);

    yield put(storeBrandAction.addSuccess());
  } catch (err) {
    yield put(storeBrandAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(brandApiLib.edit, payload);

    yield put(storeBrandAction.editSuccess());
  } catch (err) {
    yield put(storeBrandAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(brandApiLib.remove, payload);

    yield put(storeBrandAction.removeSuccess());
  } catch (err) {
    yield put(storeBrandAction.removeFailure('Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(storeBrandAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(storeBrandAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(storeBrandAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(storeBrandAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(storeBrandAction.remove, handleRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
