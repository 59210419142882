import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { storeShopAction } from './storeShopSlice';

import * as shopApiLib from '../lib/storeShopApi';

function* handleList({ payload }) {
  try {
    const { lastKey, shopList } = yield call(shopApiLib.list, payload);

    yield put(storeShopAction.listSuccess({ lastKey, shopList }));
  } catch (err) {
    yield put(storeShopAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.detail, payload);

    yield put(storeShopAction.detailSuccess({ shopInfo }));
  } catch (err) {
    yield put(storeShopAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(shopApiLib.add, payload);

    yield put(storeShopAction.addSuccess());
  } catch (err) {
    yield put(storeShopAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(shopApiLib.edit, payload);

    yield put(storeShopAction.editSuccess());
  } catch (err) {
    yield put(storeShopAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(shopApiLib.remove, payload);

    yield put(storeShopAction.removeSuccess());
  } catch (err) {
    yield put(storeShopAction.removeFailure('Remove Error'));
  }
}

function* memberList({ payload }) {
  try {
    const { memberList, accountList } = yield call(
      shopApiLib.memberList,
      payload,
    );

    yield put(storeShopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(storeShopAction.memberListFailure('Member List Error'));
  }
}

function* memberDetail({ payload }) {
  try {
    const memberInfo = yield call(shopApiLib.memberDetail, payload);

    yield put(storeShopAction.memberDetailSuccess({ memberInfo }));
  } catch (err) {
    yield put(storeShopAction.memberDetailFailure('Member Detail Error'));
  }
}

function* memberAdd({ payload }) {
  try {
    yield call(shopApiLib.memberAdd, payload);
    yield put(storeShopAction.memberAddSuccess());

    const { memberList, accountList } = yield call(
      shopApiLib.memberList,
      payload,
    );
    yield put(storeShopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(storeShopAction.memberAddFailure('Member Add Error'));
  }
}

function* memberEdit({ payload }) {
  try {
    yield call(shopApiLib.memberEdit, payload);
    yield put(storeShopAction.memberEditSuccess());

    const { memberList, accountList } = yield call(
      shopApiLib.memberList,
      payload,
    );
    yield put(storeShopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(storeShopAction.memberEditFailure('Member Edit Error'));
  }
}

function* memberRemove({ payload }) {
  try {
    yield call(shopApiLib.memberRemove, payload);
    yield put(storeShopAction.memberRemoveSuccess());

    const { memberList, accountList } = yield call(
      shopApiLib.memberList,
      payload,
    );
    yield put(storeShopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(storeShopAction.memberRemoveFailure('Member Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(storeShopAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(storeShopAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(storeShopAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(storeShopAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(storeShopAction.remove, handleRemove);
}

export function* watchMemberList() {
  yield takeLatest(storeShopAction.memberList, memberList);
}

export function* watchMemberDetail() {
  yield takeLatest(storeShopAction.memberDetail, memberDetail);
}

export function* watchMemberAdd() {
  yield takeLatest(storeShopAction.memberAdd, memberAdd);
}

export function* watchMemberEdit() {
  yield takeLatest(storeShopAction.memberEdit, memberEdit);
}

export function* watchMemberRemove() {
  yield takeLatest(storeShopAction.memberRemove, memberRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchMemberList),
    fork(watchMemberDetail),
    fork(watchMemberAdd),
    fork(watchMemberEdit),
    fork(watchMemberRemove),
  ]);
}

export default rootSaga;
