import * as apiLib from './api';

export const list = async ({ brandId, startKey, limit }) => {
  try {
    const data = {
      brandId,
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/store-category/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, categoryList: result.categoryList };
    } else {
      throw Object.assign(new Error('category list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const allList = async ({ brandId, categoryId }) => {
  try {
    const data = {
      brandId,
      categoryId,
    };

    const result = await apiLib.fetchOperationApi(
      '/store-category/all-list',
      data,
    );
    if (result.resultFlag) {
      return result.categoryList;
    } else {
      throw Object.assign(new Error('category list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ categoryId }) => {
  try {
    const data = {
      categoryId: categoryId,
    };

    const result = await apiLib.fetchOperationApi(
      '/store-category/detail',
      data,
    );
    if (result.resultFlag) {
      return result.categoryInfo;
    } else {
      throw Object.assign(new Error('category detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ categoryPid, categoryNm, spaceId, brandId }) => {
  try {
    const data = {
      categoryPid,
      categoryNm,
      spaceId,
      brandId,
    };

    const result = await apiLib.fetchOperationApi('/store-category/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('category add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  categoryPid,
  categoryId,
  categoryNm,
  spaceId,
  brandId,
  useYn,
  delYn,
}) => {
  try {
    const data = {
      categoryPid,
      categoryId,
      categoryNm,
      spaceId,
      brandId,
      useYn,
      delYn,
    };

    const result = await apiLib.fetchOperationApi('/store-category/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('category edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ categoryId }) => {
  try {
    const data = { categoryId };

    const result = await apiLib.fetchOperationApi(
      '/store-category/remove',
      data,
    );
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('category remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
