import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { accountAction } from './accountSlice';

import * as accountApiLib from '../lib/accountApi';

function* handleList({ payload }) {
  try {
    const accountList = yield call(accountApiLib.list, payload);
    accountList.sort((lhs, rhs) => new Date(lhs.regDt).valueOf() - new Date(rhs.regDt).valueOf());
    yield put(accountAction.listSuccess({ accountList }));
  } catch (err) {
    yield put(accountAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const accountInfo = yield call(accountApiLib.detail, payload);

    yield put(accountAction.detailSuccess({ accountInfo }));
  } catch (err) {
    yield put(accountAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(accountApiLib.add, payload);

    yield put(accountAction.addSuccess());
  } catch (err) {
    yield put(accountAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(accountApiLib.edit, payload);

    yield put(accountAction.editSuccess());
  } catch (err) {
    yield put(accountAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(accountApiLib.remove, payload);

    yield put(accountAction.removeSuccess());
  } catch (err) {
    yield put(accountAction.removeFailure('Remove Error'));
  }
}

function* handleSingupResend({ payload }) {
  try {
    yield call(accountApiLib.signupResend, payload);

    yield put(accountAction.signupResendSuccess());
  } catch (err) {
    yield put(accountAction.signupResendFailure('Singup Resend Error'));
  }
}

export function* watchList() {
  yield takeLatest(accountAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(accountAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(accountAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(accountAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(accountAction.remove, handleRemove);
}

export function* watchSignupResend() {
  yield takeLatest(accountAction.signupResend, handleSingupResend);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchSignupResend),
  ]);
}

export default rootSaga;
