import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface CategoryPayload {
  brandId: string;
  categoryId: string;
  categoryNm: string;
  filterValue: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface CategoryInfo {
  brandId: string;
  categoryId: string;
  categoryNm: string;
  regDt: string;
}

interface CategoryState {
  pageInfo: PageInfo;
  categoryList: Array<CategoryInfo>;
  categoryAllList: Array<CategoryInfo>;
  categoryInfo: CategoryInfo;
  categoryId: string;
  categoryNm: string;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const categoryInitialState: CategoryState = {
  pageInfo: {},
  categoryList: [],
  categoryAllList: [],
  categoryInfo: {},
  categoryId: '',
  categoryNm: '',
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryPayload>,
  ) => {
    state.filterValue = payload.filterValue;
  },
  child: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryPayload>,
  ) => {
    state.categoryId = payload.categoryId;
    state.categoryNm = payload.categoryNm;
  },
  list: (state: CategoryState, { payload }: PayloadAction<CategoryPayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryState>,
  ) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey =>
          pageKey.startKey &&
          pageKey.startKey.categoryId === state.pageInfo.curStartKey.categoryId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.categoryList = payload.categoryList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: CategoryState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: DeviceState) => {
    state.pageInfo = {};
    state.categoryList = [];
  },
  allList: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryPayload>,
  ) => {
    state.categoryId = payload.brandId;
    state.categoryNm = payload.brandNm;
    state.actionResult = 'ALL_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  allListSuccess: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryState>,
  ) => {
    state.categoryAllList = payload.categoryAllList;
    state.actionResult = 'ALL_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  allListFailure: (state: CategoryState, action: PayloadAction<string>) => {
    state.actionResult = 'ALL_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  allListClear: (state: CategoryState, action: PayloadAction<string>) => {
    state.categoryAllList = [];
  },
  detail: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryPayload>,
  ) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryState>,
  ) => {
    state.categoryInfo = payload.categoryInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: CategoryState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: CategoryState, action: PayloadAction<string>) => {
    state.categoryInfo = {};
  },
  add: (state: CategoryState, { payload }: PayloadAction<CategoryPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: CategoryState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: CategoryState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: CategoryState, { payload }: PayloadAction<CategoryPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: CategoryState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: CategoryState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (
    state: CategoryState,
    { payload }: PayloadAction<CategoryPayload>,
  ) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: CategoryState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: CategoryState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'category',
  initialState: categoryInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: CategoryState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectCategoryList = createDraftSafeSelector(
  (state: CategoryState) => state.categoryList,
  (state: CategoryState) => state.filterValue,
  (categoryList, filterValue) => {
    if (filterValue.trim() === '') {
      return categoryList.slice().sort((l, r) => {
        return l.categoryOrder === r.categoryOrder
          ? 0
          : l.categoryOrder > r.categoryOrder
          ? -1
          : 1;
      });
    }

    return categoryList
      .filter(category => {
        let nameFilter = false;

        if (category.categoryNm.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }

        return nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return l.categoryOrder === r.categoryOrder
          ? 0
          : l.categoryOrder > r.categoryOrder
          ? -1
          : 1;
      });
  },
);

const selectCategoryAllList = createDraftSafeSelector(
  (state: CategoryState) => state.categoryAllList,
  categoryAllList => categoryAllList,
);

const selectCategoryDepthList = createDraftSafeSelector(
  (state: CategoryState) => state.categoryAllList,
  (state: CategoryState) => state.categoryId,
  (categoryAllList, lastDepthId) => {
    const recursiveFn = (depthList, categoryId) => {
      const parentList = categoryAllList.filter(category => {
        return category.categoryId === categoryId;
      });

      if (parentList.length > 0) {
        let newDepthList = depthList.slice();
        newDepthList.unshift(parentList[0]);

        if (
          newDepthList.length < 10 &&
          parentList[0].categoryPid !== parentList[0].brandId
        ) {
          return recursiveFn(newDepthList, parentList[0].categoryPid);
        }

        return newDepthList;
      }

      return depthList;
    };

    return recursiveFn([], lastDepthId);
  },
);

const selectCategoryChildList = createDraftSafeSelector(
  (state: CategoryState) => state.categoryAllList,
  (state: CategoryState) => state.categoryId,
  (categoryAllList, lastDepthId) => {
    return categoryAllList.filter(category => {
      return category.categoryPid === lastDepthId;
    });
  },
);

const selectCategoryInfo = createDraftSafeSelector(
  (state: CategoryState) => state.categoryInfo,
  categoryInfo => categoryInfo,
);

const selectCategoryDepthInfo = createDraftSafeSelector(
  (state: CategoryState) => state.categoryId,
  (state: CategoryState) => state.categoryNm,
  (categoryId, categoryNm) => ({
    categoryId,
    categoryNm,
  }),
);

const selectStatus = createDraftSafeSelector(
  (state: CategoryState) => state.actionResult,
  (state: CategoryState) => state.isLoading,
  (state: CategoryState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const storeCategorySelector = {
  pageInfo: state => selectPageInfo(state[STORE_CATEGORY]),
  categoryList: state => selectCategoryList(state[STORE_CATEGORY]),
  categoryAllList: state => selectCategoryAllList(state[STORE_CATEGORY]),
  categoryDepthList: state => selectCategoryDepthList(state[STORE_CATEGORY]),
  categoryChildList: state => selectCategoryChildList(state[STORE_CATEGORY]),
  categoryInfo: state => selectCategoryInfo(state[STORE_CATEGORY]),
  categoryDepthInfo: state => selectCategoryDepthInfo(state[STORE_CATEGORY]),
  status: state => selectStatus(state[STORE_CATEGORY]),
};

export const STORE_CATEGORY = slice.name;
export const storeCategoryReducer = slice.reducer;
export const storeCategoryAction = slice.actions;
