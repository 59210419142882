import * as apiLib from './api';

export const list = async ({ spaceId, startKey, limit }) => {
  try {
    const data = {
      spaceId,
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/place/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, placeList: result.placeList };
    } else {
      throw Object.assign(new Error('place list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ placeId }) => {
  try {
    const data = {
      placeId: placeId,
    };

    const result = await apiLib.fetchOperationApi('/place/detail', data);
    if (result.resultFlag) {
      return result.placeInfo;
    } else {
      throw Object.assign(new Error('place detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  spaceId,
  placeDepth,
  placeNm,
  placeType,
  pricing,
  pricingDt,
  pricingEndDt,
  licenseSeat,
  storageUse,
  storageMax,
  playlistUse,
  playlistMax,
  castUse,
  castMax,
}) => {
  try {
    const data = {
      spaceId,
      placeDepth,
      placeNm,
      placeType,
      pricing,
      pricingDt,
      pricingEndDt,
      licenseSeat,
      storageUse,
      storageMax,
      playlistUse,
      playlistMax,
      castUse,
      castMax,
    };

    const result = await apiLib.fetchOperationApi('/place/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('place add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  spaceId,
  placeId,
  placeDepth,
  placeNm,
  placeType,
  pricing,
  pricingDt,
  pricingEndDt,
  licenseSeat,
  storageUse,
  storageMax,
  playlistUse,
  playlistMax,
  castUse,
  castMax,
  useYn,
  delYn,
}) => {
  try {
    const data = {
      spaceId,
      placeId,
      placeDepth,
      placeNm,
      placeType,
      pricing,
      pricingDt,
      pricingEndDt,
      licenseSeat,
      storageUse,
      storageMax,
      playlistUse,
      playlistMax,
      castUse,
      castMax,
      useYn,
      delYn,
    };

    const result = await apiLib.fetchOperationApi('/place/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('place edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ placeId }) => {
  try {
    const data = { placeId };

    const result = await apiLib.fetchOperationApi('/place/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('place remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
