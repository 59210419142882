import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as packageApiLib from '../lib/packageApi';

import { packageAction } from './packageSlice';

function* handleList({ payload }) {
    try {
        const packageList = yield call(packageApiLib.list, payload);
        console.log(packageList);

        yield put(packageAction.listSuccess({ packageList }));
    } catch (err) {
        yield put(packageAction.listFailure('List Error'));
    }
}

function* handleDetail({ payload: { packageId } }) {
    try {
        const packageInfo = yield call(packageApiLib.detail, { packageId });
        console.log(packageInfo);

        yield put(packageAction.detailSuccess({ packageInfo }));
    } catch (err) {
        console.log(err);
        yield put(packageAction.detailFailure('Detail Error'));
    }
}

function* handleAdd({ payload: { appId } }) {
    try {
        yield call(packageApiLib.add, { appId });

        yield put(packageAction.addSuccess());

        const proxy = new Date().getTime();
        sessionStorage.setItem(proxy, JSON.stringify({ appId }));
    } catch (err) {
        yield put(packageAction.addFailure('Add Error'));
    }
}

function* handleEdit({ payload: { packageId, updatePackage } }) {
    try {
        yield call(packageApiLib.edit, { packageId, updatePackage });

        const packageInfo = yield call(packageApiLib.detail, { packageId });

        yield put(packageAction.editSuccess({ packageInfo }));
    } catch (err) {
        console.log(err);
        yield put(packageAction.editFailure('Edit Error'));
    }
}

function* handleRemove({ payload: { appIdList } }) {
    try {
        yield call(packageApiLib.remove, { appIdList });

        const appList = yield call(packageApiLib.list, {});

        yield put(packageAction.removeSuccess({ appList }));
    } catch (err) {
        console.log(err);
        yield put(packageAction.removeFailure('Remove Error'));
    }
}

function* handleUpdateSpaceIdList({ payload: { packageId, spaceIdList } }) {
    try {
        console.log('packageId', packageId, spaceIdList);
        yield call(packageApiLib.updateSpaceIdList, { packageId, spaceIdList });

        const packageList = yield call(packageApiLib.list, {});

        yield put(packageAction.updateSpaceIdListSuccess({ packageList }));
    } catch (err) {
        console.log(err);
        yield put(packageAction.updateSpaceIdListFailure('Remove Error'));
    }
}

export function* watchList() {
    yield takeLatest(packageAction.list, handleList);
}

export function* watchDetail() {
    yield takeLatest(packageAction.detail, handleDetail);
}

export function* watchAdd() {
    yield takeLatest(packageAction.add, handleAdd);
}

export function* watchEdit() {
    yield takeLatest(packageAction.edit, handleEdit);
}

export function* watchRemove() {
    yield takeLatest(packageAction.remove, handleRemove);
}

export function* watchSpaceIdList() {
    yield takeLatest(packageAction.updateSpaceIdList, handleUpdateSpaceIdList);
}

function* rootSaga() {
    yield all([fork(watchList), fork(watchDetail), fork(watchAdd), fork(watchEdit), fork(watchRemove), fork(watchSpaceIdList)]);
}

export default rootSaga;
