import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface AccountPayload {
  placeId: string;
  accountId: string;
  accountNm: string;
  filterValue: string;
}

interface AccountInfo {
  placeId: string;
  viewPlaceId: string;
  accountId: string;
  accountNm: string;
  accountEmail: string;
  imagePath: string;
  imageSize: string;
  regDt: string;
}

interface AccountState {
  accountList: Array<AccountInfo>;
  accountInfo: AccountInfo;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const accountInitialState: AccountState = {
  accountList: [],
  accountInfo: {},
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.filterValue = payload.filterValue;
  },
  list: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (
    state: AccountState,
    { payload }: PayloadAction<AccountState>,
  ) => {
    state.accountList = payload.accountList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detail: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: AccountState,
    { payload }: PayloadAction<AccountState>,
  ) => {
    state.accountInfo = payload.accountInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: AccountState, action: PayloadAction<string>) => {
    state.accountInfo = {};
  },
  add: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: AccountState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: AccountState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: AccountState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  signupResend: (
    state: AccountState,
    { payload }: PayloadAction<AccountPayload>,
  ) => {
    state.actionResult = 'SINGUP_RESEND_REQ';
    state.isLoading = true;
    state.error = null;
  },
  signupResendSuccess: (state: AccountState) => {
    state.actionResult = 'SINGUP_RESEND_OK';
    state.isLoading = false;
    state.error = null;
  },
  signupResendFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'SINGUP_RESEND_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'account',
  initialState: accountInitialState,
  reducers: reducers,
});

const selectAccountList = createDraftSafeSelector(
  (state: AccountState) => state.accountList,
  (state: AccountState) => state.filterValue,
  (accountList, filterValue) => {
    if (filterValue.trim() === '') {
      return accountList.slice().sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
    }

    return accountList
      .filter(account => {
        let aliasFilter = false;
        let emailFilter = false;
        let nameFilter = false;

        if (account.accountAlias.indexOf(filterValue) >= 0) {
          aliasFilter = true;
        }

        if (account.accountEmail.indexOf(filterValue) >= 0) {
          emailFilter = true;
        }

        if (account.accountNm.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }

        return aliasFilter || emailFilter || nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
  },
);

const selectAccountInfo = createDraftSafeSelector(
  (state: AccountState) => state.accountInfo,
  accountInfo => accountInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: AccountState) => state.actionResult,
  (state: AccountState) => state.isLoading,
  (state: AccountState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const accountSelector = {
  accountList: state => selectAccountList(state[ACCOUNT]),
  accountInfo: state => selectAccountInfo(state[ACCOUNT]),
  status: state => selectStatus(state[ACCOUNT]),
};

export const ACCOUNT = slice.name;
export const accountReducer = slice.reducer;
export const accountAction = slice.actions;
