import React, { useEffect, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

const loading = () => <div className="text-center"></div>;

const Auth = props => {
  return (
    <Suspense fallback={loading()}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
        <Outlet />
      </GoogleOAuthProvider>
    </Suspense>
  );
};

export default Auth;
