import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { spaceAction } from './spaceSlice';

import * as spaceApiLib from '../lib/spaceApi';

function* handleList({ payload }) {
  try {
    const { lastKey, spaceList } = yield call(spaceApiLib.list, payload);
    spaceList.sort((lhs, rhs) => new Date(lhs.regDt).valueOf() - new Date(rhs.regDt).valueOf());
    yield put(spaceAction.listSuccess({ lastKey, spaceList }));
  } catch (err) {
    yield put(spaceAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const spaceInfo = yield call(spaceApiLib.detail, payload);

    yield put(spaceAction.detailSuccess({ spaceInfo }));
  } catch (err) {
    yield put(spaceAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(spaceApiLib.add, payload);

    yield put(spaceAction.addSuccess());
  } catch (err) {
    yield put(spaceAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(spaceApiLib.edit, payload);

    yield put(spaceAction.editSuccess());
  } catch (err) {
    yield put(spaceAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(spaceApiLib.remove, payload);

    yield put(spaceAction.removeSuccess());
  } catch (err) {
    yield put(spaceAction.removeFailure('Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(spaceAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(spaceAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(spaceAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(spaceAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(spaceAction.remove, handleRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
