import * as apiLib from './api';

export const list = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/account/list', data);
    if (result.resultFlag) {
      return result.accountList;
    } else {
      throw Object.assign(new Error('account list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ accountId }) => {
  try {
    const data = {
      accountId: accountId,
    };

    const result = await apiLib.fetchOperationApi('/account/detail', data);
    if (result.resultFlag) {
      return result.accountInfo;
    } else {
      throw Object.assign(new Error('account detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ accountEmail, accountNm, accountPw }) => {
  try {
    const data = {
      accountEmail,
      accountNm,
      accountPw,
    };

    const result = await apiLib.fetchOperationApi('/account/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  spaceId,
  placeId,
  viewPlaceId,
  accountId,
  accountAlias,
  accountEmail,
  accountNm,
  accountPw,
  accountEmailVerifyYn,
  useYn,
  delYn,
}) => {
  try {
    const data = {
      spaceId,
      placeId,
      viewPlaceId,
      accountAlias,
      accountId,
      accountNm,
      accountPw,
      accountEmailVerifyYn,
      useYn,
      delYn,
    };

    const result = await apiLib.fetchOperationApi('/account/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ accountId }) => {
  try {
    const data = { accountId };

    const result = await apiLib.fetchOperationApi('/account/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const signupResend = async ({ accountId }) => {
  try {
    const data = { accountId };

    const result = await apiLib.fetchOperationApi('/account/signup-resend', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account signup resend error'), {
        status: 401,
      });
    }
  } catch (err) {
    throw err;
  }
};
