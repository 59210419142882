import * as apiLib from './api';

export const list = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/admin/list', data);
    if (result.resultFlag) {
      return result.adminList;
    } else {
      throw Object.assign(new Error('admin list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ adminId }) => {
  try {
    const data = {
      adminId: adminId,
    };

    const result = await apiLib.fetchOperationApi('/admin/detail', data);
    if (result.resultFlag) {
      return result.adminInfo;
    } else {
      throw Object.assign(new Error('admin detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  adminType,
  adminEmail,
  adminNm,
  adminPw,
  adminPwRe,
}) => {
  try {
    const data = { adminType, adminEmail, adminNm, adminPw, adminPwRe };

    const result = await apiLib.fetchOperationApi('/admin/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('admin add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  adminId,
  adminType,
  adminEmail,
  adminNm,
  adminPw,
  adminPwRe,
}) => {
  try {
    const data = {
      adminId,
      adminType,
      adminEmail,
      adminNm,
      adminPw,
      adminPwRe,
    };

    const result = await apiLib.fetchOperationApi('/admin/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('admin edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ adminId }) => {
  try {
    const data = { adminId };

    const result = await apiLib.fetchOperationApi('/admin/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('admin remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const passwordChange = async ({ loginPw }) => {
  try {
    const data = {
      adminPw: loginPw,
    };

    const result = await apiLib.fetchOperationApi(
      '/admin/password-change',
      data,
    );
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('admin password change error'), {
        status: 401,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const upload = async ({ adminId, file }) => {
  try {
    const uploadData = new FormData();
    uploadData.append('adminId', adminId);
    uploadData.append('uploadFile', file, file.name);

    const result = await apiLib.fetchInteractionMultipartApi(
      '/admin/image-upload',
      uploadData,
    );
    if (result.resultFlag) {
      return result.adminInfo;
    } else {
      throw Object.assign(new Error('upload error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
