import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

import dayjs from 'dayjs';

interface BoardPayload {
  placeId: string;
  placeNm: string;
  accountId: string;
  accountNm: string;
  filterValue: string;
  userNm: string;
}

interface NoticeInfo {
  noticeId: string;
  noticeTitle: string;
  noticeDetail: string;
  regDt: string;
}

interface ReleaseInfo {
  releaseVersion: string;
  releaseTitle: string;
  releaseDetail: string;
  regDt: string;
}

interface QnaInfo {
  placeId: string;
  placeNm: string;
  qId: string;
  qTitle: string;
  qNm: string;
  regDt: string;
}
interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}
interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}
interface QuestionInfo {
  placeId: string;
  placeNm: string;
  qId: string;
  qTitle: string;
  qNm: string;
  regDt: string;
  useYn: string;
  userNm: string;
}
interface GuideInfo {
  guideId: string;
  guideTitle: string;
  regDt: string;
}

interface NewsInfo {
  newsId: string;
  newsTitle?: string;
  newsText?: string;
  regDt?: string;
  regId?: string;
  modDt?: string;
  modId?: string;
}

interface FaqInfo {
  faqId: string;
  faqTitle?: string;
  faqText?: string;
  regDt?: string;
  regId?: string;
  modDt?: string;
  modId?: string;
}

interface BoardState {
  pageInfo: PageInfo;
  noticeList: Array<NoticeInfo>;
  noticeInfo: NoticeInfo;
  releaseList: Array<ReleaseInfo>;
  releaseInfo: ReleaseInfo;
  qnaList: Array<QnaInfo>;
  questionList: Array<QuestionInfo>;
  qnaInfo: QnaInfo;
  questionInfo: QuestionInfo;
  guideList: Array<GuideInfo>;
  guideInfo: GuideInfo;
  newsList: Array<NewsInfo>;
  newsInfo: NewsInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
  filterValue: string;
}

const boardInitialState: BoardState = {
  pageInfo: {},
  noticeList: [],
  noticeInfo: {},
  releaseList: [],
  releaseInfo: {},
  qnaList: [],
  questionList: [],
  qnaInfo: {},
  questionInfo: {},
  guideList: [],
  guideInfo: {},
  newsList: [],
  newsInfo: {},
  faqList: [],
  faqInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
  filterValue: '',
};

const reducers = {
  filter: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.filterValue = payload.filterValue;
  },
  noticeList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NOTICE_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.noticeList = payload.noticeList.sort((a, b) => (dayjs(a.regDt).isAfter(dayjs(b.regDt)) ? -1 : 1));
    state.actionResult = 'NOTICE_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  noticeDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NOTICE_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeDetailSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.noticeInfo = payload.noticeInfo;
    state.actionResult = 'NOTICE_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  noticeDetailClear: (state: AccountState) => {
    state.noticeInfo = {};
  },
  noticeAdd: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NOTICE_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeAddSuccess: (state: AccountState) => {
    state.actionResult = 'NOTICE_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  noticeFileAdd: (state: BoardState, { payload }: PayloadAction<NoticeInfo>) => {
    state.actionResult = 'NOTICE_FILE_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeFileAddSuccess: (state: AccountState) => {
    state.actionResult = 'NOTICE_FILE_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeFileAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_FILE_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  noticeEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NOTICE_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeEditSuccess: (state: AccountState) => {
    state.actionResult = 'NOTICE_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  noticeRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NOTICE_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  noticeRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'NOTICE_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  noticeRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NOTICE_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  releaseList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'RELEASE_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  releaseListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.releaseList = payload.releaseList.sort((a, b) => (dayjs(a.regDt).isAfter(dayjs(b.regDt)) ? -1 : 1));
    state.actionResult = 'RELEASE_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  releaseListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'RELEASE_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  releaseAdd: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'RELEASE_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  releaseAddSuccess: (state: AccountState) => {
    state.actionResult = 'RELEASE_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  releaseAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'RELEASE_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  releaseEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'RELEASE_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  releaseEditSuccess: (state: AccountState) => {
    state.actionResult = 'RELEASE_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  releaseEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'RELEASE_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  releaseDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'RELEASE_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  releaseDetailSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.releaseInfo = payload.releaseInfo;
    state.actionResult = 'RELEASE_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  releaseDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'RELEASE_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  releaseDetailClear: (state: AccountState) => {
    state.releaseInfo = {};
  },
  releaseRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'RELEASE_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  releaseRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'RELEASE_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  releaseRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'RELEASE_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  questionList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'QUESTION_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaListClear: (state: BoardState, action: PayloadAction<string>) => {
    state.pageInfo = {};
    state.qnaList = [];
  },
  questionListClear: (state: BoardState, action: PayloadAction<string>) => {
    state.pageInfo = {};
    state.questionList = [];
  },
  qnaListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.qnaList = payload.qnaList.sort((a, b) => (dayjs(a.qDt).isAfter(dayjs(b.qDt)) ? -1 : 1));
    state.actionResult = 'QNA_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  questionListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey => pageKey.startKey && pageKey.startKey.customerId === state.pageInfo.curStartKey.customerId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }
    state.questionList = payload.questionList.sort((a, b) => (dayjs(a.qDt).isAfter(dayjs(b.qDt)) ? -1 : 1));
    state.actionResult = 'QUESTION_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  questionListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QUESTION_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  questionDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QUESTION_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaDetailSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.qnaInfo = payload.qnaInfo;
    state.actionResult = 'QNA_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  questionDetailSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.questionInfo = payload.questionInfo;
    state.actionResult = 'QUESTION_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  questionDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QUESTION_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaDetailClear: (state: AccountState) => {
    state.qnaInfo = {};
  },
  questionDetailClear: (state: AccountState) => {
    state.questionInfo = {};
  },
  questionAdd: (state: BoardState, action: PayloadAction<oardPayload>) => {
    state.actionResult = 'QUESTION_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  questionAddSuccess: (state: AccountState) => {
    state.actionResult = 'QUESTION_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  questionAddFailure: (state: Boardstate, action: PayloadAction<string>) => {
    state.actionResult = 'QUESTION_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaAdd: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaAddSuccess: (state: AccountState) => {
    state.actionResult = 'QNA_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  questionEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QUESTION_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  questionEditSuccess: (state: AccountState) => {
    state.actionResult = 'QUESTION_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  questionEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QUESTION_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaEditSuccess: (state: AccountState) => {
    state.actionResult = 'QNA_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  qnaRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QNA_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  qnaRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'QNA_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  qnaRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QNA_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  questionRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'QUESTION_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  questionRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'QUESTION_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  questionRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'QUESTION_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'GUIDE_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.guideList = payload.guideList.sort((a, b) => (dayjs(a.regDt).isAfter(dayjs(b.regDt)) ? -1 : 1));
    state.actionResult = 'GUIDE_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideAdd: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'GUIDE_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideAddSuccess: (state: AccountState) => {
    state.actionResult = 'GUIDE_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'GUIDE_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideDetailSuccess: (
    state: AccountState,
    { payload: { guideInfo, guideFileList, fileIdList } }: PayloadAction<BoardState>,
  ) => {
    state.guideInfo = {
      ...guideInfo,
      fileIdList,
      guideFileList,
    };
    state.actionResult = 'GUIDE_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'GUIDE_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideEditSuccess: (state: AccountState) => {
    state.actionResult = 'GUIDE_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'GUIDE_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'GUIDE_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideFileList: (state: BoardState, { payload }: PayloadAction<String>) => {
    state.actionResult = 'GUIDE_FILE_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideFileListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.guideInfo.guideFileList = payload.guideFileList;
    state.actionResult = 'GUIDE_FILE_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideFileListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_FILE_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  //guideFile을 state에서만 추가
  guideFileStateAdd: (state: BoardState, { payload }: PayloadAction<GuideInfo>) => {
    state.guideInfo.guideFileList.push(payload);
    state.actionResult = 'GUIDE_FILE_STATE_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },

  guideFileAdd: (state: BoardState, { payload }: PayloadAction<GuideInfo>) => {
    state.actionResult = 'GUIDE_FILE_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideFileAddSuccess: (state: AccountState) => {
    state.actionResult = 'GUIDE_FILE_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideFileAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_FILE_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideFileDownloadRequest: (state: BoardState, { payload }: PayloadAction<GuideInfo>) => {
    state.actionResult = 'DOWNLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  guideFileDownloadSuccess: (state: AccountState) => {
    state.actionResult = 'GUIDE_FILE_DOWNLOAD_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideFileDownloadFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_FILE_DOWNLOAD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  guideFileRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'GUIDE_FILE_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  guideFileRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'GUIDE_FILE_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  guideFileRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'GUIDE_FILE_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  //guideFile의 state만 제거 (S3 삭제 X)
  guideFileStateRemove: (state: AccountState, { payload }: PayloadAction<String>) => {
    //state.guideInfo.guideFileList의 state 삭제
    let deleteFileIndex = state.guideInfo.guideFileList.findIndex(file => file.fileId === payload.guideFileId);
    state.guideInfo.guideFileList.splice(deleteFileIndex, 1);

    //state.guideInfo.fileIdList의 state 삭제
    deleteFileIndex = state.guideInfo.fileIdList.findIndex(fileId => fileId === payload.guideFileId);
    state.guideInfo.fileIdList.splice(deleteFileIndex, 1);

    state.actionResult = 'GUIDE_FILE_STATE_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },

  //guideFile의 state를 원복
  guideFileStateRestore: (state: AccountState, { payload }: PayloadAction<String>) => {
    //state.guideInfo.guideFileList 원복
    state.guideInfo.guideFileList = payload.guideFileList;
    //state.guideInfo.fileIdList 원복
    state.guideInfo.fileIdList = payload.fileIdList;

    state.actionResult = 'GUIDE_FILE_STATE_RESTORE_OK';
    state.isLoading = false;
    state.error = '';
  },

  guideDetailClear: (state: AccountState) => {
    state.guideInfo = {};
  },

  newsList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NEWS_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  newsListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.newsList = payload.newsList;
    state.actionResult = 'NEWS_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  newsListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NEWS_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  newsDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NEWS_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  newsDetailSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.newsInfo = payload.newsInfo;
    state.actionResult = 'NEWS_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  newsDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NEWS_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  newsDetailClear: (state: AccountState) => {
    state.newsInfo = {};
  },
  newsAdd: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NEWS_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  newsAddSuccess: (state: AccountState) => {
    state.actionResult = 'NEWS_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  newsAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NEWS_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  newsEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NEWS_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  newsEditSuccess: (state: AccountState) => {
    state.actionResult = 'NEWS_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  newsEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NEWS_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  newsRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'NEWS_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  newsRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'NEWS_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  newsRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'NEWS_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  faqList: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'FAQ_LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  faqListSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.faqList = payload.faqList;
    state.actionResult = 'FAQ_LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  faqListFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'FAQ_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  faqDetail: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'FAQ_DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  faqDetailSuccess: (state: AccountState, { payload }: PayloadAction<BoardState>) => {
    state.faqInfo = payload.faqInfo;
    state.actionResult = 'FAQ_DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  faqDetailFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'FAQ_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  faqDetailClear: (state: AccountState) => {
    state.faqInfo = {};
  },
  faqAdd: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'FAQ_ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  faqAddSuccess: (state: AccountState) => {
    state.actionResult = 'FAQ_ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  faqAddFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'FAQ_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  faqEdit: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'FAQ_EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  faqEditSuccess: (state: AccountState) => {
    state.actionResult = 'FAQ_EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  faqEditFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'FAQ_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  faqRemove: (state: BoardState, { payload }: PayloadAction<BoardPayload>) => {
    state.actionResult = 'FAQ_REMOVE_REQ';
    state.isLoading = true;
    state.error = '';
  },
  faqRemoveSuccess: (state: AccountState) => {
    state.actionResult = 'FAQ_REMOVE_OK';
    state.isLoading = false;
    state.error = '';
  },
  faqRemoveFailure: (state: BoardState, action: PayloadAction<string>) => {
    state.actionResult = 'FAQ_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: BoardState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'board',
  initialState: boardInitialState,
  reducers: reducers,
});

const selectNoticeList = createDraftSafeSelector(
  (state: BoardState) => state.noticeList,
  noticeList => noticeList,
);

const selectNoticeInfo = createDraftSafeSelector(
  (state: BoardState) => state.noticeInfo,
  noticeInfo => noticeInfo,
);

const selectReleaseList = createDraftSafeSelector(
  (state: BoardState) => state.releaseList,
  releaseList => releaseList,
);

const selectReleaseInfo = createDraftSafeSelector(
  (state: BoardState) => state.releaseInfo,
  releaseInfo => releaseInfo,
);

const selectQnaList = createDraftSafeSelector(
  (state: BoardState) => state.qnaList,
  qnaList => qnaList,
);

const selectPageInfo = createDraftSafeSelector(
  (state: BoardState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectQuestionList = createDraftSafeSelector(
  (state: BoardState) => state.questionList,
  (state: BoardState) => state.filterValue,
  (questionList, filterValue) => {
    if (filterValue.trim() === '') {
      return questionList.slice().sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
    }

    return questionList
      .filter(question => {
        let nameFilter = false;

        if (question.placeNm.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }
        return nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
  },
);

const selectQnaInfo = createDraftSafeSelector(
  (state: BoardState) => state.qnaInfo,
  qnaInfo => qnaInfo,
);

const selectQuestionInfo = createDraftSafeSelector(
  (state: BoardState) => state.questionInfo,
  questionInfo => questionInfo,
);

const selectGuideList = createDraftSafeSelector(
  (state: BoardState) => state.guideList,
  guideList => guideList,
);

const selectGuideInfo = createDraftSafeSelector(
  (state: BoardState) => state.guideInfo,
  guideInfo => guideInfo,
);

const selectNewsList = createDraftSafeSelector(
  (state: BoardState) => state.newsList,
  newsList => newsList,
);

const selectNewsInfo = createDraftSafeSelector(
  (state: BoardState) => state.newsInfo,
  newsInfo => newsInfo,
);

const selectFaqList = createDraftSafeSelector(
  (state: BoardState) => state.faqList,
  faqList => faqList,
);

const selectFaqInfo = createDraftSafeSelector(
  (state: BoardState) => state.faqInfo,
  faqInfo => faqInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: BoardState) => state.actionResult,
  (state: BoardState) => state.isLoading,
  (state: BoardState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const boardSelector = {
  noticeList: state => selectNoticeList(state[BOARD]),
  noticeInfo: state => selectNoticeInfo(state[BOARD]),
  releaseList: state => selectReleaseList(state[BOARD]),
  releaseInfo: state => selectReleaseInfo(state[BOARD]),
  qnaList: state => selectQnaList(state[BOARD]),
  questionList: state => selectQuestionList(state[BOARD]),
  qnaInfo: state => selectQnaInfo(state[BOARD]),
  questionInfo: state => selectQuestionInfo(state[BOARD]),
  guideList: state => selectGuideList(state[BOARD]),
  guideInfo: state => selectGuideInfo(state[BOARD]),
  newsList: state => selectNewsList(state[BOARD]),
  newsInfo: state => selectNewsInfo(state[BOARD]),
  faqList: state => selectFaqList(state[BOARD]),
  faqInfo: state => selectFaqInfo(state[BOARD]),
  status: state => selectStatus(state[BOARD]),
  pageInfo: state => selectPageInfo(state[BOARD]),
};

export const BOARD = slice.name;
export const boardReducer = slice.reducer;
export const boardAction = slice.actions;
