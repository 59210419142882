import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { placeAction } from './placeSlice';

import * as placeApiLib from '../lib/placeApi';

function* handleList({ payload }) {
  try {
    const { lastKey, placeList } = yield call(placeApiLib.list, payload);
    placeList.sort((lhs, rhs) => new Date(lhs.regDt).valueOf() - new Date(rhs.regDt).valueOf());
    yield put(placeAction.listSuccess({ lastKey, placeList }));
  } catch (err) {
    yield put(placeAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const placeInfo = yield call(placeApiLib.detail, payload);

    yield put(placeAction.detailSuccess({ placeInfo }));
  } catch (err) {
    yield put(placeAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(placeApiLib.add, payload);

    yield put(placeAction.addSuccess());
  } catch (err) {
    yield put(placeAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(placeApiLib.edit, payload);

    yield put(placeAction.editSuccess());
  } catch (err) {
    yield put(placeAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(placeApiLib.remove, payload);

    yield put(placeAction.removeSuccess());
  } catch (err) {
    yield put(placeAction.removeFailure('Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(placeAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(placeAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(placeAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(placeAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(placeAction.remove, handleRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
