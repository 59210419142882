import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as apiLib from '../lib/api';
import * as templateApiLib from '../lib/templateApi';
import * as layerApiLib from '../lib/layerApi';

import { templateAction } from './templateSlice';
import { layerAction } from './layerSlice';
import { activeItemAction } from './activeItemSlice';
import { layerHistoryAction } from './layerHistorySlice';

function* handleList({ payload }) {
  try {
    const templateList = yield call(templateApiLib.list, payload);

    yield put(templateAction.listSuccess({ templateList }));
  } catch (err) {
    yield put(templateAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload: { templateId } }) {
  try {
    const templateInfo = yield call(templateApiLib.detail, { templateId });

    yield put(activeItemAction.setActiveFrameId(templateInfo.templateId));

    yield put(layerAction.getLayerList(templateInfo.templateId));
    yield put(layerHistoryAction.initLayerHistory());

    yield put(templateAction.detailSuccess({ templateInfo }));
  } catch (err) {
    console.log(err);
    yield put(templateAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload: { templateId, layer } }) {
  try {
    yield call(templateApiLib.add, { templateId });
    yield call(layerApiLib.getAddLayer, { ...layer });

    yield put(templateAction.addSuccess());

    const proxy = new Date().getTime();
    sessionStorage.setItem(
      proxy,
      JSON.stringify({ templateId }),
    );
  } catch (err) {
    yield put(templateAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload: { templateId, updateTemplate } }) {
  try {
    yield call(templateApiLib.edit, { templateId, updateTemplate });

    const templateInfo = yield call(templateApiLib.detail, { templateId });

    yield put(templateAction.editSuccess({ templateInfo }));
  } catch (err) {
    console.log(err);
    yield put(templateAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload: { templateIdList } }) {
  try {
    yield call(templateApiLib.remove, { templateIdList });

    const templateList = yield call(templateApiLib.list, { });

    yield put(templateAction.removeSuccess({ templateList }));
  } catch (err) {
    console.log(err);
    yield put(templateAction.removeFailure('Remove Error'));
  }
}

function* uploadThumbnail({ payload: { templateId, file, thumbnail, onUploadProgress } }) {
  try {
    let data = {
      templateId,
      fileNm: file.name,
      fileType: file.type,
    };

    if (thumbnail !== '') {
      const thumbNm = thumbnail.name;
      const thumbType = thumbnail.type;
      
      data = {
        ...data,
        fileType: thumbType,
        fileNm: thumbNm,
      }
    }

    const result = yield call(templateApiLib.thumbnailUpload, data);

    const presignUrl = result.url;
    const uploadData = new FormData();
    uploadData.append('file', file, file.name);
    yield call(apiLib.fetchUploadApi, presignUrl, file, onUploadProgress, 0);

    const templateInfo = yield call(templateApiLib.detail, { templateId });
    yield put(templateAction.uploadThumbnailSuccess({ templateInfo }));
  } catch (error) {
    yield put(templateAction.uploadThumbnailFailure('uploadThumbnail error'));
  }
}

export function* watchList() {
  yield takeLatest(templateAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(templateAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(templateAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(templateAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(templateAction.remove, handleRemove);
}

export function* watchUploadThumbnail() {
  yield takeLatest(templateAction.uploadThumbnail, uploadThumbnail);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchUploadThumbnail),
  ]);
}

export default rootSaga;
