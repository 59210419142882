import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface GoodsPayload {
  goodsId: string;
  spaceId: string;
  goodsNm: string;
  filterValue: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface GoodsInfo {
  goodsId: string;
  spaceId: string;
  goodsNm: string;
  regDt: string;
}

interface GoodsState {
  pageInfo: PageInfo;
  goodsList: Array<GoodsInfo>;
  goodsInfo: GoodsInfo;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const goodsInitialState: GoodsState = {
  pageInfo: {},
  goodsList: [],
  goodsInfo: {},
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.filterValue = payload.filterValue;
  },
  list: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey =>
          pageKey.startKey &&
          pageKey.startKey.goodsId === state.pageInfo.curStartKey.goodsId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.goodsList = payload.goodsList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: GoodsState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: DeviceState) => {
    state.pageInfo = {};
    state.goodsList = [];
  },
  detail: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: GoodsState,
    { payload }: PayloadAction<GoodsState>,
  ) => {
    state.goodsInfo = payload.goodsInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: GoodsState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: GoodsState, action: PayloadAction<string>) => {
    state.goodsInfo = {};
  },
  add: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: GoodsState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: GoodsState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: GoodsState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: GoodsState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: GoodsState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: GoodsState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'goods',
  initialState: goodsInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: GoodsState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goodsList,
  (state: GoodsState) => state.filterValue,
  (goodsList, filterValue) => {
    if (filterValue.trim() === '') {
      return goodsList.slice().sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
    }

    return goodsList
      .filter(goods => {
        let nameFilter = false;

        if (goods.goodsNm.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }

        return nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
  },
);

const selectGoodsInfo = createDraftSafeSelector(
  (state: GoodsState) => state.goodsInfo,
  goodsInfo => goodsInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: GoodsState) => state.actionResult,
  (state: GoodsState) => state.isLoading,
  (state: GoodsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const storeGoodsSelector = {
  pageInfo: state => selectPageInfo(state[STORE_GOODS]),
  goodsList: state => selectGoodsList(state[STORE_GOODS]),
  goodsInfo: state => selectGoodsInfo(state[STORE_GOODS]),
  status: state => selectStatus(state[STORE_GOODS]),
};

export const STORE_GOODS = slice.name;
export const storeGoodsReducer = slice.reducer;
export const storeGoodsAction = slice.actions;
