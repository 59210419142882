import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { spaceMemberAction } from './spaceMemberSlice';

import * as memberApiLib from '../lib/spaceMemberApi';

function* handleList({ payload }) {
  try {
    const { memberList, accountList } = yield call(memberApiLib.list, payload);

    yield put(spaceMemberAction.listSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(spaceMemberAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const memberInfo = yield call(
      memberApiLib.detail,
      payload,
    );

    yield put(spaceMemberAction.detailSuccess({ memberInfo }));
  } catch (err) {
    yield put(spaceMemberAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(memberApiLib.add, payload);

    yield put(spaceMemberAction.addSuccess());
  } catch (err) {
    yield put(spaceMemberAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(memberApiLib.edit, payload);

    yield put(spaceMemberAction.editSuccess());
  } catch (err) {
    yield put(spaceMemberAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(memberApiLib.remove, payload);

    yield put(spaceMemberAction.removeSuccess());
  } catch (err) {
    yield put(spaceMemberAction.removeFailure('Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(spaceMemberAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(spaceMemberAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(spaceMemberAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(spaceMemberAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(spaceMemberAction.remove, handleRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
