import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface LayerDataState {
  layerId: string;
  layerOrder: number;
  width: number;
  height: number;
  x: number;
  y: number;
  title: number;
  color: string;
  baseYn: string;
  muteYn: string;
  frameLinkId: string;
  lockYn: string;
}

interface ClipboardState {
  copiedLayer: LayerDataState;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: ClipboardState = {
  copiedLayer: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    copyLayer(state: ClipboardState, { payload: activeLayer }: PayloadAction<LayerDataState>) {
      state.copiedLayer = activeLayer;

      // state.actionResult = 'COPY_LAYER_REQ';
      // state.isLoading = true;
      // state.error = null;
    },
    copyLayerSuccess(state: ClipboardState, { payload }: PayloadAction<String>) {
      // state.actionResult = 'COPY_LAYER_OK';
      // state.isLoading = false;
      // state.error = null;
    },
    copyLayerFailure(state: ClipboardState, { payload }: PayloadAction<String>) {
      // state.actionResult = 'COPY_LAYER_ERR';
      // state.isLoading = false;
      // state.error = payload;
    },
  },
});

const selectCopiedLayer = createDraftSafeSelector(
  (state: ClipboardState) => state.copiedLayer,
  copiedLayer => copiedLayer,
);

const selectStatus = createDraftSafeSelector(
  (state: LayerState) => state.actionResult,
  (state: LayerState) => state.isLoading,
  (state: LayerState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const clipboardSelector = {
  copiedLayer: state => selectCopiedLayer(state[CLIPBOARD]),
  status: state => selectStatus(state[CLIPBOARD]),
};

export const CLIPBOARD = slice.name;
export const clipboardReducer = slice.reducer;
export const clipboardAction = slice.actions;
