import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import * as apiLib from '../lib/api';
import * as layerContentsApiLib from '../lib/layerContentsApi';
import * as contentsApiLib from '../lib/publicContentsApi';

import { activeItemSelector } from './activeItemSlice';
import { layerContentsAction } from './templateLayerContentsSlice';
import { contentsAction } from './publicContentsSlice';

function* getLayerContents({ payload: layerId }) {
  try {
    const playlistId = yield select(activeItemSelector.playlistId);
    const frameId = yield select(activeItemSelector.frameId);
    const layerContents = yield call(layerContentsApiLib.getLayerContents, layerId);
    if (layerContents) {
      if (layerContents.contentsType === 'media') {
        const mediaList = layerContents.contentsData.mediaList;
        for (let i = 0; i < mediaList.length; i++) {
          const data = {
            fileId: mediaList[i].fileId,
          };
          let result = yield call(contentsApiLib.getStorageFileExistenceCheck, data);
          let file = result.fileData;
          if (file === undefined) { //file이 DB에 존재하지 않는 경우, filePath 수정
            file = {
              fileId: mediaList[i].fileData.fileId,
              fileNm: mediaList[i].fileData.fileNm,
              filePath: "",
              fileSize: mediaList[i].fileData.fileSize,
              fileType: mediaList[i].fileData.fileType,
            }

            mediaList[i].fileData = file;
            layerContents.contentsFileList[i] = file;
          }
        }
      }
      yield put(layerContentsAction.getLayerContentsSuccess(layerContents));
    } else {
      yield put(layerContentsAction.getLayerContentsSuccess({ playlistId, frameId, layerId }));
    }
  } catch (error) {
    yield put(layerContentsAction.getLayerContentsFailure('getLayerContents error'));
  }
}

function* saveLayerContents({ payload: { newLayerContents, onUploadProgress }}) {
  try {
    const playlistId = yield select(activeItemSelector.playlistId);
    const frameId = yield select(activeItemSelector.frameId);
    const layerId = yield select(activeItemSelector.layerId);

    if (newLayerContents.contentsType === 'media') {
      yield put(contentsAction.storageFileAddRequest());
      for (let i = 0; i< newLayerContents.contentsData.mediaList.length; i++) {
        let mediaFile = newLayerContents.contentsData.mediaList[i];
        try {
          // file DB에 저장되어 있는 파일의 경우
          if (mediaFile.fileId !== '') { //contentsFileList에만 정보 추가
            newLayerContents.contentsFileList= newLayerContents.contentsFileList.concat({
              fileId: mediaFile.fileId,
              filePath: mediaFile.fileData.filePath,
              fileType: mediaFile.fileData.fileType,
              fileNm: mediaFile.fileData.fileNm,
              fileSize: mediaFile.fileData.fileSize,
            });
            continue;
          }

          let data = {
            placeId: mediaFile.filePlaceId,
            folderId: mediaFile.fileFolderId,
            fileNm: mediaFile.fileData.name,
            fileSize: mediaFile.fileData.size,
            fileType: mediaFile.fileData.type,
            fileDuration: mediaFile.fileData.type.includes('video') ? mediaFile.changeTime : '0',
          };

          if (mediaFile.fileThumb) {
            const thumbNm = mediaFile.fileThumb.name;
            const thumbType = mediaFile.fileThumb.type;
            const thumbData = {
              folderId: mediaFile.fileFolderId,
              thumbNm: thumbNm,
              thumbType: thumbType,
            }
            const thumbResult = yield call(contentsApiLib.getStorageThumbAdd, thumbData);
            const thumbPresignUrl = thumbResult.url;
            yield call(apiLib.fetchUploadApi, thumbPresignUrl, mediaFile.fileThumb);
            data.fileThumbId = thumbResult.thumbInfo.thumbId;
            data.fileThumb = thumbResult.thumbInfo.thumbPath;
          }

          const result = yield call(contentsApiLib.getStorageFileAdd, data);
          const presignUrl = result.url;
          yield call(apiLib.fetchUploadApi, presignUrl, mediaFile.fileData, onUploadProgress, i);

          const fileId = result.fileInfo.fileId;
          const filePath = result.fileInfo.filePath;
          const fileTumb = result.fileInfo.fileThumb;

          mediaFile.fileId = fileId;
          mediaFile.fileData.fileId = fileId;
          mediaFile.fileData.fileNm = mediaFile.fileData.name;
          mediaFile.fileData.fileSize = mediaFile.fileData.size;
          mediaFile.fileData.fileType = mediaFile.fileData.type;
          mediaFile.fileData.filePath = filePath;
          mediaFile.fileData.fileThumb = fileTumb;
          delete mediaFile.filePlaceId;
          delete mediaFile.fileFolderId;
          delete mediaFile.filePreview;
          delete mediaFile.fileThumb;
          delete mediaFile.fileThumbPreview;

          // 새로운 파일을 추가한 경우, file DB에 저장 후 해당 정보를 contentsFileList에 추가
          newLayerContents.contentsFileList= newLayerContents.contentsFileList.concat({
            fileId: result.fileInfo.fileId,
            filePath: result.fileInfo.filePath,
            fileType: result.fileInfo.fileType,
            fileNm: result.fileInfo.fileNm,
            fileSize: result.fileInfo.fileSize,
          });

          // const uploadData = new FormData();
          // uploadData.append('file', file, file.fileNm);
        } catch (error) {
          // yield put(contentsAction.storageFileUploadMode(5));
          yield put(contentsAction.storageFileAddFailure());
          return;
        }
      }
      yield put(contentsAction.storageFileAddSuccess());
      // yield put(contentsAction.getPlaceFileInfo());

    } else if (newLayerContents.contentsType === 'oneFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        useYn: 'N',
        folderPid: '_ROOT_',
        folderId: '_ROOT_-app',
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(newLayerContents);
      let uploadCnt = 0;
      for (let company of newLayerContents.contentsData.companyList) {
        // console.log(company.logoFile); // 실제 파일 객체
        try {
          if (company.logoFile) {
            uploadCnt++;

            const data = {
              folderId: '_ROOT_-app',
              fileNm: company.logoFile.name,
              fileSize: company.logoFile.size,
              fileType: company.logoFile.type,
            };

            const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
            const presignUrl = result.url;
            const fileId = result.fileInfo.fileId;
            const filePath = result.fileInfo.filePath;

            // eliga-interaction-layer-contents DB용 데이터 가공
            company.logoFile.fileId = fileId;
            company.logoFile.fileNm = company.logoFile.name;
            company.logoFile.fileSize = company.logoFile.size;
            company.logoFile.fileType = company.logoFile.type;
            company.logoFile.filePath = filePath;
            delete company.logoFile.preview;

            // contentsFileList 데이터 생성
            newLayerContents.contentsFileList = newLayerContents.contentsFileList.concat({
              fileId: fileId,
              fileNm: company.logoFile.name,
              fileSize: company.logoFile.size,
              fileType: company.logoFile.type,
              filePath: filePath
            });

            const uploadData = new FormData();
            uploadData.append('file', company.logoFile, company.logoFile.name);
            yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
          }
        } catch (error) {
          console.log(error);
          yield put(contentsAction.storageFileAddFailure(error));
          return;
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
    } else if (newLayerContents.contentsType === 'wholeFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        useYn: 'N',
        folderPid: '_ROOT_',
        folderId: '_ROOT_-app',
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(newLayerContents);
      let uploadCnt = 0;
      for (let floor of newLayerContents.contentsData.floorList) {
        for (let company of floor.companyList) {
          // console.log(company.logoFile); // 실제 파일 객체
          try {
            if (company.logoFile) {
              uploadCnt++;

              const data = {
                folderId: '_ROOT_-app',
                fileNm: company.logoFile.name,
                fileSize: company.logoFile.size,
                fileType: company.logoFile.type,
              };

              const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
              const presignUrl = result.url;
              const fileId = result.fileInfo.fileId;
              const filePath = result.fileInfo.filePath;

              // eliga-interaction-layer-contents DB용 데이터 가공
              company.logoFile.fileId = fileId;
              company.logoFile.fileNm = company.logoFile.name;
              company.logoFile.fileSize = company.logoFile.size;
              company.logoFile.fileType = company.logoFile.type;
              company.logoFile.filePath = filePath;
              delete company.logoFile.preview;

              // contentsFileList 데이터 생성
              newLayerContents.contentsFileList = newLayerContents.contentsFileList.concat({
                fileId: fileId,
                fileNm: company.logoFile.name,
                fileSize: company.logoFile.size,
                fileType: company.logoFile.type,
                filePath: filePath
              });

              const uploadData = new FormData();
              uploadData.append('file', company.logoFile, company.logoFile.name);
              yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
            }
          } catch (error) {
            console.log(error);
            yield put(contentsAction.storageFileAddFailure(error));
            return;
          }
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
    }

    yield call(layerContentsApiLib.getSaveLayerContents, { playlistId, frameId, layerId, newLayerContents }); // insert to eliga-interaction-layer-contents DB
    yield put(layerContentsAction.saveLayerContentsSuccess(newLayerContents));
  } catch (error) {
    console.log(error);
    yield put(layerContentsAction.saveLayerContentsFailure('saveLayerContents error'));
  }
}

function* updateLayerContents({ payload: { updateLayerContents, onUploadProgress }}) {
  try {
    if (updateLayerContents.contentsType === 'media') {
      yield put(contentsAction.storageFileAddRequest());
      //삭제된 컨텐츠가 있을 경우, contentsFileList에서도 삭제
      updateLayerContents.contentsFileList = updateLayerContents.contentsFileList.filter(fileList => updateLayerContents.contentsData.mediaList.includes(fileList.fileId));

      for (let i = 0; i< updateLayerContents.contentsData.mediaList.length; i++) {
        let mediaFile = updateLayerContents.contentsData.mediaList[i];
        try {
          // file DB에 저장되어 있는 파일의 경우
          if (mediaFile.fileId !== '') { // contentsFileList에만 정보 추가
            const idx = updateLayerContents.contentsFileList.findIndex(contents => contents.fileId === mediaFile.fileId);
            if (idx === -1) { 
              updateLayerContents.contentsFileList = updateLayerContents.contentsFileList.concat({
                fileId: mediaFile.fileId,
                filePath: mediaFile.fileData.filePath,
                fileType: mediaFile.fileData.fileType,
                fileNm: mediaFile.fileData.fileNm,
                fileSize: mediaFile.fileData.fileSize,
              });
            }
            continue;
          }

          let data = {
            placeId: mediaFile.filePlaceId,
            folderId: mediaFile.fileFolderId,
            fileNm: mediaFile.fileData.name,
            fileSize: mediaFile.fileData.size,
            fileType: mediaFile.fileData.type,
            fileDuration: mediaFile.fileData.type.includes('video') ? mediaFile.changeTime : '0',
          };

          if (mediaFile.fileThumb) {
            const thumbNm = mediaFile.fileThumb.name;
            const thumbType = mediaFile.fileThumb.type;
            const thumbData = {
              folderId: mediaFile.fileFolderId,
              thumbNm: thumbNm,
              thumbType: thumbType,
            }
            const thumbResult = yield call(contentsApiLib.getStorageThumbAdd, thumbData);
            const thumbPresignUrl = thumbResult.url;
            yield call(apiLib.fetchUploadApi, thumbPresignUrl, mediaFile.fileThumb);
            data.fileThumbId = thumbResult.thumbInfo.thumbId;
            data.fileThumb = thumbResult.thumbInfo.thumbPath;
          }
          
          const result = yield call(contentsApiLib.getStorageFileAdd, data);
          const presignUrl = result.url;
          yield call(apiLib.fetchUploadApi, presignUrl, mediaFile.fileData, onUploadProgress, i);
          
          const fileId = result.fileInfo.fileId;
          const filePath = result.fileInfo.filePath;
          const fileTumb = result.fileInfo.fileThumb;

          mediaFile.fileId = fileId;
          mediaFile.fileData.fileId = fileId;
          mediaFile.fileData.fileNm = mediaFile.fileData.name;
          mediaFile.fileData.fileSize = mediaFile.fileData.size;
          mediaFile.fileData.fileType = mediaFile.fileData.type;
          mediaFile.fileData.filePath = filePath;
          mediaFile.fileData.fileThumb = fileTumb;
          delete mediaFile.filePlaceId;
          delete mediaFile.fileFolderId;
          delete mediaFile.filePreview;
          delete mediaFile.fileThumb;
          delete mediaFile.fileThumbPreview;

          // 새로운 파일을 추가한 경우, file DB에 저장 후 해당 정보를 contentsFileList에 추가
          updateLayerContents.contentsFileList = updateLayerContents.contentsFileList.concat({
            fileId: result.fileInfo.fileId,
            filePath: result.fileInfo.filePath,
            fileType: result.fileInfo.fileType,
            fileNm: result.fileInfo.fileNm,
            fileSize: result.fileInfo.fileSize,
          });

          // const uploadData = new FormData();
          // uploadData.append('file', file, file.fileNm);
          // yield call(apiLib.fetchUploadApi, presignUrl, mediaFile.fileData, onUploadProgress);
        } catch (error) {
          // yield put(contentsAction.storageFileUploadMode(5));
          yield put(contentsAction.storageFileAddFailure());
          return;
        }
      }
      yield put(contentsAction.storageFileAddSuccess());
      // yield put(contentsAction.getPlaceFileInfo());

    } else if (updateLayerContents.contentsType === 'oneFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        useYn: 'N',
        folderPid: '_ROOT_',
        folderId: '_ROOT_-app',
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(updateLayerContents);
      let uploadCnt = 0;
      for (let company of updateLayerContents.contentsData.companyList) {
        // console.log(company.logoFile); // 실제 파일 객체
        try {
          if (company.logoFile) {
            if (!company.logoFile.fileId) { // 파일에 변경이 생긴 경우
              uploadCnt++;

              const data = {
                folderId: '_ROOT_-app',
                fileNm: company.logoFile.name,
                fileSize: company.logoFile.size,
                fileType: company.logoFile.type,
              };
  
              const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
              const presignUrl = result.url;
              const fileId = result.fileInfo.fileId;
              const fileNm = result.fileInfo.fileNm;
              const fileSize = result.fileInfo.fileSize;
              const fileType = result.fileInfo.fileType;
              const filePath = result.fileInfo.filePath;
  
              // eliga-interaction-layer-contents DB용 데이터 가공
              company.logoFile.fileId = fileId;
              company.logoFile.fileNm = fileNm;
              company.logoFile.fileSize = fileSize;
              company.logoFile.fileType = fileType;
              company.logoFile.filePath = filePath;
              delete company.logoFile.preview;
  
              const uploadData = new FormData();
              uploadData.append('file', company.logoFile, company.logoFile.name);
              yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
            }

            // contentsFileList 데이터 생성
            // console.log(company.logoFile);
            updateLayerContents.contentsFileList = updateLayerContents.contentsFileList.concat({
              fileId: company.logoFile.fileId,
              fileNm: company.logoFile.fileNm,
              fileSize: company.logoFile.fileSize,
              fileType: company.logoFile.fileType,
              filePath: company.logoFile.filePath
            });
          }
        } catch (error) {
          console.log(error);
          yield put(contentsAction.storageFileAddFailure(error));
          return;
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
    } else if (updateLayerContents.contentsType === 'wholeFloor') {
      yield put(contentsAction.storageFileAddRequest());

      const folderData = {
        useYn: 'N',
        folderPid: '_ROOT_',
        folderId: '_ROOT_-app',
        folderNm: 'app-file',
      };
      yield call(contentsApiLib.getStorageFolderCheckAndMake, folderData); // check folder exist and make folder

      // console.log(updateLayerContents);
      let uploadCnt = 0;
      for (let floor of updateLayerContents.contentsData.floorList) {
        for (let company of floor.companyList) {
          // console.log(company.logoFile); // 실제 파일 객체
          try {
            if (company.logoFile) {
              if (!company.logoFile.fileId) { // 파일에 변경이 생긴 경우
                uploadCnt++;

                const data = {
                  folderId: '_ROOT_-app',
                  fileNm: company.logoFile.name,
                  fileSize: company.logoFile.size,
                  fileType: company.logoFile.type,
                };
    
                const result = yield call(contentsApiLib.getStorageFileAdd, data); // insert to eliga-interaction-storage-file DB / get url for put object in s3
                const presignUrl = result.url;
                const fileId = result.fileInfo.fileId;
                const fileNm = result.fileInfo.fileNm;
                const fileSize = result.fileInfo.fileSize;
                const fileType = result.fileInfo.fileType;
                const filePath = result.fileInfo.filePath;
    
                // eliga-interaction-layer-contents DB용 데이터 가공
                company.logoFile.fileId = fileId;
                company.logoFile.fileNm = fileNm;
                company.logoFile.fileSize = fileSize;
                company.logoFile.fileType = fileType;
                company.logoFile.filePath = filePath;
                delete company.logoFile.preview;
    
                const uploadData = new FormData();
                uploadData.append('file', company.logoFile, company.logoFile.name);
                yield call(apiLib.fetchUploadApi, presignUrl, company.logoFile, onUploadProgress, uploadCnt); // file upload
              }

              // contentsFileList 데이터 생성
              // console.log(company.logoFile);
              updateLayerContents.contentsFileList = updateLayerContents.contentsFileList.concat({
                fileId: company.logoFile.fileId,
                fileNm: company.logoFile.fileNm,
                fileSize: company.logoFile.fileSize,
                fileType: company.logoFile.fileType,
                filePath: company.logoFile.filePath
              });
            }
          } catch (error) {
            console.log(error);
            yield put(contentsAction.storageFileAddFailure(error));
            return;
          }
        }
      }

      yield put(contentsAction.storageFileAddSuccess());
    }

    yield call(layerContentsApiLib.getUpdateLayerContents, updateLayerContents);
    yield put(layerContentsAction.updateLayerContentsSuccess(updateLayerContents));
  } catch (error) {
    console.log(error);
    yield put(layerContentsAction.updateLayerContentsFailure('updateLayerContents error'));
  }
}

function* removeLayerContents({ payload: { removeLayerContents, activeLayerId }}) {
  try {
    const playlistId = yield select(activeItemSelector.playlistId);
    const frameId = yield select(activeItemSelector.frameId);
    const layerId = activeLayerId;

    yield call(layerContentsApiLib.getRemoveLayerContents, removeLayerContents);
    yield put(layerContentsAction.removeLayerContentsSuccess());
    yield put(layerContentsAction.getLayerContentsSuccess({ playlistId, frameId, layerId }));
  } catch (error) {
    yield put(layerContentsAction.removeLayerContentsFailure('removeLayerContents error'));
  }
}

export function* watchgetLayerContents() {
  yield takeLatest(layerContentsAction.getLayerContents, getLayerContents);
}

export function* watchSaveLayerContents() {
  yield takeLatest(layerContentsAction.saveLayerContents, saveLayerContents);
}

export function* watchUpdateLayerContents() {
  yield takeLatest(layerContentsAction.updateLayerContents, updateLayerContents);
}

export function* watchRemoveLayerContents() {
  yield takeLatest(layerContentsAction.removeLayerContents, removeLayerContents);
}

function* rootSaga() {
  yield all([
    fork(watchgetLayerContents),
    fork(watchSaveLayerContents),
    fork(watchUpdateLayerContents),
    fork(watchRemoveLayerContents),
  ]);
}

export default rootSaga;
