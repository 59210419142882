import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface AppPayload {
  appId: string;
  appNm: string;
}

interface AppInfo {
  appId: string;
  appNm: string;
  regDt: string;
}

interface AppState {
  appList: Array<AppInfo>;
  appInfo: AppInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const AppInitialState: AppState = {
  appList: [],
  appInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  initAppState: (state: AppState) => {
    state.appList = [];
    state.appInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
  list: (state: AppState, { payload }: PayloadAction<AppPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: AppState, { payload }: PayloadAction<AppState>) => {
    state.appList = payload.appList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: AppState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detail: (state: AppState, { payload }: PayloadAction<AppPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: AppState,
    { payload }: PayloadAction<AppState>,
  ) => {
    state.appInfo = payload.appInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: AppState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: AppState, action: PayloadAction<string>) => {
    state.appInfo = {};
  },
  add: (state: AppState, { payload }: PayloadAction<AppPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: AppState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: AppState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: AppState, { payload }: PayloadAction<AppPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: AppState, { payload: { appInfo } }: PayloadAction<AppPayload>) => {
    state.appInfo = appInfo;
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: AppState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: AppState) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: AppState, { payload: { appList } }: PayloadAction<AppPayload>) => {
    state.appList = appList;
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: AppState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
};

const slice = createSlice({
  name: 'app',
  initialState: AppInitialState,
  reducers: reducers,
});

const selectappList = createDraftSafeSelector(
  (state: AppState) => state.appList,
  appList => appList,
);

const selectappInfo = createDraftSafeSelector(
  (state: AppState) => state.appInfo,
  appInfo => appInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: AppState) => state.actionResult,
  (state: AppState) => state.isLoading,
  (state: AppState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const appSelector = {
  appList: state => selectappList(state[APP]),
  appInfo: state => selectappInfo(state[APP]),
  status: state => selectStatus(state[APP]),
};

export const APP = slice.name;
export const appReducer = slice.reducer;
export const appAction = slice.actions;
