import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface CustomerPayload {
  customerId: string;
  spaceId: string;
  customerNm: string;
  filterValue: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface CustomerInfo {
  customerId: string;
  spaceId: string;
  customerNm: string;
  regDt: string;
}

interface CustomerState {
  pageInfo: PageInfo;
  customerList: Array<CustomerInfo>;
  customerInfo: CustomerInfo;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const customerInitialState: CustomerState = {
  pageInfo: {},
  customerList: [],
  customerInfo: {},
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: CustomerState, { payload }: PayloadAction<CustomerPayload>) => {
    state.filterValue = payload.filterValue;
  },
  list: (state: CustomerState, { payload }: PayloadAction<CustomerPayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey => pageKey.startKey && pageKey.startKey.customerId === state.pageInfo.curStartKey.customerId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.customerList = payload.customerList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: DeviceState) => {
    state.pageInfo = {};
    state.customerList = [];
  },
  detail: (state: CustomerState, { payload }: PayloadAction<CustomerPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.customerInfo = payload.customerInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: CustomerState, action: PayloadAction<string>) => {
    state.customerInfo = {};
  },
  add: (state: CustomerState, { payload }: PayloadAction<CustomerPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: CustomerState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: CustomerState, { payload }: PayloadAction<CustomerPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: CustomerState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: CustomerState, { payload }: PayloadAction<CustomerPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: CustomerState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  signupResend: (state: AccountState, { payload }: PayloadAction<AccountPayload>) => {
    state.actionResult = 'SINGUP_RESEND_REQ';
    state.isLoading = true;
    state.error = null;
  },
  signupResendSuccess: (state: AccountState) => {
    state.actionResult = 'SINGUP_RESEND_OK';
    state.isLoading = false;
    state.error = null;
  },
  signupResendFailure: (state: AccountState, action: PayloadAction<string>) => {
    state.actionResult = 'SINGUP_RESEND_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'customer',
  initialState: customerInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: CustomerState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectCustomerList = createDraftSafeSelector(
  (state: CustomerState) => state.customerList,
  customerList => customerList,
);

const selectCustomerInfo = createDraftSafeSelector(
  (state: CustomerState) => state.customerInfo,
  customerInfo => customerInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: CustomerState) => state.actionResult,
  (state: CustomerState) => state.isLoading,
  (state: CustomerState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const storeCustomerSelector = {
  pageInfo: state => selectPageInfo(state[STORE_CUSTOMER]),
  customerList: state => selectCustomerList(state[STORE_CUSTOMER]),
  customerInfo: state => selectCustomerInfo(state[STORE_CUSTOMER]),
  status: state => selectStatus(state[STORE_CUSTOMER]),
};

export const STORE_CUSTOMER = slice.name;
export const storeCustomerReducer = slice.reducer;
export const storeCustomerAction = slice.actions;
