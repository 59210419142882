import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { menuAction } from './menuSlice';

import * as menuApiLib from '../lib/menuApi';

function* list({ payload }) {
  try {
    const menuList = yield call(menuApiLib.list, payload);

    yield put(menuAction.listSuccess({ menuList }));
  } catch (error) {
    yield put(menuAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    yield call(menuApiLib.add, payload);

    yield put(menuAction.addSuccess());
  } catch (error) {
    yield put(menuAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const menuInfo = yield call(menuApiLib.detail, payload);

    yield put(menuAction.detailSuccess({ menuInfo }));
  } catch (error) {
    yield put(menuAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    yield call(menuApiLib.edit, payload);

    yield put(menuAction.editSuccess());
  } catch (error) {
    yield put(menuAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(menuApiLib.remove, payload);

    yield put(menuAction.removeSuccess());
  } catch (error) {
    yield put(menuAction.removeFailure({ error }));
  }
}

export function* watchmenuList() {
  yield takeLatest(menuAction.list, list);
}

export function* watchmenuAdd() {
  yield takeLatest(menuAction.add, add);
}

export function* watchmenuDetail() {
  yield takeLatest(menuAction.detail, detail);
}

export function* watchmenuEdit() {
  yield takeLatest(menuAction.edit, edit);
}

export function* watchmenuRemove() {
  yield takeLatest(menuAction.remove, remove);
}

function* rootSaga() {
  yield all([
    fork(watchmenuList),
    fork(watchmenuAdd),
    fork(watchmenuDetail),
    fork(watchmenuEdit),
    fork(watchmenuRemove),
  ]);
}

export default rootSaga;
