import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { BOARD, boardAction } from './boardSlice';

import * as boardApiLib from '../lib/boardApi';
import * as apiLib from '../lib/api';

function* handleNoticeList({ payload }) {
  try {
    const noticeList = yield call(boardApiLib.noticeList, payload);

    yield put(boardAction.noticeListSuccess({ noticeList }));
  } catch (err) {
    yield put(boardAction.noticeListFailure('Notice List Error'));
  }
}

function* handleNoticeDetail({ payload }) {
  try {
    const noticeInfo = yield call(boardApiLib.noticeDetail, payload);

    yield put(boardAction.noticeDetailSuccess({ noticeInfo }));
  } catch (err) {
    yield put(boardAction.noticeDetailFailure('Notice Detail Error'));
  }
}

function* handleNoticeAdd({ payload }) {
  try {
    yield call(boardApiLib.noticeAdd, payload);

    yield put(boardAction.noticeAddSuccess());
  } catch (err) {
    yield put(boardAction.noticeAddFailure('Notice Add Error'));
  }
}

function* handleNoticeFileAdd({ payload: { noticeId, files, onUploadProgress } }) {
  for (let i = 0; i < files.length; i++) {
    try {
      const data = {
        noticeId: noticeId,
        fileNm: files[i].name,
        fileType: files[i].type,
        fileSize: files[i].size,
        fileThumb: 'test fileThumb',
      };

      const result = yield call(boardApiLib.noticeFileAdd, data);
      const presignUrl = result.url;

      const uploadData = new FormData();
      uploadData.append('file', files[i], files[i].name);
      yield call(apiLib.fetchUploadApi, presignUrl, files[i], onUploadProgress, i);
    } catch (err) {
      yield put(boardAction.noticeFileAddFailure('Notice File Add Error'));
    }
  }
  yield put(boardAction.noticeFileAddSuccess());
}

function* handleNoticeEdit({ payload }) {
  try {
    yield call(boardApiLib.noticeEdit, payload);

    yield put(boardAction.noticeEditSuccess());
  } catch (err) {
    yield put(boardAction.noticeEditFailure('Notice Edit Error'));
  }
}

function* handleNoticeRemove({ payload }) {
  try {
    yield call(boardApiLib.noticeRemove, payload);

    yield put(boardAction.noticeRemoveSuccess());
  } catch (err) {
    yield put(boardAction.noticeRemoveFailure('Notice Remove Error'));
  }
}

function* handleReleaseList({ payload }) {
  try {
    const releaseList = yield call(boardApiLib.releaseList, payload);

    yield put(boardAction.releaseListSuccess({ releaseList }));
  } catch (err) {
    yield put(boardAction.releaseListFailure('Release List Error'));
  }
}

function* handleReleaseAdd({ payload }) {
  try {
    yield call(boardApiLib.releaseAdd, payload);

    yield put(boardAction.releaseAddSuccess());
  } catch (err) {
    yield put(boardAction.releaseAddFailure('Release Add Error'));
  }
}

function* handleReleaseEdit({ payload }) {
  try {
    yield call(boardApiLib.releaseEdit, payload);

    yield put(boardAction.releaseEditSuccess());
  } catch (err) {
    yield put(boardAction.releaseEditFailure('Release Edit Error'));
  }
}

function* handleReleaseDetail({ payload }) {
  try {
    const releaseInfo = yield call(boardApiLib.releaseDetail, payload);

    yield put(boardAction.releaseDetailSuccess({ releaseInfo }));
  } catch (err) {
    yield put(boardAction.releaseDetailFailure('Release Detail Error'));
  }
}

function* handleReleaseRemove({ payload }) {
  try {
    yield call(boardApiLib.releaseRemove, payload);

    yield put(boardAction.releaseRemoveSuccess());
  } catch (err) {
    yield put(boardAction.releaseRemoveFailure('Release Remove Error'));
  }
}

function* handleQnaList({ payload }) {
  try {
    const qnaList = yield call(boardApiLib.qnaList, payload);

    yield put(boardAction.qnaListSuccess({ qnaList }));
  } catch (err) {
    yield put(boardAction.qnaListFailure('Qna List Error'));
  }
}

function* handleQuestionList({ payload }) {
  try {
    const questionList = yield call(boardApiLib.questionList, payload);
    yield put(boardAction.questionListSuccess({ questionList }));
  } catch (err) {
    yield put(boardAction.questionListFailure('Question List Error'));
  }
}

function* handleQnaDetail({ payload }) {
  try {
    const qnaInfo = yield call(boardApiLib.qnaDetail, payload);

    yield put(boardAction.qnaDetailSuccess({ qnaInfo }));
  } catch (err) {
    yield put(boardAction.qnaDetailFailure('Qna Detail Error'));
  }
}

function* handleQuestionDetail({ payload }) {
  try {
    const questionInfo = yield call(boardApiLib.questionDetail, payload);

    yield put(boardAction.questionDetailSuccess({ questionInfo }));
  } catch (err) {
    yield put(boardAction.questionDetailFailure('Question Detail Error'));
  }
}

function* handleQnaAdd({ payload }) {
  try {
    yield call(boardApiLib.qnaAdd, payload);

    yield put(boardAction.qnaAddSuccess());
  } catch (err) {
    yield put(boardAction.qnaAddFailure('Qna Add Error'));
  }
}

function* handleQuestionAdd({ payload }) {
  try {
    yield call(boardApiLib.questionAdd, payload);

    yield put(boardAction.questionAddSuccess());
  } catch (err) {
    yield put(boardAction.questionAddFailure('Question Add Error'));
  }
}

function* handleQnaEdit({ payload }) {
  try {
    yield call(boardApiLib.qnaEdit, payload);

    yield put(boardAction.qnaEditSuccess());
  } catch (err) {
    yield put(boardAction.qnaEditFailure('Qna Edit Error'));
  }
}

function* handleQuestionEdit({ payload }) {
  try {
    yield call(boardApiLib.questionEdit, payload);

    yield put(boardAction.questionEditSuccess());
  } catch (err) {
    yield put(boardAction.questionEditFailure('Question Edit Error'));
  }
}

function* handleQnaRemove({ payload }) {
  try {
    yield call(boardApiLib.qnaRemove, payload);

    yield put(boardAction.qnaRemoveSuccess());
  } catch (err) {
    yield put(boardAction.qnaRemoveFailure('Qna Remove Error'));
  }
}

function* handleQuestionRemove({ payload }) {
  try {
    yield call(boardApiLib.questionRemove, payload);

    yield put(boardAction.questionRemoveSuccess());
  } catch (err) {
    yield put(boardAction.questionRemoveFailure('Question Remove Error'));
  }
}
function* handleGuideList({ payload }) {
  try {
    const guideList = yield call(boardApiLib.guideList, payload);

    yield put(boardAction.guideListSuccess({ guideList }));
  } catch (err) {
    yield put(boardAction.guideListFailure('Guide List Error'));
  }
}

function* handleGuideAdd({ payload }) {
  try {
    yield call(boardApiLib.guideAdd, payload);

    yield put(boardAction.guideAddSuccess());
  } catch (err) {
    yield put(boardAction.guideAddFailure('Guide Add Error'));
  }
}

function* handleGuideDetail({ payload }) {
  try {
    const guideInfo = yield call(boardApiLib.guideDetail, payload);
    const guideFileList = yield call(boardApiLib.guideFileList, payload);
    const fileIdList = [];
    guideFileList.map(file => {
      return fileIdList.push(file.fileId);
    });

    yield put(boardAction.guideDetailSuccess({ guideInfo, guideFileList, fileIdList }));
  } catch (err) {
    yield put(boardAction.guideDetailFailure('Guide Detail Error'));
  }
}

function* handleGuideEdit({ payload }) {
  try {
    yield call(boardApiLib.guideEdit, payload);

    yield put(boardAction.guideEditSuccess());
  } catch (err) {
    yield put(boardAction.guideEditFailure('Guide Edit Error'));
  }
}

function* handleGuideRemove({ payload }) {
  try {
    yield call(boardApiLib.guideRemove, payload);

    yield put(boardAction.guideRemoveSuccess());
  } catch (err) {
    yield put(boardAction.guideRemoveFailure('Guide Remove Error'));
  }
}

//uploadProgress 개발중
function* handleGuideFileAdd({ payload: { guideId, files, fileIdList, onUploadProgress } }) {
  for (let i = 0; i < files.length; i++) {
    try {
      const data = {
        guideId: guideId,
        fileNm: files[i].name,
        fileType: files[i].type,
        fileSize: files[i].size,
        fileId: fileIdList[i],
        fileThumb: 'test fileThumb',
      };

      const result = yield call(boardApiLib.guideFileAdd, data);
      const presignUrl = result.url;

      const uploadData = new FormData();
      uploadData.append('file', files[i], files[i].name);
      yield call(apiLib.fetchUploadApi, presignUrl, files[i], onUploadProgress, i);
    } catch (err) {
      yield put(boardAction.guideFileAddFailure('Guide File Add Error'));
    }
  }
  yield put(boardAction.guideFileAddSuccess());
}
//uploadProgress 개발중

function* getGuideFileDownloadRequest({ payload }) {
  try {
    yield call(boardApiLib.getGuideFileDownloadRequest, payload);

    yield put(boardAction.guideFileDownloadSuccess());
  } catch (err) {
    yield put(boardAction.guideFileDownloadFailure('Guide File Download Error'));
  }
}

//선택된 가이드 파일을 S3에서 삭제
function* handleGuideFileRemove({ payload }) {
  try {
    yield call(boardApiLib.guideFileRemove, payload);
    yield put(boardAction.guideFileRemoveSuccess());

    const guideInfo = yield select(state => state[BOARD].guideInfo);
    yield put(boardAction.guideFileList(guideInfo));
  } catch (err) {
    yield put(boardAction.guideFileRemoveFailure('Guide File Remove Error'));
  }
}

function* guideFileList({ payload }) {
  try {
    const fileList = yield call(boardApiLib.guideFileList, payload);
    yield put(
      boardAction.guideFileListSuccess({
        guideFileList: fileList,
      }),
    );
  } catch (err) {
    yield put(boardAction.guideFileListFailure('Guide File List Error'));
  }
}

function* handleNewsList({ payload }) {
  try {
    const newsList = yield call(boardApiLib.newsList, payload);

    yield put(boardAction.newsListSuccess({ newsList }));
  } catch (err) {
    yield put(boardAction.newsListFailure('News List Error'));
  }
}

function* handleNewsDetail({ payload }) {
  try {
    const newsInfo = yield call(boardApiLib.newsDetail, payload);

    yield put(boardAction.newsDetailSuccess({ newsInfo }));
  } catch (err) {
    yield put(boardAction.newsDetailFailure('News Detail Error'));
  }
}

function* handleNewsAdd({ payload }) {
  try {
    yield call(boardApiLib.newsAdd, payload);

    yield put(boardAction.newsAddSuccess());
  } catch (err) {
    yield put(boardAction.newsAddFailure('News Add Error'));
  }
}

function* handleNewsEdit({ payload }) {
  try {
    yield call(boardApiLib.newsEdit, payload);

    yield put(boardAction.newsEditSuccess());
  } catch (err) {
    yield put(boardAction.newsEditFailure('News Edit Error'));
  }
}

function* handleNewsRemove({ payload }) {
  try {
    yield call(boardApiLib.newsRemove, payload);

    yield put(boardAction.newsRemoveSuccess());
  } catch (err) {
    yield put(boardAction.newsRemoveFailure('News Remove Error'));
  }
}

function* handleFaqList({ payload }) {
  try {
    const faqList = yield call(boardApiLib.faqList, payload);

    yield put(boardAction.faqListSuccess({ faqList }));
  } catch (err) {
    yield put(boardAction.faqListFailure('Faq List Error'));
  }
}

function* handleFaqDetail({ payload }) {
  try {
    const faqInfo = yield call(boardApiLib.faqDetail, payload);

    yield put(boardAction.faqDetailSuccess({ faqInfo }));
  } catch (err) {
    yield put(boardAction.faqDetailFailure('Faq Detail Error'));
  }
}

function* handleFaqAdd({ payload }) {
  try {
    yield call(boardApiLib.faqAdd, payload);

    yield put(boardAction.faqAddSuccess());
  } catch (err) {
    yield put(boardAction.faqAddFailure('Faq Add Error'));
  }
}

function* handleFaqEdit({ payload }) {
  try {
    yield call(boardApiLib.faqEdit, payload);

    yield put(boardAction.faqEditSuccess());
  } catch (err) {
    yield put(boardAction.faqEditFailure('Faq Edit Error'));
  }
}

function* handleFaqRemove({ payload }) {
  try {
    yield call(boardApiLib.faqRemove, payload);

    yield put(boardAction.faqRemoveSuccess());
  } catch (err) {
    yield put(boardAction.faqRemoveFailure('Faq Remove Error'));
  }
}

export function* watchNoticeList() {
  yield takeLatest(boardAction.noticeList, handleNoticeList);
}

export function* watchNoticeDetail() {
  yield takeLatest(boardAction.noticeDetail, handleNoticeDetail);
}

export function* watchNoticeAdd() {
  yield takeLatest(boardAction.noticeAdd, handleNoticeAdd);
}

export function* watchNoticeFileAdd() {
  yield takeLatest(boardAction.noticeFileAdd, handleNoticeFileAdd);
}

export function* watchNoticeEdit() {
  yield takeLatest(boardAction.noticeEdit, handleNoticeEdit);
}

export function* watchNoticeRemove() {
  yield takeLatest(boardAction.noticeRemove, handleNoticeRemove);
}

export function* watchReleaseList() {
  yield takeLatest(boardAction.releaseList, handleReleaseList);
}

export function* watchReleaseAdd() {
  yield takeLatest(boardAction.releaseAdd, handleReleaseAdd);
}

export function* watchReleaseEdit() {
  yield takeLatest(boardAction.releaseEdit, handleReleaseEdit);
}

export function* watchReleaseDetail() {
  yield takeLatest(boardAction.releaseDetail, handleReleaseDetail);
}

export function* watchReleaseRemove() {
  yield takeLatest(boardAction.releaseRemove, handleReleaseRemove);
}

export function* watchQnaList() {
  yield takeLatest(boardAction.qnaList, handleQnaList);
}

export function* watchQuestionList() {
  yield takeLatest(boardAction.questionList, handleQuestionList);
}

export function* watchQnaDetail() {
  yield takeLatest(boardAction.qnaDetail, handleQnaDetail);
}

export function* watchQuestionDetail() {
  yield takeLatest(boardAction.questionDetail, handleQuestionDetail);
}

export function* watchQnaAdd() {
  yield takeLatest(boardAction.qnaAdd, handleQnaAdd);
}

export function* watchQuestionAdd() {
  yield takeLatest(boardAction.questionAdd, handleQuestionAdd);
}

export function* watchQnaEdit() {
  yield takeLatest(boardAction.qnaEdit, handleQnaEdit);
}

export function* watchQuestionEdit() {
  yield takeLatest(boardAction.questionEdit, handleQuestionEdit);
}

export function* watchQnaRemove() {
  yield takeLatest(boardAction.qnaRemove, handleQnaRemove);
}

export function* watchQuestionRemove() {
  yield takeLatest(boardAction.questionRemove, handleQuestionRemove);
}

export function* watchGuideList() {
  yield takeLatest(boardAction.guideList, handleGuideList);
}

export function* watchGuideAdd() {
  yield takeLatest(boardAction.guideAdd, handleGuideAdd);
}

export function* watchGuideEdit() {
  yield takeLatest(boardAction.guideEdit, handleGuideEdit);
}

export function* watchGuideDetail() {
  yield takeLatest(boardAction.guideDetail, handleGuideDetail);
}

export function* watchGuideRemove() {
  yield takeLatest(boardAction.guideRemove, handleGuideRemove);
}

export function* watchGuideFileAdd() {
  yield takeLatest(boardAction.guideFileAdd, handleGuideFileAdd);
}

export function* watchGuideFileList() {
  yield takeLatest(boardAction.guideFileList, guideFileList);
}

export function* watchGuideFileDownload() {
  yield takeLatest(boardAction.guideFileDownloadRequest, getGuideFileDownloadRequest);
}

export function* watchGuideFileRemove() {
  yield takeLatest(boardAction.guideFileRemove, handleGuideFileRemove);
}

export function* watchNewsList() {
  yield takeLatest(boardAction.newsList, handleNewsList);
}

export function* watchNewsDetail() {
  yield takeLatest(boardAction.newsDetail, handleNewsDetail);
}

export function* watchNewsAdd() {
  yield takeLatest(boardAction.newsAdd, handleNewsAdd);
}

export function* watchNewsEdit() {
  yield takeLatest(boardAction.newsEdit, handleNewsEdit);
}

export function* watchNewsRemove() {
  yield takeLatest(boardAction.newsRemove, handleNewsRemove);
}

export function* watchFaqList() {
  yield takeLatest(boardAction.faqList, handleFaqList);
}

export function* watchFaqDetail() {
  yield takeLatest(boardAction.faqDetail, handleFaqDetail);
}

export function* watchFaqAdd() {
  yield takeLatest(boardAction.faqAdd, handleFaqAdd);
}

export function* watchFaqEdit() {
  yield takeLatest(boardAction.faqEdit, handleFaqEdit);
}

export function* watchFaqRemove() {
  yield takeLatest(boardAction.faqRemove, handleFaqRemove);
}

function* rootSaga() {
  yield all([
    fork(watchNoticeList),
    fork(watchNoticeDetail),
    fork(watchNoticeAdd),
    fork(watchNoticeFileAdd),
    fork(watchNoticeEdit),
    fork(watchNoticeRemove),
    fork(watchReleaseList),
    fork(watchReleaseAdd),
    fork(watchReleaseEdit),
    fork(watchReleaseDetail),
    fork(watchReleaseRemove),
    fork(watchQnaList),
    fork(watchQnaDetail),
    fork(watchQnaAdd),
    fork(watchQnaEdit),
    fork(watchQnaRemove),
    fork(watchGuideList),
    fork(watchGuideDetail),
    fork(watchGuideAdd),
    fork(watchGuideEdit),
    fork(watchGuideRemove),
    fork(watchGuideFileAdd),
    fork(watchGuideFileList),
    fork(watchGuideFileDownload),
    fork(watchGuideFileRemove),
    fork(watchNewsList),
    fork(watchNewsDetail),
    fork(watchNewsAdd),
    fork(watchNewsEdit),
    fork(watchNewsRemove),
    fork(watchFaqList),
    fork(watchFaqDetail),
    fork(watchFaqAdd),
    fork(watchFaqEdit),
    fork(watchFaqRemove),
    fork(watchQuestionAdd),
    fork(watchQuestionEdit),
    fork(watchQuestionRemove),
    fork(watchQuestionList),
    fork(watchQuestionDetail),
  ]);
}

export default rootSaga;
