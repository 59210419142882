import * as apiLib from './api';

export const list = async () => {
  try {
    const result = await apiLib.fetchOperationApi('/app/list', {});

    if (result.resultFlag) {
      return result.appList;
    } else {
      throw Object.assign(new Error('app list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ appId }) => {
  try {
    const data = {
      appId,
    };

    const result = await apiLib.fetchOperationApi('/app/detail', data);

    if (result.resultFlag) {
      return result.appInfo;
    } else {
      throw Object.assign(new Error('app list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ appId }) => {
  try {
    const data ={
      appId,
    };

    const result = await apiLib.fetchOperationApi('/app/add', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('app add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ appId, updateApp }) => {
  try {
    const data = {
      appId,
      updateApp,
    };

    const result = await apiLib.fetchOperationApi('/app/edit', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('app edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ appIdList }) => {
  try {
    const data = {
      appIdList,
    };

    const result = await apiLib.fetchOperationApi('/app/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('app remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
