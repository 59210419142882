import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { scrshotAction } from './scrshotSlice';

import * as scrshotApiLib from '../lib/scrshotApi';

function* handleList({ payload }) {
  try {
    const { lastKey, scrshotList } = yield call(scrshotApiLib.list, payload);

    yield put(scrshotAction.listSuccess({ lastKey, scrshotList }));
  } catch (err) {
    yield put(scrshotAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const scrshotInfo = yield call(scrshotApiLib.detail, payload);

    yield put(scrshotAction.detailSuccess({ scrshotInfo }));
  } catch (err) {
    yield put(scrshotAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(scrshotApiLib.add, payload);

    yield put(scrshotAction.addSuccess());
  } catch (err) {
    yield put(scrshotAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(scrshotApiLib.edit, payload);

    yield put(scrshotAction.editSuccess());
  } catch (err) {
    yield put(scrshotAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(scrshotApiLib.remove, payload);

    yield put(scrshotAction.removeSuccess());
  } catch (err) {
    yield put(scrshotAction.removeFailure('Remove Error'));
  }
}

function* handleRemoveAll({ payload }) {
  try {
    yield call(scrshotApiLib.removeAll, payload);

    yield put(scrshotAction.removeAllSuccess());
  } catch (err) {
    yield put(scrshotAction.removeAllFailure('Remove All Error'));
  }
}

export function* watchList() {
  yield takeLatest(scrshotAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(scrshotAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(scrshotAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(scrshotAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(scrshotAction.remove, handleRemove);
}

export function* watchRemoveAll() {
  yield takeLatest(scrshotAction.removeAll, handleRemoveAll);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchRemoveAll),
  ]);
}

export default rootSaga;
