import React from 'react';
import { RouterProvider, Navigate, createBrowserRouter } from 'react-router-dom';

import AuthLayout from '../pages/layouts/Auth';
import ManagerLayout from '../pages/layouts/Manager';
import ErrorLayout from '../pages/layouts/Error';

const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

const Profile = React.lazy(() => import('../pages/profile/Profile'));

const DashboardDefault = React.lazy(() => import('../pages/dashboard/Default'));

const BoardLayout = React.lazy(() => import('../pages/board/BoardLayout'));
const BoardNoticeList = React.lazy(() => import('../pages/board/notice/NoticeList'));
const BoardNoticeAdd = React.lazy(() => import('../pages/board/notice/NoticeAdd'));
const BoardNoticeDetail = React.lazy(() => import('../pages/board/notice/NoticeDetail'));
const BoardReleaseList = React.lazy(() => import('../pages/board/release/ReleaseList'));
const BoardReleaseAdd = React.lazy(() => import('../pages/board/release/ReleaseAdd'));
const BoardReleaseDetail = React.lazy(() => import('../pages/board/release/ReleaseDetail'));
const BoardGuideList = React.lazy(() => import('../pages/board/guide/GuideList'));
const BoardGuideAdd = React.lazy(() => import('../pages/board/guide/GuideAdd'));
const BoardGuideDetail = React.lazy(() => import('../pages/board/guide/GuideDetail'));
const BoardQnaList = React.lazy(() => import('../pages/board/qna/QnaList'));
const BoardQnaAdd = React.lazy(() => import('../pages/board/qna/QnaAdd'));
const BoardQnaDetail = React.lazy(() => import('../pages/board/qna/QnaDetail'));
const BoardNewsList = React.lazy(() => import('../pages/board/news/NewsList'));
const BoardNewsAdd = React.lazy(() => import('../pages/board/news/NewsAdd'));
const BoardNewsDetail = React.lazy(() => import('../pages/board/news/NewsDetail'));
const BoardFaqList = React.lazy(() => import('../pages/board/faq/FaqList'));
const BoardFaqAdd = React.lazy(() => import('../pages/board/faq/FaqAdd'));
const BoardFaqDetail = React.lazy(() => import('../pages/board/faq/FaqDetail'));

const CoreLayout = React.lazy(() => import('../pages/core/CoreLayout'));
const CoreSpaceList = React.lazy(() => import('../pages/core/space/SpaceList'));
const CoreSpaceAdd = React.lazy(() => import('../pages/core/space/SpaceAdd'));
const CoreSpaceDetail = React.lazy(() => import('../pages/core/space/SpaceDetail'));
const CoreSpaceMemberList = React.lazy(() => import('../pages/core/space/SpaceMemberList'));
const CoreSpaceMemberAdd = React.lazy(() => import('../pages/core/space/SpaceMemberAdd'));
const CoreSpaceMemberDetail = React.lazy(() => import('../pages/core/space/SpaceMemberDetail'));
const CorePlaceList = React.lazy(() => import('../pages/core/place/PlaceList'));
const CorePlaceAdd = React.lazy(() => import('../pages/core/place/PlaceAdd'));
const CorePlaceDetail = React.lazy(() => import('../pages/core/place/PlaceDetail'));
const CorePlaceMemberList = React.lazy(() => import('../pages/core/place/PlaceMemberList'));
const CorePlaceMemberAdd = React.lazy(() => import('../pages/core/place/PlaceMemberAdd'));
const CorePlaceMemberDetail = React.lazy(() => import('../pages/core/place/PlaceMemberDetail'));
const CorePlaceAuthList = React.lazy(() => import('../pages/core/place/PlaceAuthList'));
const CorePlaceAuthAdd = React.lazy(() => import('../pages/core/place/PlaceAuthAdd'));
const CorePlaceAuthDetail = React.lazy(() => import('../pages/core/place/PlaceAuthDetail'));
const CoreGroupList = React.lazy(() => import('../pages/core/group/GroupList'));
const CoreGroupAdd = React.lazy(() => import('../pages/core/group/GroupAdd'));
const CoreGroupDetail = React.lazy(() => import('../pages/core/group/GroupDetail'));
const CoreLicenseList = React.lazy(() => import('../pages/core/license/LicenseList'));
const CoreLicenseAdd = React.lazy(() => import('../pages/core/license/LicenseAdd'));
const CoreLicenseDetail = React.lazy(() => import('../pages/core/license/LicenseDetail'));
const CoreDeviceList = React.lazy(() => import('../pages/core/device/DeviceList'));
const CoreDeviceAdd = React.lazy(() => import('../pages/core/device/DeviceAdd'));
const CoreDeviceDetail = React.lazy(() => import('../pages/core/device/DeviceDetail'));
const CoreDeviceScrshot = React.lazy(() => import('../pages/core/device/DeviceScrshot'));
const CoreDeviceScrshotList = React.lazy(() => import('../pages/core/device/ScrshotList'));
const CorePackList = React.lazy(() => import('../pages/core/pack/PackList'));
const CorePackAdd = React.lazy(() => import('../pages/core/pack/PackAdd'));
const CorePackDetail = React.lazy(() => import('../pages/core/pack/PackDetail'));
const CorePackFileList = React.lazy(() => import('../pages/core/pack/PackFileList'));
const CorePackFileAdd = React.lazy(() => import('../pages/core/pack/PackFileAdd'));
const CorePackFileDetail = React.lazy(() => import('../pages/core/pack/PackFileDetail'));
const CoreAccountList = React.lazy(() => import('../pages/core/account/AccountList'));
const CoreAccountAdd = React.lazy(() => import('../pages/core/account/AccountAdd'));
const CoreAccountDetail = React.lazy(() => import('../pages/core/account/AccountDetail'));
const CoreUserList = React.lazy(() => import('../pages/core/user/UserList'));
const CoreUserAdd = React.lazy(() => import('../pages/core/user/UserAdd'));
const CoreUserDetail = React.lazy(() => import('../pages/core/user/UserDetail'));
const CoreMenuList = React.lazy(() => import('../pages/core/menu/MenuList'));
const CoreMenuAdd = React.lazy(() => import('../pages/core/menu/MenuAdd'));
const CoreMenuDetail = React.lazy(() => import('../pages/core/menu/MenuDetail'));

const InteractionLayout = React.lazy(() => import('../pages/interaction/InteractionLayout'));
const InteractionPlaylistList = React.lazy(() => import('../pages/interaction/playlist/PlaylistList'));
// const InteractionPlaylistAdd = React.lazy(() => import('../pages/interaction/playlist/PlaylistAdd'));
const InteractionPlaylistDetail = React.lazy(() => import('../pages/interaction/playlist/PlaylistDetail'));
const InteractionPlaylistFolderDetail = React.lazy(() => import('../pages/interaction/playlist/PlaylistFolderDetail'));
const InteractionChannelList = React.lazy(() => import('../pages/interaction/channel/ChannelList'));
const InteractionScheduleList = React.lazy(() => import('../pages/interaction/schedule/ScheduleList'));
const InteractionStorageList = React.lazy(() => import('../pages/interaction/storage/StorageList'));
const InteractionStorageFolderDetail = React.lazy(() => import('../pages/interaction/storage/StorageFolderDetail'));
const InteractionStorageFileDetail = React.lazy(() => import('../pages/interaction/storage/StorageFileDetail'));
const InteractionPublicStorageList = React.lazy(() => import('../pages/interaction/publicStorage/StorageList'));
const InteractionPublicStorageFolderDetail = React.lazy(() =>
  import('../pages/interaction/publicStorage/StorageFolderDetail'),
);
const InteractionPublicStorageFileDetail = React.lazy(() =>
  import('../pages/interaction/publicStorage/StorageFileDetail'),
);
const InteractionTemplateList = React.lazy(() => import('../pages/interaction/template/TemplateList'));
const InteractionTemplateDetail = React.lazy(() => import('../pages/interaction/template/TemplateDetail'));
const InteractionPackageList = React.lazy(() => import('../pages/interaction/package/PackageList'));
const InteractionPackageDetail = React.lazy(() => import('../pages/interaction/package/PackageDetail'));
const InteractionAppList = React.lazy(() => import('../pages/interaction/app/AppList'));
const InteractionAppDetail = React.lazy(() => import('../pages/interaction/app/AppDetail'));

const ArchiveLayout = React.lazy(() => import('../pages/archive/ArchiveLayout'));
const ArchivePlaylistList = React.lazy(() => import('../pages/archive/playlist/PlaylistList'));
const ArchivePlaylistAdd = React.lazy(() => import('../pages/archive/playlist/PlaylistAdd'));
const ArchivePlaylistDetail = React.lazy(() => import('../pages/archive/playlist/PlaylistDetail'));
const ArchivePackageList = React.lazy(() => import('../pages/archive/package/PackageList'));
const ArchivePackageDetail = React.lazy(() => import('../pages/archive/package/PackageDetail'));

const StoreLayout = React.lazy(() => import('../pages/store/StoreLayout'));
const StoreBrandList = React.lazy(() => import('../pages/store/brand/BrandList'));
const StoreBrandAdd = React.lazy(() => import('../pages/store/brand/BrandAdd'));
const StoreBrandDetail = React.lazy(() => import('../pages/store/brand/BrandDetail'));
const StoreShopList = React.lazy(() => import('../pages/store/shop/ShopList'));
const StoreShopAdd = React.lazy(() => import('../pages/store/shop/ShopAdd'));
const StoreShopDetail = React.lazy(() => import('../pages/store/shop/ShopDetail'));
const StoreShopMemberList = React.lazy(() => import('../pages/store/shop/ShopMemberList'));
const StoreShopMemberAdd = React.lazy(() => import('../pages/store/shop/ShopMemberAdd'));
const StoreShopMemberDetail = React.lazy(() => import('../pages/store/shop/ShopMemberDetail'));
const StoreStorageList = React.lazy(() => import('../pages/store/storage/StorageList'));
const StoreStorageFolderDetail = React.lazy(() => import('../pages/store/storage/StorageFolderDetail'));
const StoreStorageFileDetail = React.lazy(() => import('../pages/store/storage/StorageFileDetail'));
const StoreCustomerList = React.lazy(() => import('../pages/store/customer/CustomerList'));
const StoreCustomerAdd = React.lazy(() => import('../pages/store/customer/CustomerAdd'));
const StoreCustomerDetail = React.lazy(() => import('../pages/store/customer/CustomerDetail'));

const SystemLayout = React.lazy(() => import('../pages/system/SystemLayout'));
const SystemAdminList = React.lazy(() => import('../pages/system/admin/AdminList'));
const SystemAdminDetail = React.lazy(() => import('../pages/system/admin/AdminDetail'));
const SystemAdminAdd = React.lazy(() => import('../pages/system/admin/AdminAdd'));

const rootRoutes = {
  path: '/',
  element: <Navigate to="/dashboard" />,
};

const authRoutes = {
  path: '/auth',
  element: <AuthLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/auth/login" />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'logout',
      element: <Logout />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const profileRoutes = {
  path: '/profile',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <Profile />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const dashboardRoutes = {
  path: '/dashboard',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <DashboardDefault />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const boardRoutes = {
  path: '/board',
  element: <ManagerLayout />,
  children: [
    {
      element: <BoardLayout />,
      children: [
        {
          path: 'notice/list',
          element: <BoardNoticeList />,
        },
        {
          path: 'notice/add',
          element: <BoardNoticeAdd />,
        },
        {
          path: 'notice/detail',
          element: <BoardNoticeDetail />,
        },
        {
          path: 'release/list',
          element: <BoardReleaseList />,
        },
        {
          path: 'release/detail',
          element: <BoardReleaseDetail />,
        },
        {
          path: 'release/add',
          element: <BoardReleaseAdd />,
        },
        {
          path: 'guide/list',
          element: <BoardGuideList />,
        },
        {
          path: 'guide/detail',
          element: <BoardGuideDetail />,
        },
        {
          path: 'guide/add',
          element: <BoardGuideAdd />,
        },
        {
          path: 'qna/list',
          element: <BoardQnaList />,
        },
        {
          path: 'qna/detail',
          element: <BoardQnaDetail />,
        },
        {
          path: 'qna/add',
          element: <BoardQnaAdd />,
        },
        {
          path: 'news/list',
          element: <BoardNewsList />,
        },
        {
          path: 'news/detail',
          element: <BoardNewsDetail />,
        },
        {
          path: 'news/add',
          element: <BoardNewsAdd />,
        },
        {
          path: 'faq/list',
          element: <BoardFaqList />,
        },
        {
          path: 'faq/detail',
          element: <BoardFaqDetail />,
        },
        {
          path: 'faq/add',
          element: <BoardFaqAdd />,
        },
      ],
    },
  ],
  errorElement: <ErrorLayout />,
};

const coreRoutes = {
  path: '/core',
  element: <ManagerLayout />,
  children: [
    {
      element: <CoreLayout />,
      children: [
        {
          path: 'space/list',
          element: <CoreSpaceList />,
        },
        {
          path: 'space/add',
          element: <CoreSpaceAdd />,
        },
        {
          path: 'space/detail',
          element: <CoreSpaceDetail />,
        },
        {
          path: 'space/member-list',
          element: <CoreSpaceMemberList />,
        },
        {
          path: 'space/member-add',
          element: <CoreSpaceMemberAdd />,
        },
        {
          path: 'space/member-detail',
          element: <CoreSpaceMemberDetail />,
        },
        {
          path: 'place/list',
          element: <CorePlaceList />,
        },
        {
          path: 'place/add',
          element: <CorePlaceAdd />,
        },
        {
          path: 'place/detail',
          element: <CorePlaceDetail />,
        },
        {
          path: 'place/member-list',
          element: <CorePlaceMemberList />,
        },
        {
          path: 'place/member-add',
          element: <CorePlaceMemberAdd />,
        },
        {
          path: 'place/member-detail',
          element: <CorePlaceMemberDetail />,
        },
        {
          path: 'place/auth-list',
          element: <CorePlaceAuthList />,
        },
        {
          path: 'place/auth-add',
          element: <CorePlaceAuthAdd />,
        },
        {
          path: 'place/auth-detail',
          element: <CorePlaceAuthDetail />,
        },
        {
          path: 'group/list',
          element: <CoreGroupList />,
        },
        {
          path: 'group/add',
          element: <CoreGroupAdd />,
        },
        {
          path: 'group/detail',
          element: <CoreGroupDetail />,
        },
        {
          path: 'license/list',
          element: <CoreLicenseList />,
        },
        {
          path: 'license/add',
          element: <CoreLicenseAdd />,
        },
        {
          path: 'license/detail',
          element: <CoreLicenseDetail />,
        },
        {
          path: 'device/list',
          element: <CoreDeviceList />,
        },
        {
          path: 'device/add',
          element: <CoreDeviceAdd />,
        },
        {
          path: 'device/detail',
          element: <CoreDeviceDetail />,
        },
        {
          path: 'device/scrshot',
          element: <CoreDeviceScrshot />,
        },
        {
          path: 'device/scrshot-list',
          element: <CoreDeviceScrshotList />,
        },
        {
          path: 'pack/list',
          element: <CorePackList />,
        },
        {
          path: 'pack/add',
          element: <CorePackAdd />,
        },
        {
          path: 'pack/detail',
          element: <CorePackDetail />,
        },
        {
          path: 'pack/file-list',
          element: <CorePackFileList />,
        },
        {
          path: 'pack/file-add',
          element: <CorePackFileAdd />,
        },
        {
          path: 'pack/file-detail',
          element: <CorePackFileDetail />,
        },
        {
          path: 'account/list',
          element: <CoreAccountList />,
        },
        {
          path: 'account/add',
          element: <CoreAccountAdd />,
        },
        {
          path: 'account/detail',
          element: <CoreAccountDetail />,
        },
        {
          path: 'user/list',
          element: <CoreUserList />,
        },
        {
          path: 'user/add',
          element: <CoreUserAdd />,
        },
        {
          path: 'user/detail',
          element: <CoreUserDetail />,
        },
        {
          path: 'menu/list',
          element: <CoreMenuList />,
        },
        {
          path: 'menu/add',
          element: <CoreMenuAdd />,
        },
        {
          path: 'menu/detail',
          element: <CoreMenuDetail />,
        },
      ],
    },
  ],
  errorElement: <ErrorLayout />,
};

const interactionRoutes = {
  path: '/interaction',
  element: <ManagerLayout />,
  children: [
    {
      element: <InteractionLayout />,
      children: [
        {
          path: 'playlist/list',
          element: <InteractionPlaylistList />,
        },
        {
          path: 'playlist/detail',
          element: <InteractionPlaylistDetail />,
        },
        {
          path: 'playlist/folder-detail',
          element: <InteractionPlaylistFolderDetail />,
        },
        {
          path: 'channel/list',
          element: <InteractionChannelList />,
        },
        {
          path: 'schedule/list',
          element: <InteractionScheduleList />,
        },
        {
          path: 'storage/list',
          element: <InteractionStorageList />,
        },
        {
          path: 'storage/folder-detail',
          element: <InteractionStorageFolderDetail />,
        },
        {
          path: 'storage/file-detail',
          element: <InteractionStorageFileDetail />,
        },
        {
          path: 'public-storage/list',
          element: <InteractionPublicStorageList />,
        },
        {
          path: 'public-storage/folder-detail',
          element: <InteractionPublicStorageFolderDetail />,
        },
        {
          path: 'public-storage/file-detail',
          element: <InteractionPublicStorageFileDetail />,
        },
        {
          path: 'template/list',
          element: <InteractionTemplateList />,
        },
        {
          path: 'template/detail',
          element: <InteractionTemplateDetail />,
        },
        {
          path: 'package/list',
          element: <InteractionPackageList />,
        },
        {
          path: 'package/detail',
          element: <InteractionPackageDetail />,
        },
        {
          path: 'app/list',
          element: <InteractionAppList />,
        },
        {
          path: 'app/detail',
          element: <InteractionAppDetail />,
        },
      ],
    },
  ],
  errorElement: <ErrorLayout />,
};

const archiveRoutes = {
  path: '/archive',
  element: <ManagerLayout />,
  children: [
    {
      element: <ArchiveLayout />,
      children: [
        {
          path: 'playlist/list',
          element: <ArchivePlaylistList />,
        },
        {
          path: 'playlist/add',
          element: <ArchivePlaylistAdd />,
        },
        {
          path: 'playlist/detail',
          element: <ArchivePlaylistDetail />,
        },
        {
          path: 'package/list',
          element: <ArchivePackageList />,
        },
        {
          path: 'package/detail',
          element: <ArchivePackageDetail />,
        },
      ],
    },
  ],
  errorElement: <ErrorLayout />,
};

const storeRoutes = {
  path: '/store',
  element: <ManagerLayout />,
  children: [
    {
      element: <StoreLayout />,
      children: [
        {
          path: 'brand/list',
          element: <StoreBrandList />,
        },
        {
          path: 'brand/add',
          element: <StoreBrandAdd />,
        },
        {
          path: 'brand/detail',
          element: <StoreBrandDetail />,
        },
        {
          path: 'shop/list',
          element: <StoreShopList />,
        },
        {
          path: 'shop/add',
          element: <StoreShopAdd />,
        },
        {
          path: 'shop/detail',
          element: <StoreShopDetail />,
        },
        {
          path: 'shop/member-list',
          element: <StoreShopMemberList />,
        },
        {
          path: 'shop/member-add',
          element: <StoreShopMemberAdd />,
        },
        {
          path: 'shop/member-detail',
          element: <StoreShopMemberDetail />,
        },
        {
          path: 'storage/list',
          element: <StoreStorageList />,
        },
        {
          path: 'storage/folder-detail',
          element: <StoreStorageFolderDetail />,
        },
        {
          path: 'storage/file-detail',
          element: <StoreStorageFileDetail />,
        },
        {
          path: 'customer/list',
          element: <StoreCustomerList />,
        },
        {
          path: 'customer/add',
          element: <StoreCustomerAdd />,
        },
        {
          path: 'customer/detail',
          element: <StoreCustomerDetail />,
        },
      ],
    },
  ],
  errorElement: <ErrorLayout />,
};

const systemRoutes = {
  path: '/system',
  element: <ManagerLayout />,
  children: [
    {
      element: <SystemLayout />,
      children: [
        {
          path: 'admin/list',
          element: <SystemAdminList />,
        },
        {
          path: 'admin/detail',
          element: <SystemAdminDetail />,
        },
        {
          path: 'admin/add',
          element: <SystemAdminAdd />,
        },
      ],
    },
  ],
  errorElement: <ErrorLayout />,
};

const noMatchRoutes = {
  path: '*',
  element: <Navigate to="/" />,
  errorElement: <ErrorLayout />,
};

const router = createBrowserRouter([
  rootRoutes,
  authRoutes,
  profileRoutes,
  dashboardRoutes,
  boardRoutes,
  coreRoutes,
  interactionRoutes,
  archiveRoutes,
  storeRoutes,
  systemRoutes,
  noMatchRoutes,
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
