import * as apiLib from './api';

export const list = async ({ brandId, startKey, limit }) => {
  try {
    const data = {
      brandId,
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/store-goods/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, goodsList: result.goodsList };
    } else {
      throw Object.assign(new Error('goods list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ goodsId }) => {
  try {
    const data = {
      goodsId: goodsId,
    };

    const result = await apiLib.fetchOperationApi('/store-goods/detail', data);
    if (result.resultFlag) {
      return result.goodsInfo;
    } else {
      throw Object.assign(new Error('goods detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  spaceId,
  brandId,
  goodsType,
  goodsNm,
  placeCount,
  placeLimit,
}) => {
  try {
    const data = {
      spaceId,
      brandId,
      goodsType,
      goodsNm,
      placeCount,
      placeLimit,
    };

    const result = await apiLib.fetchOperationApi('/store-goods/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('goods add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  goodsId,
  spaceId,
  brandId,
  goodsType,
  goodsNm,
  useYn,
  delYn,
}) => {
  try {
    const data = {
      goodsId,
      spaceId,
      goodsType,
      goodsNm,
      useYn,
      delYn,
    };

    const result = await apiLib.fetchOperationApi('/store-goods/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('goods edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ goodsId }) => {
  try {
    const data = { goodsId };

    const result = await apiLib.fetchOperationApi('/store-goods/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('goods remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
