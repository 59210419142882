import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface LayerDataState {
  layerId: string;
  layerOrder: number;
  width: number;
  height: number;
  x: number;
  y: number;
  title: number;
  color: string;
  baseYn: string;
  muteYn: string;
  frameLinkId: string;
  lockYn: string;
}

interface LayerInfoState {
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  muteYn?: string;
  layerOrder?: number;
}

interface LayerPayload {
  activeframeId: string;
  activeLayerId?: string;
  layer?: LayerDataState;
  layerInfo?: LayerInfoState;
  layerList?: Array<LayerDataState>;
  frameLinkId? : string;
  copiedLayer?: LayerDataState;
}
  
interface LayerState {
  layerList: Array<LayerDataState>;
  isLoading: boolean;
  error: string | null;
}

const InitLayer: LayerDataState = {
  layerId: '',
  frameLinkId: 'none',
  baseYn: 'N',
  title: '',
  color: '',
  layerOrder: 0,
  lockYn: 'N',
  muteYn: 'N',
  width: 0,
  height: 0,
  x: 0,
  y: 0,
}

const initialState: LayerState = {
  frameId: '',
  layerList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'layer',
  initialState,
  reducers: {
    initLayerState(state: LayerState, { payload }: PayloadAction<String>) {
      state.frameId = '';
      state.layerList = [];
      state.actionResult = '';
    },
    getLayerList(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getLayerListSuccess(state: LayerState, { payload: { layerList }}: PayloadAction<LayerState>) {
      state.frameId = layerList[0].frameId;
      state.layerList = layerList;
      
      state.actionResult = 'LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    getLayerListFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    addLayer(state: LayerState, { payload: { activeFrameId, layer }}: PayloadAction<LayerPayload>) {
      if (state.frameId !== activeFrameId) {
        state.frameId = activeFrameId;
        state.layerList = [];
      }
      state.layerList.push(layer);

      state.actionResult = 'ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addLayerSuccess(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    addLayerFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'ADD_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    updateLayer(state: LayerState, { payload: { activeLayerId, layerInfo }}: PayloadAction<LayerPayload>) {
      const layer = state.layerList.find(layer => layer.layerId === activeLayerId);
      const layerProps = Object.keys(layerInfo);
      layerProps.forEach(prop =>
        layer[prop] = layerInfo[prop]
      );

      state.actionResult = 'UPDATE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    updateLayerSuccess(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'UPDATE_OK';
      state.isLoading = false;
      state.error = null;
    },
    updateLayerFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'UPDATE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    removeLayer(state: LayerState, { payload: { activeLayerId }}: PayloadAction<LayerPayload>) {
      const index = state.layerList.findIndex(layer => layer.layerId === activeLayerId);
      state.layerList.splice(index, 1);

      state.actionResult = 'REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeLayerSuccess(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removeLayerFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'REMOVE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    // sortLayer(state: LayerState, { payload }: PayloadAction<LayerPayload>) {
    //   onSortLayer({
  //     layerId: layerProps.layerId,
  //     layerOrder: index
  //   });

    //   state.isLoading = true;
    //   state.error = null;
    // },
    // sortLayerSuccess(state: LayerState, { payload }: PayloadAction<String>) {
    //   state.isLoading = false;
    //   state.error = null;
    // },
    // sortLayerFailure(state: LayerState, { payload }: PayloadAction<String>) {
    //   state.isLoading = false;
    //   state.error = payload;
    // },
    moveToLayer(state: LayerState, { payload: { activeLayerId, where }}: PayloadAction<LayerPayload>) {
      const index = state.layerList.findIndex(layer => layer.layerId === activeLayerId);
      const targetLayer = state.layerList[index];
      state.layerList.splice(index, 1);

      if (where === 'top') {
        state.layerList.push(targetLayer);
      } else if (where === 'bottom') {
        state.layerList.unshift(targetLayer);
      }

      state.layerList.forEach((layer, index) =>
        layer.layerOrder = index
      );

      state.isLoading = true;
      state.error = null;
    },
    moveToLayerSuccess(state: LayerState, { payload }: PayloadAction<String>) {
      state.isLoading = false;
      state.error = null;
    },
    moveToLayerFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.isLoading = false;
      state.error = payload;
    },
    setBaseLayer(state: LayerState, { payload: { activeLayerId }}: PayloadAction<LayerPayload>) {
      state.layerList.forEach(layer =>
        layer.layerId === activeLayerId ? layer.baseYn = 'Y' : layer.baseYn = 'N'
      );

      state.isLoading = true;
      state.error = null;
    },
    setBaseLayerSuccess(state: LayerState, { payload }: PayloadAction<String>) {
      state.isLoading = false;
      state.error = null;
    },
    setBaseLayerFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.isLoading = false;
      state.error = payload;
    },
    setLayerHistory(state: LayerState, { payload }: PayloadAction<LayerPayload>) {
      state.actionResult = 'SET_HISTORY_REQ';
      state.isLoading = true;
      state.error = null;
    },
    setLayerHistorySuccess(state: LayerState, { payload: newLayerList }: PayloadAction<LayerPayload>) {
      state.layerList = newLayerList;

      state.actionResult = 'SET_HISTORY_OK';
      state.isLoading = false;
      state.error = null;
    },
    setLayerHistoryFailure(state: LayerState, { payload }: PayloadAction<LayerPayload>) {
      state.actionResult = 'SET_HISTORY_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    setFrameLink(state: LayerState, { payload: { layerId, frameLinkId } }: PayloadAction<LayerPayload>) {
      const index = state.layerList.findIndex(layer => layer.layerId === layerId);
      state.layerList[index].frameLinkId = frameLinkId;
      state.isLoading = true;
      state.error = null;
    },
    pasteLayer(state: LayerState, { payload: { pasteLayer }}: PayloadAction<LayerPayload>) {
      state.layerList.push(pasteLayer);

      state.actionResult = 'PASTE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    pasteLayerSuccess(state: LayerState, { payload }: PayloadAction<String>) {
      console.log('복사 성공');

      state.actionResult = 'PASTE_OK';
      state.isLoading = false;
      state.error = null;
    },
    pasteLayerFailure(state: LayerState, { payload }: PayloadAction<String>) {
      state.actionResult = 'PASTE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
  }
});

const selectLayerList = createDraftSafeSelector(
  (state: LayerState) => state.layerList,
  (layerList) => layerList,
);

const selectActiveLayer = createDraftSafeSelector(
  (state: any) => state,
  (state) => state.layer.layerList.find(layer => layer.layerId === state.activeItem.activeLayerId) || InitLayer,
);

const selectBaseLayer = createDraftSafeSelector(
  (state: any) => state,
  (state) => state.layer.layerList.find(layer => layer.baseYn === 'Y'),
);

const selectStatus = createDraftSafeSelector(
  (state: LayerState) => state.actionResult,
  (state: LayerState) => state.isLoading,
  (state: LayerState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const layerSelector = {
  layerList: state => selectLayerList(state[LAYER]),
  activeLayer: state => selectActiveLayer(state),
  baseLayer: state => selectBaseLayer(state),
  status: state => selectStatus(state[LAYER]),
};

export const LAYER = slice.name;
export const layerReducer = slice.reducer;
export const layerAction = slice.actions;
