import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface CalendarAppDataState {
  calendarName: string;
  dateCheck: string;
  iCalendarUrlYn: string;
  iCalendarUrl: string;
}

interface WeatherAppDataState {
  location: string;
  degreeCheck: string;
  timeForecastYn: string;
  dayForecastYn: string;
}

interface TextAppDataState {
  fontSize: string;
  text: string;
  frameLinkYn: string;
}

interface FrameNoAppDataState {
  frameNoYn: string;
}

interface MediaDataState {
  file: Array<any>;
  animation: string;
  zoom: string;
  fitOption: string;
}

interface LayerContentsState {
  playlistId: string;
  frameId: string;
  layerId: string;
  contentsId: string;
  contentsType: string;
  contentsData:
    CalendarAppDataState
    | WeatherAppDataState
    | TextAppDataState
    | FrameNoAppDataState
    | MediaDataState;
  contentsFileList: Array<any>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

interface OnSubmitPayload {
  contentsId?: string;
  contentsType: string;
  contentsData:
    CalendarAppDataState
    | WeatherAppDataState
    | TextAppDataState
    | FrameNoAppDataState
    | MediaDataState;
}

const initialState: LayerContentsState = {
  playlistId: '',
  frameId: '',
  layerId: '',
  contentsId: '',
  contentsType: '',
  contentsData: {},
  contentsFileList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'layerContents',
  initialState,
  reducers: {
    initLayerContentsState(state: LayerContentsState) {
      state.playlistId = '';
      state.frameId = '';
      state.layerId = '';
      state.contentsId = '';
      state.contentsType = '';
      state.contentsData = {};
      state.contentsFileList = [];
    },
    getLayerContents(state: LayerContentsState) {
      state.actionResult = 'GET_CONTENTS_REQ';
      state.isLoading = true;
      state.error = null;
    },
    getLayerContentsSuccess(state: LayerContentsState, { payload: { playlistId, layerId, frameId = '', contentsId = '', contentsType = '', contentsData = {}, contentsFileList = [] } }: PayloadAction<LayerContentsState>) {
      state.playlistId = playlistId;
      state.frameId = frameId;
      state.layerId = layerId;
      state.contentsId = contentsId;
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;

      state.actionResult = 'GET_CONTENTS_OK';
      state.isLoading = false;
      state.error = null;
    },
    getLayerContentsFailure(state: LayerContentsState, { payload: error}: PayloadAction<string>) {
      state.actionResult = 'GET_CONTENTS_ERR';
      state.error = error;
      state.isLoading = false;
    },
    saveLayerContents(state: LayerContentsState) {
      state.isLoading = true;
      state.error = null;
    },
    saveLayerContentsSuccess(state: LayerContentsState, { payload: { contentsId, contentsType, contentsData, contentsFileList } }: PayloadAction<OnSubmitPayload>) {
      state.contentsId = contentsId;
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;
      state.isLoading = false;
      state.error = null;
    },
    saveLayerContentsFailure(state: LayerContentsState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
    updateLayerContents(state: LayerContentsState) {
      state.isLoading = true;
      state.error = null;
    },
    updateLayerContentsSuccess(state: LayerContentsState, { payload: { contentsType, contentsData, contentsFileList } }: PayloadAction<OnSubmitPayload>) {
      state.contentsType = contentsType;
      state.contentsData = contentsData;
      state.contentsFileList = contentsFileList;
      state.isLoading = false;
      state.error = null;
    },
    updateLayerContentsFailure(state: LayerContentsState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
    removeLayerContents(state: LayerContentsState) {
      state.isLoading = true;
      state.error = null;
    },
    removeLayerContentsSuccess(state: LayerContentsState) {
      state.contentsId = initialState.contentsId;
      state.contentsType = initialState.contentsType;
      state.contentsData = initialState.contentsData;
      state.contentsFileList = initialState.contentsFileList;
      state.isLoading = false;
      state.error = null;
    },
    removeLayerContentsFailure(state: LayerContentsState, { payload: error }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = error;
    },
  },
});

const selectLayerContents = createDraftSafeSelector(
  (state: LayerContentsState) => state.playlistId,
  (state: LayerContentsState) => state.frameId,
  (state: LayerContentsState) => state.layerId,
  (state: LayerContentsState) => state.contentsId,
  (state: LayerContentsState) => state.contentsType,
  (state: LayerContentsState) => state.contentsData,
  (state: LayerContentsState) => state.contentsFileList,
  (playlistId, frameId, layerId, contentsId, contentsType, contentsData, contentsFileList) => ({ playlistId, frameId, layerId, contentsId, contentsType, contentsData, contentsFileList }),
);

const selectStatus = createDraftSafeSelector(
  (state: LayerContentsState) => state.actionResult,
  (state: LayerContentsState) => state.isLoading,
  (state: LayerContentsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const layerContentsSelector = {
  layerContents: state => selectLayerContents(state[LAYERCONTENTS]),
  status: state => selectStatus(state[LAYERCONTENTS]),
};

export const LAYERCONTENTS = slice.name;
export const layerContentsReducer = slice.reducer;
export const layerContentsAction = slice.actions;
