import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { adminAction } from './adminSlice';

import * as adminApiLib from '../lib/adminApi';

function* handleList({ payload }) {
  try {
    const adminList = yield call(adminApiLib.list, payload);

    yield put(adminAction.listSuccess({ adminList }));
  } catch (err) {
    yield put(adminAction.listFailure('List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const adminInfo = yield call(adminApiLib.detail, payload);

    yield put(adminAction.detailSuccess({ adminInfo }));
  } catch (err) {
    yield put(adminAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(adminApiLib.add, payload);

    yield put(adminAction.addSuccess());
  } catch (err) {
    yield put(adminAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(adminApiLib.edit, payload);

    yield put(adminAction.editSuccess());
  } catch (err) {
    yield put(adminAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(adminApiLib.remove, payload);

    yield put(adminAction.removeSuccess());
  } catch (err) {
    yield put(adminAction.removeFailure('Remove Error'));
  }
}

function* handlePasswordChange({ payload }) {
  try {
    yield call(adminApiLib.passwordChange, payload);

    yield put(adminAction.passwordChangeSuccess());
  } catch (err) {
    yield put(adminAction.passwordChangeFailure('Password Change Error'));
  }
}

function* handleImageUpload({ payload }) {
  try {
    const adminInfo = yield call(adminApiLib.upload, payload);

    yield put(adminAction.imageUploadSuccess({ adminInfo }));
  } catch (err) {
    yield put(adminAction.imageUploadFailure('Image Upload Error'));
  }
}

export function* watchList() {
  yield takeLatest(adminAction.list, handleList);
}

export function* watchDetail() {
  yield takeLatest(adminAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(adminAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(adminAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(adminAction.remove, handleRemove);
}

export function* watchPasswordChange() {
  yield takeLatest(adminAction.passwordChange, handlePasswordChange);
}

export function* watchImageUpload() {
  yield takeLatest(adminAction.imageUpload, handleImageUpload);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchPasswordChange),
    fork(watchImageUpload),
  ]);
}

export default rootSaga;
