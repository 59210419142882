import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchOperationApi('/menu/list', data);
    if (result.resultFlag) {
      return result.menuList;
    } else {
      throw Object.assign(new Error('menu list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchOperationApi('/menu/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('menu add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchOperationApi('/menu/detail', data);
    if (result.resultFlag) {
      return result.menuInfo;
    } else {
      throw Object.assign(new Error('menu detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchOperationApi('/menu/edit', data);
    if (result.resultFlag) {
      return result.menuInfo;
    } else {
      throw Object.assign(new Error('menu edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchOperationApi('/menu/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('menu remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};