import * as apiLib from './api';

export const list = async ({ startKey, limit }) => {
  try {
    const data = {
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/space/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, spaceList: result.spaceList };
    } else {
      throw Object.assign(new Error('space list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ spaceId }) => {
  try {
    const data = {
      spaceId: spaceId,
    };

    const result = await apiLib.fetchOperationApi('/space/detail', data);
    if (result.resultFlag) {
      return result.spaceInfo;
    } else {
      throw Object.assign(new Error('space detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ spaceType, spaceNm, placeCount, placeLimit }) => {
  try {
    const data = {
      spaceType,
      spaceNm,
      placeCount,
      placeLimit,
    };

    const result = await apiLib.fetchOperationApi('/space/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('space add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  spaceId,
  spaceType,
  spaceNm,
  placeCount,
  placeLimit,
  useYn,
  delYn,
  companyCodeList,
}) => {
  try {
    const data = {
      spaceId,
      spaceType,
      spaceNm,
      placeCount,
      placeLimit,
      useYn,
      delYn,
      companyCodeList,
    };

    const result = await apiLib.fetchOperationApi('/space/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('space edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ spaceId }) => {
  try {
    const data = { spaceId };

    const result = await apiLib.fetchOperationApi('/space/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('space remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
