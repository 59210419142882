import React, { useState, useCallback, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import FadeLoader from 'react-spinners/FadeLoader';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { authSelector } from '../../module/authSlice';

const Token = React.lazy(() => import('../auth/Token'));
const Header = React.lazy(() => import('../common/Header'));
const NavBar = React.lazy(() => import('../common/NavBar'));
const Footer = React.lazy(() => import('../common/Footer'));

const loading = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <FadeLoader color={'#41a1ea'} loading={true} size={20} />
  </div>
);

const contentLoading = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <FadeLoader color={'#41a1ea'} loading={true} size={20} />
  </div>
);

const Manager = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const status = useSelector(authSelector.status);

  const onMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  if (!status.isLogin) {
    return (
      <Suspense fallback={loading()}>
        <Token />
      </Suspense>
    );
  }

  return (
    <Container $isMobile={isMobile}>
      {isMenuOpen && (
        <Suspense fallback={loading()}>
          <NavBar />
        </Suspense>
      )}
      <Content $isMobile={isMobile} $isMenuOpen={isMenuOpen}>
        <Suspense fallback={loading()}>
          <Header onMenuToggle={onMenuToggle} />
        </Suspense>
        <Suspense fallback={contentLoading()}>
          <Outlet />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
          />
        </Suspense>
        <Suspense fallback={loading()}>
          <Footer />
        </Suspense>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
`;

const Content = styled.div`
  flex: 1;
  margin-top: ${props => (props.$isMobile ? '4rem' : '0')};
  margin-left: ${props => (props.$isMobile || !props.$isMenuOpen ? '0' : '12.5rem')};
  display: flex;
  flex-direction: column;
`;

export default Manager;
