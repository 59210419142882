import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import { endpoints } from 'rtk/system/authApi';

const initialState = {
  isLogin: false,
  loginInfo: {},
  globalSpaceInfo: sessionStorage.getItem('globalSpace')
    ? JSON.parse(sessionStorage.getItem('globalSpace'))
    : { spaceId: 'notall', spaceNm: '미선택' },
  globalPlaceInfo: sessionStorage.getItem('globalPlace')
    ? JSON.parse(sessionStorage.getItem('globalPlace'))
    : { placeId: 'notall', placeNm: '미선택' },
  globalBrandInfo: sessionStorage.getItem('globalBrand')
    ? JSON.parse(sessionStorage.getItem('globalBrand'))
    : { brandId: 'notall', brandNm: '미선택' },
  globalShopInfo: sessionStorage.getItem('globalShop')
    ? JSON.parse(sessionStorage.getItem('globalShop'))
    : { spaceId: '', placeId: '', shopId: 'notall', shopNm: '미선택' },
};

const reducers = {
  setState: (state, { payload: { key, value } }) => {
    state[key] = value;
  },
  setStateList: (state, { payload }) => {
    for (const item of payload) {
      state[item.key] = item.value;
    }
  },
  setGlobalSpaceInfo: (state, { payload: { spaceId, spaceNm } }) => {
    state.globalSpaceInfo = { spaceId, spaceNm };
    state.globalPlaceInfo = { placeId: 'notall', placeNm: '미선택' };
    state.globalBrandInfo = { brandId: 'notall', brandNm: '미선택' };
    state.globalShopInfo = { spaceId: '', placeId: '', shopId: 'notall', shopNm: '미선택' };
    sessionStorage.setItem('globalSpace', JSON.stringify(state.globalSpaceInfo));
    sessionStorage.setItem('globalPlace', JSON.stringify(state.globalPlaceInfo));
    sessionStorage.setItem('globalBrand', JSON.stringify(state.globalBrandInfo));
    sessionStorage.setItem('globalShop', JSON.stringify(state.globalShopInfo));
  },
  setGlobalPlaceInfo: (state, { payload: { placeId, placeNm } }) => {
    state.globalPlaceInfo = { placeId, placeNm };
    sessionStorage.setItem('globalPlace', JSON.stringify(state.globalPlaceInfo));
  },
  setGlobalBrandInfo: (state, { payload: { brandId, brandNm } }) => {
    state.globalBrandInfo = { brandId, brandNm };
    state.globalShopInfo = { spaceId: '', placeId: '', shopId: 'notall', shopNm: '미선택' };
    sessionStorage.setItem('globalBrand', JSON.stringify(state.globalBrandInfo));
    sessionStorage.setItem('globalShop', JSON.stringify(state.globalShopInfo));
  },
  setGlobalShopInfo: (state, { payload: { spaceId, placeId, shopId, shopNm } }) => {
    state.globalShopInfo = { spaceId, placeId, shopId, shopNm };
    sessionStorage.setItem('globalShop', JSON.stringify(state.globalShopInfo));
  },
};

const extraReducers = builder => {
  builder.addMatcher(endpoints.login.matchFulfilled, (state, { payload }) => {
    state.isLogin = true;
    state.loginInfo = payload;
  });
  builder.addMatcher(endpoints.login.matchRejected, (state, { payload }) => {
    state.isLogin = false;
    state.loginInfo = {};
    sessionStorage.removeItem('globalSpace');
    sessionStorage.removeItem('globalPlace');
    sessionStorage.removeItem('globalBrand');
    sessionStorage.removeItem('globalShop');
  });
  builder.addMatcher(endpoints.token.matchFulfilled, (state, { payload }) => {
    state.isLogin = true;
    state.loginInfo = payload;
  });
  builder.addMatcher(endpoints.token.matchRejected, (state, { payload }) => {
    state.isLogin = false;
    state.loginInfo = {};
    sessionStorage.removeItem('globalSpace');
    sessionStorage.removeItem('globalPlace');
    sessionStorage.removeItem('globalBrand');
    sessionStorage.removeItem('globalShop');
  });
  builder.addMatcher(endpoints.logout.matchFulfilled, (state, { payload }) => {
    state.isLogin = false;
    state.loginInfo = {};
    sessionStorage.removeItem('globalSpace');
    sessionStorage.removeItem('globalPlace');
    sessionStorage.removeItem('globalBrand');
    sessionStorage.removeItem('globalShop');
  });
};

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const selectLoginInfo = createDraftSafeSelector(
  state => state,
  state => state.loginInfo,
);

const selectGlobalSpaceInfo = createDraftSafeSelector(
  state => state,
  state => state.globalSpaceInfo,
);

const selectGlobalPlaceInfo = createDraftSafeSelector(
  state => state,
  state => state.globalPlaceInfo,
);

const selectGlobalBrandInfo = createDraftSafeSelector(
  state => state,
  state => state.globalBrandInfo,
);

const selectGlobalShopInfo = createDraftSafeSelector(
  state => state,
  state => state.globalShopInfo,
);

const selectStatus = createDraftSafeSelector(
  state => state,
  state => ({ isLogin: state.isLogin }),
);

export const authSelector = {
  loginInfo: state => selectLoginInfo(state[slice.name]),
  globalSpaceInfo: state => selectGlobalSpaceInfo(state[slice.name]),
  globalPlaceInfo: state => selectGlobalPlaceInfo(state[slice.name]),
  globalBrandInfo: state => selectGlobalBrandInfo(state[slice.name]),
  globalShopInfo: state => selectGlobalShopInfo(state[slice.name]),
  status: state => selectStatus(state[slice.name]),
};

export const AUTH = slice.name;
export const authReducer = slice.reducer;
export const authAction = slice.actions;
