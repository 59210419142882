import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { deviceAction } from './deviceSlice';

import * as deviceApiLib from '../lib/deviceApi';

function* deviceList({ payload }) {
  try {
    const { lastKey, deviceList } = yield call(deviceApiLib.list, payload);

    yield put(deviceAction.deviceListSuccess({ lastKey, deviceList }));
  } catch (err) {
    yield put(deviceAction.deviceListFailure('Device List Error'));
  }
}

function* deviceDetail({ payload }) {
  try {
    const deviceInfo = yield call(deviceApiLib.detail, payload);

    yield put(deviceAction.deviceDetailSuccess({ deviceInfo }));
  } catch (err) {
    yield put(deviceAction.deviceDetailFailure('Device Detail Error'));
  }
}

function* deviceAdd({ payload }) {
  try {
    yield call(deviceApiLib.add, payload);

    yield put(deviceAction.deviceAddSuccess());
  } catch (err) {
    yield put(deviceAction.deviceAddFailure('Device Add Error'));
  }
}

function* deviceEdit({ payload }) {
  try {
    yield call(deviceApiLib.edit, payload);

    yield put(deviceAction.deviceEditSuccess());
  } catch (err) {
    yield put(deviceAction.deviceEditFailure('Device Edit Error'));
  }
}

function* deviceRemove({ payload }) {
  try {
    yield call(deviceApiLib.remove, payload);

    yield put(deviceAction.deviceRemoveSuccess());
  } catch (err) {
    yield put(deviceAction.deviceRemoveFailure('Device Remove Error'));
  }
}

function* deviceCommand({ payload }) {
  try {
    yield call(deviceApiLib.command, payload);

    yield put(deviceAction.deviceCommandSuccess());
  } catch (err) {
    yield put(deviceAction.deviceCommandFailure('Device Command Error'));
  }
}

function* deviceCommandSocketList({ payload }) {
  try {
    const socketList = yield call(deviceApiLib.commandSocketList, payload);

    yield put(deviceAction.deviceCommandSocketListSuccess({ socketList }));
  } catch (err) {
    yield put(deviceAction.deviceCommandSocketListFailure('Device Command Socket List Error'));
  }
}

function* deviceCommandSocketSend({ payload }) {
  try {
    yield call(deviceApiLib.commandSocketSend, payload);

    yield put(deviceAction.deviceCommandSocketSendSuccess());
  } catch (err) {
    yield put(deviceAction.deviceCommandSocketSendFailure('Device Command Socket Send Error'));
  }
}

function* devicePowerUpdate({ payload }) {
  try {
    yield call(deviceApiLib.powerUpdate, payload);

    yield put(deviceAction.devicePowerUpdateSuccess());
  } catch (err) {
    yield put(
      deviceAction.devicePowerUpdateFailure('Device Power Update Error'),
    );
  }
}

function* pairing({ payload }) {
  try {
    const pairingInfo = yield call(deviceApiLib.pairing, payload);

    yield put(deviceAction.pairingSuccess({ pairingInfo }));
  } catch (err) {
    yield put(deviceAction.pairingFailure('Pairing Error'));
  }
}

function* pairingCancel({ payload }) {
  try {
    yield call(deviceApiLib.pairingCancel, payload);

    yield put(deviceAction.pairingSuccess({}));
  } catch (err) {
    yield put(deviceAction.pairingFailure('Pairing Error'));
  }
}

function* pairingUnset({ payload }) {
  try {
    yield call(deviceApiLib.pairingUnset, payload);

    const deviceList = yield call(deviceApiLib.list, payload);

    yield put(deviceAction.deviceListSuccess({ deviceList }));
  } catch (err) {
    yield put(deviceAction.deviceListFailure('Pairing Unset Error'));
  }
}

function* stop({ payload }) {
  try {
    yield call(deviceApiLib.stop, payload);

    yield put(deviceAction.stopSuccess('Stop Success'));

    const deviceList = yield call(deviceApiLib.list, payload);

    yield put(deviceAction.deviceListSuccess({ deviceList }));
  } catch (error) {
    yield put(deviceAction.stopFailure('Stop Error'));
  }
}

function* play({ payload }) {
  try {
    yield call(deviceApiLib.play, payload);

    yield put(deviceAction.playSuccess('Play Success'));

    const deviceList = yield call(deviceApiLib.list, payload);

    yield put(deviceAction.deviceListSuccess({ deviceList }));
  } catch (error) {
    yield put(deviceAction.playFailure('Play Error'));
  }
}

function* playUnset({ payload }) {
  try {
    yield call(deviceApiLib.playUnset, payload);

    yield put(deviceAction.playSuccess('Play Success'));

    const deviceList = yield call(deviceApiLib.list, payload);

    yield put(deviceAction.deviceListSuccess({ deviceList }));
  } catch (error) {
    yield put(deviceAction.playFailure('Play Error'));
  }
}

function* scrshotList({ payload }) {
  try {
    const scrshotList = yield call(deviceApiLib.scrshotList, payload);

    yield put(deviceAction.scrshotListSuccess({ scrshotList }));
  } catch (err) {
    yield put(deviceAction.scrshotListFailure('Scrshot List Error'));
  }
}

function* groupMove({ payload }) {
  try {
    yield call(deviceApiLib.groupMove, payload);

    yield put(deviceAction.groupMoveSuccess());
  } catch (error) {
    yield put(deviceAction.groupMoveFailure('Group Move Error'));
  }
}

function* remoteUrl({ payload }) {
  try {
    const remoteUrl = yield call(deviceApiLib.remoteUrl, payload);

    yield put(deviceAction.remoteUrlSuccess({ remoteUrl }));
  } catch (error) {
    yield put(deviceAction.remoteUrlFailure('Remote Url Error'));
  }
}

export function* watchDeviceList() {
  yield takeLatest(deviceAction.deviceList, deviceList);
}

export function* watchDeviceInfo() {
  yield takeLatest(deviceAction.deviceDetail, deviceDetail);
}

export function* watchDeviceAdd() {
  yield takeLatest(deviceAction.deviceAdd, deviceAdd);
}

export function* watchDeviceEdit() {
  yield takeLatest(deviceAction.deviceEdit, deviceEdit);
}

export function* watchDeviceRemove() {
  yield takeLatest(deviceAction.deviceRemove, deviceRemove);
}

export function* watchDeviceCommand() {
  yield takeLatest(deviceAction.deviceCommand, deviceCommand);
}

export function* watchDeviceCommandSocketList() {
  yield takeLatest(deviceAction.deviceCommandSocketList, deviceCommandSocketList);
}

export function* watchDeviceCommandSocketSend() {
  yield takeLatest(deviceAction.deviceCommandSocketSend, deviceCommandSocketSend);
}

export function* watchDevicePowerUpdate() {
  yield takeLatest(deviceAction.devicePowerUpdate, devicePowerUpdate);
}

export function* watchPairing() {
  yield takeLatest(deviceAction.pairing, pairing);
}

export function* watchPairingCancel() {
  yield takeLatest(deviceAction.pairingCancel, pairingCancel);
}

export function* watchPairingUnset() {
  yield takeLatest(deviceAction.pairingUnset, pairingUnset);
}

export function* watchStop() {
  yield takeLatest(deviceAction.stop, stop);
}

export function* watchPlay() {
  yield takeLatest(deviceAction.play, play);
}

export function* watchPlayUnset() {
  yield takeLatest(deviceAction.playUnset, playUnset);
}

export function* watchScrshotList() {
  yield takeLatest(deviceAction.scrshotList, scrshotList);
}

export function* watchGroupMove() {
  yield takeLatest(deviceAction.groupMove, groupMove);
}

export function* watchRemoteUrl() {
  yield takeLatest(deviceAction.remoteUrl, remoteUrl);
}

function* rootSaga() {
  yield all([
    fork(watchDeviceList),
    fork(watchDeviceInfo),
    fork(watchDeviceAdd),
    fork(watchDeviceEdit),
    fork(watchDeviceRemove),
    fork(watchDeviceCommand),
    fork(watchDeviceCommandSocketList),
    fork(watchDeviceCommandSocketSend),
    fork(watchDevicePowerUpdate),
    fork(watchPairing),
    fork(watchPairingCancel),
    fork(watchPairingUnset),
    fork(watchStop),
    fork(watchPlay),
    fork(watchPlayUnset),
    fork(watchScrshotList),
    fork(watchGroupMove),
    fork(watchRemoteUrl),
  ]);
}

export default rootSaga;
