import dayjs from 'dayjs';
import { managerBaseApi } from '../managerBaseApi';

export const authApi = managerBaseApi.injectEndpoints({
  endpoints: build => ({
    login: build.mutation({
      async queryFn(args, { dispatch, getState }, extraOptions, baseApi) {
        try {
          const result = await baseApi({ url: '/auth/create-token', data: args });
          if (result.data.resultFlag) {
            const loginInfo = { ...result.data.loginInfo, accessToken: result.data.accessToken };
            loginInfo.lastLoginDt = dayjs(loginInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');
            localStorage.setItem('accessToken', loginInfo.accessToken);
            return { data: loginInfo };
          } else {
            throw new Error('no login');
          }
        } catch (error) {
          return { error };
        }
      },
    }),
    token: build.mutation({
      async queryFn(args, { dispatch, getState }, extraOptions, baseApi) {
        try {
          const accessToken = localStorage.getItem('accessToken');
          if (!accessToken) {
            throw Object.assign(new Error('token error'), { status: 401 });
          }

          const result = await baseApi({ url: '/auth/confirm-token', data: { accessToken } });
          if (result.data.resultFlag) {
            const loginInfo = { ...result.data.loginInfo, accessToken };
            loginInfo.lastLoginDt = dayjs(loginInfo.lastLoginDt).format('YYYY-MM-DD HH:mm:ss');
            return { data: loginInfo };
          } else {
            throw new Error('no token');
          }
        } catch (error) {
          localStorage.removeItem('accessToken');
          return { error };
        }
      },
    }),
    logout: build.mutation({
      async queryFn(args, { dispatch, getState }, extraOptions, baseApi) {
        try {
          localStorage.removeItem('accessToken');
          return { data: true };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useLoginMutation, useTokenMutation, useLogoutMutation } = authApi;
