import * as apiLib from './api';

export const noticeList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/board/notice-list', data);
    if (result.resultFlag) {
      return result.noticeList;
    } else {
      throw Object.assign(new Error('notice list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const noticeDetail = async ({ noticeId }) => {
  try {
    const data = {
      noticeId,
    };

    const result = await apiLib.fetchOperationApi('/board/notice-detail', data);
    if (result.resultFlag) {
      return result.notice;
    } else {
      throw Object.assign(new Error('notice detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const noticeAdd = async ({ noticeId, noticeType, noticeStatus, noticeTitle, noticeDetail, useYn }) => {
  try {
    const data = {
      noticeId,
      noticeType,
      noticeStatus,
      noticeTitle,
      noticeDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/notice-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('notice add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const noticeFileAdd = async ({ noticeId, fileNm, fileType, fileSize, fileThumb }) => {
  try {
    const data = {
      noticeId,
      fileNm,
      fileType,
      fileSize,
      fileThumb,
    };

    const result = await apiLib.fetchOperationApi('/board/notice-file-presign-upload', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('file add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const noticeEdit = async ({ noticeId, noticeType, noticeStatus, noticeTitle, noticeDetail, useYn }) => {
  try {
    const data = {
      noticeId,
      noticeType,
      noticeStatus,
      noticeTitle,
      noticeDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/notice-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('notice edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const noticeRemove = async ({ noticeId }) => {
  try {
    const data = {
      noticeId,
    };

    const result = await apiLib.fetchOperationApi('/board/notice-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('notice remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const releaseList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/board/release-list', data);
    if (result.resultFlag) {
      return result.releaseList;
    } else {
      throw Object.assign(new Error('release list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const releaseAdd = async ({ releaseType, releaseTitle, releaseDetail, useYn }) => {
  try {
    const data = {
      releaseType,
      releaseTitle,
      releaseDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/release-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('release add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const releaseEdit = async ({ releaseId, releaseType, releaseTitle, releaseDetail, useYn }) => {
  try {
    const data = {
      releaseId,
      releaseType,
      releaseTitle,
      releaseDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/release-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('release edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const releaseDetail = async ({ releaseId }) => {
  try {
    const data = {
      releaseId,
    };

    const result = await apiLib.fetchOperationApi('/board/release-detail', data);
    if (result.resultFlag) {
      return result.release;
    } else {
      throw Object.assign(new Error('release detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const releaseRemove = async ({ releaseId }) => {
  try {
    const data = {
      releaseId,
    };

    const result = await apiLib.fetchOperationApi('/board/release-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('release remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/board/qna-list', data);
    if (result.resultFlag) {
      return result.qnaList;
    } else {
      throw Object.assign(new Error('qna list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaDetail = async ({ qnaId }) => {
  try {
    const data = {
      qnaId,
    };

    const result = await apiLib.fetchOperationApi('/board/qna-detail', data);
    if (result.resultFlag) {
      return result.qna;
    } else {
      throw Object.assign(new Error('qna detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaAdd = async ({ accountId, placeId, placeNm, userId, userNm, userTel, qTitle, qDetail, useYn }) => {
  try {
    const data = {
      accountId,
      placeId,
      placeNm,
      userId,
      userNm,
      userTel,
      qTitle,
      qDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/qna-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('qna add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaEdit = async ({
  qnaId,
  qnaStCd,
  accountId,
  placeId,
  placeNm,
  userId,
  userNm,
  userTel,
  qTitle,
  qDetail,
  aId,
  aDetail,
  useYn,
}) => {
  try {
    const data = {
      qnaId,
      qnaStCd,
      accountId,
      placeId,
      placeNm,
      userId,
      userNm,
      userTel,
      qTitle,
      qDetail,
      aId,
      aDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/qna-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('qna edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
export const questionList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/board/question-list', data);
    if (result.resultFlag) {
      return result.questionList;
    } else {
      throw Object.assign(new Error('question list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const questionDetail = async ({ qnaId }) => {
  try {
    const data = {
      qnaId,
    };

    const result = await apiLib.fetchOperationApi('/board/question-detail', data);
    if (result.resultFlag) {
      return result.qna;
    } else {
      throw Object.assign(new Error('question detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const questionAdd = async ({
  accountId,
  placeId,
  placeNm,
  userId,
  userNm,
  userTel,
  qTitle,
  qDetail,
  useYn,
  qnaStCd,
  botName,
  botIconImage,
  text,
  password,
}) => {
  try {
    const data = {
      accountId,
      placeId,
      placeNm,
      userId,
      userNm,
      userTel,
      qTitle,
      qDetail,
      useYn,
      qnaStCd,
      password,
    };
    const result = await apiLib.fetchOperationApi('/board/question-add', data);
    if (result.resultFlag) {
      const data = {
        botName: '문의등록',
        text: [userNm] + '님의 문의글',
        attachments: [
          {
            title: '문의글보러가기',
            text: qTitle,
            titleLink: 'https://op.eligaws.com/question/customer/list',
            color: 'red',
          },
        ],
      };
      await apiLib.fetchDooraySendApi(
        'https://hook.dooray.com/services/2176713984240089307/3058632000359169078/-mbeT-PkR4OhVC5gbPfrhg',
        data,
      );

      return true;
    } else {
      throw Object.assign(new Error('question add error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const questionEdit = async ({
  qnaId,
  qnaStCd,
  accountId,
  placeId,
  placeNm,
  userId,
  userNm,
  userTel,
  qTitle,
  qDetail,
  aId,
  aDetail,
  useYn,
}) => {
  try {
    const data = {
      qnaId,
      qnaStCd,
      accountId,
      placeId,
      placeNm,
      userId,
      userNm,
      userTel,
      qTitle,
      qDetail,
      aId,
      aDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/question-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('question edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const qnaRemove = async ({ qnaId }) => {
  try {
    const data = {
      qnaId,
    };

    const result = await apiLib.fetchOperationApi('/board/qna-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('qna remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const questionRemove = async ({ qnaId }) => {
  try {
    const data = {
      qnaId,
    };

    const result = await apiLib.fetchOperationApi('/board/question-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('question remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/board/guide-list', data);
    if (result.resultFlag) {
      return result.guideList;
    } else {
      throw Object.assign(new Error('guide list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideAdd = async ({ guideId, guideType, guideTitle, guideDetail, fileIdList, useYn }) => {
  try {
    const data = {
      guideId,
      guideType,
      guideTitle,
      guideDetail,
      fileIdList,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('guide add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideEdit = async ({ guideId, guideType, guideTitle, guideDetail, useYn }) => {
  try {
    const data = {
      guideId,
      guideType,
      guideTitle,
      guideDetail,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('guide edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideDetail = async ({ guideId }) => {
  try {
    const data = {
      guideId,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-detail', data);
    if (result.resultFlag) {
      return result.guide;
    } else {
      throw Object.assign(new Error('guide detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideRemove = async ({ guideId }) => {
  try {
    const data = {
      guideId,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('guide remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideFileAdd = async ({ guideId, fileId, fileNm, fileType, fileSize, fileThumb }) => {
  try {
    const data = {
      guideId,
      fileId,
      fileNm,
      fileType,
      fileSize,
      fileThumb,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-file-presign-upload', data);
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('file add  error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideFileList = async ({ guideId }) => {
  try {
    const data = {
      guideId,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-file-list', data);
    if (result.resultFlag) {
      return result.guideFileList;
    } else {
      throw Object.assign(new Error('guide file list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getGuideFileDownloadRequest = async ({ fileId }) => {
  try {
    const data = {
      fileId: fileId,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-file-presign-download', data);
    if (result.resultFlag) {
      const url = result.url;
      const fileInfo = result.fileInfo;

      const response = await fetch(url);

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      let element = document.createElement('a');
      element.setAttribute('href', blobUrl);
      element.setAttribute('download', fileInfo.fileNm);
      element.style.display = '';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } else {
      throw Object.assign(new Error('file error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const guideFileRemove = async ({ fileId }) => {
  try {
    const data = {
      fileId,
    };

    const result = await apiLib.fetchOperationApi('/board/guide-file-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('guide file remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const newsList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/board/news-list', data);
    if (result.resultFlag) {
      return result.newsList;
    } else {
      throw Object.assign(new Error('news list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const newsDetail = async ({ newsId }) => {
  try {
    const data = {
      newsId,
    };

    const result = await apiLib.fetchOperationApi('/board/news-detail', data);
    if (result.resultFlag) {
      return result.newsInfo;
    } else {
      throw Object.assign(new Error('news detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const newsAdd = async ({ newsTitle, newsText, useYn }) => {
  try {
    const data = {
      newsTitle,
      newsText,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/news-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('news add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const newsEdit = async ({ newsId, newsTitle, newsText, useYn }) => {
  try {
    const data = {
      newsId,
      newsTitle,
      newsText,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/news-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('news edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const newsRemove = async ({ newsId }) => {
  try {
    const data = {
      newsId,
    };

    const result = await apiLib.fetchOperationApi('/board/news-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('news remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const faqList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchOperationApi('/board/faq-list', data);
    if (result.resultFlag) {
      return result.faqList;
    } else {
      throw Object.assign(new Error('faq list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const faqDetail = async ({ faqId }) => {
  try {
    const data = {
      faqId,
    };

    const result = await apiLib.fetchOperationApi('/board/faq-detail', data);
    if (result.resultFlag) {
      return result.faqInfo;
    } else {
      throw Object.assign(new Error('faq detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const faqAdd = async ({ faqTitle, faqText, useYn }) => {
  try {
    const data = {
      faqTitle,
      faqText,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/faq-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('faq add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const faqEdit = async ({ faqId, faqTitle, faqText, useYn }) => {
  try {
    const data = {
      faqId,
      faqTitle,
      faqText,
      useYn,
    };

    const result = await apiLib.fetchOperationApi('/board/faq-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('faq edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const faqRemove = async ({ faqId }) => {
  try {
    const data = {
      faqId,
    };

    const result = await apiLib.fetchOperationApi('/board/faq-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('faq remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
