import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { storeCategoryAction } from './storeCategorySlice';

import * as categoryApiLib from '../lib/storeCategoryApi';

function* handleList({ payload }) {
  try {
    const { lastKey, categoryList } = yield call(categoryApiLib.list, payload);

    yield put(storeCategoryAction.listSuccess({ lastKey, categoryList }));
  } catch (err) {
    yield put(storeCategoryAction.listFailure('List Error'));
  }
}

function* handleAllList({ payload }) {
  try {
    const categoryAllList = yield call(categoryApiLib.allList, payload);

    yield put(storeCategoryAction.allListSuccess({ categoryAllList }));
  } catch (err) {
    yield put(storeCategoryAction.allListFailure('All List Error'));
  }
}

function* handleDetail({ payload }) {
  try {
    const categoryInfo = yield call(categoryApiLib.detail, payload);

    yield put(storeCategoryAction.detailSuccess({ categoryInfo }));
  } catch (err) {
    yield put(storeCategoryAction.detailFailure('Detail Error'));
  }
}

function* handleAdd({ payload }) {
  try {
    yield call(categoryApiLib.add, payload);

    yield put(storeCategoryAction.addSuccess());
  } catch (err) {
    yield put(storeCategoryAction.addFailure('Add Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(categoryApiLib.edit, payload);

    yield put(storeCategoryAction.editSuccess());
  } catch (err) {
    yield put(storeCategoryAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(categoryApiLib.remove, payload);

    yield put(storeCategoryAction.removeSuccess());
  } catch (err) {
    yield put(storeCategoryAction.removeFailure('Remove Error'));
  }
}

export function* watchList() {
  yield takeLatest(storeCategoryAction.list, handleList);
}

export function* watchAllList() {
  yield takeLatest(storeCategoryAction.allList, handleAllList);
}

export function* watchDetail() {
  yield takeLatest(storeCategoryAction.detail, handleDetail);
}

export function* watchAdd() {
  yield takeLatest(storeCategoryAction.add, handleAdd);
}

export function* watchEdit() {
  yield takeLatest(storeCategoryAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(storeCategoryAction.remove, handleRemove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchAllList),
    fork(watchDetail),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
