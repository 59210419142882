import * as apiLib from './api';

export const list = async ({ brandId, startKey, limit }) => {
  try {
    const data = {
      brandId,
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, shopList: result.shopList };
    } else {
      throw Object.assign(new Error('shop list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ shopId }) => {
  try {
    const data = {
      shopId: shopId,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/detail', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ spaceId, placeId, brandId, shopType, shopNm, placeCount, placeLimit, companyCodeList }) => {
  try {
    const data = {
      spaceId,
      placeId,
      brandId,
      shopType,
      shopNm,
      placeCount,
      placeLimit,
      companyCodeList,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('shop add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  shopId,
  spaceId,
  placeId,
  brandId,
  shopType,
  shopNm,
  useYn,
  delYn,
  useMobileYn,
  shopColor,
  empPay,
  nomalPay,
  pointPay,
  companyCodeList,
}) => {
  try {
    const data = {
      shopId,
      spaceId,
      placeId,
      brandId,
      shopType,
      shopNm,
      useYn,
      delYn,
      useMobileYn,
      shopColor,
      empPay,
      nomalPay,
      pointPay,
      companyCodeList,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('shop edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ shopId }) => {
  try {
    const data = { shopId };

    const result = await apiLib.fetchOperationApi('/store-shop/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('shop remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberList = async ({ shopId }) => {
  try {
    const data = {
      shopId: shopId,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/member-list', data);
    if (result.resultFlag) {
      return { memberList: result.memberList, accountList: result.accountList };
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberDetail = async ({ shopId, accountId }) => {
  try {
    const data = {
      shopId: shopId,
      accountId: accountId,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/member-detail', data);
    if (result.resultFlag) {
      return result.memberInfo;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberAdd = async ({ shopId, accountEmail, permissionType }) => {
  try {
    const data = {
      shopId: shopId,
      accountEmail: accountEmail,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/member-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberEdit = async ({ shopId, accountId, permissionType }) => {
  try {
    const data = {
      shopId: shopId,
      accountId: accountId,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/member-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberRemove = async ({ shopId, accountId }) => {
  try {
    const data = {
      shopId: shopId,
      accountId: accountId,
    };

    const result = await apiLib.fetchOperationApi('/store-shop/member-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
