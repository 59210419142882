import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { placeAuthAction } from './placeAuthSlice';

import * as placeAuthApiLib from '../lib/placeAuthApi';

function* list({ payload }) {
  try {
    const placeAuthList = yield call(placeAuthApiLib.list, payload);

    yield put(placeAuthAction.listSuccess({ placeAuthList }));
  } catch (error) {
    yield put(placeAuthAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    yield call(placeAuthApiLib.add, payload);

    yield put(placeAuthAction.addSuccess());
  } catch (error) {
    yield put(placeAuthAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const placeAuthInfo = yield call(placeAuthApiLib.detail, payload);

    yield put(placeAuthAction.detailSuccess({ placeAuthInfo }));
  } catch (error) {
    yield put(placeAuthAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    yield call(placeAuthApiLib.edit, payload);

    yield put(placeAuthAction.editSuccess());
  } catch (error) {
    yield put(placeAuthAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(placeAuthApiLib.remove, payload);

    yield put(placeAuthAction.removeSuccess());
  } catch (error) {
    yield put(placeAuthAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(placeAuthAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(placeAuthAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(placeAuthAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(placeAuthAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(placeAuthAction.remove, remove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchAdd),
    fork(watchDetail),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
