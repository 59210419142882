import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import * as deviceThunkAll from './deviceThunk';

interface DevicePayload {
  placeId: string;
  groupId: string;
  deviceId: string;
  pairingId: string;
  playlistId: string;
  deviceList: DevicePayload[];
}

interface ScrshotPayload {
  deviceId: string;
  scrshotDt: string;
  page: number;
}

interface FilterInfo {
  groupId: string;
  deviceNm: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface DeviceInfo {
  placeId: string;
  deviceId: string;
  deviceNm: string;
}

interface PairingInfo {
  placeId: string;
  licenseId: string;
  deviceId: string;
}

interface ScrshotInfo {
  pageIndex: number;
  page: number;
  pages: number;
  listCount: number;
  totalCount: number;
  deviceId: string;
  regDt: string;
  scrshotFile: string;
}

interface CommandSocketInfo {
  connectionId: string;
}

interface DeviceState {
  filterInfo: FilterInfo;
  pageInfo: PageInfo;
  deviceInfo: DeviceInfo;
  deviceList: Array<DeviceInfo>;
  scrshotList: Array<ScrshotInfo>;
  commandSocketList: Array<CommandSocketInfo>;
  pairingInfo: PairingInfo;
  remoteUrl: string;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const deviceInitialState: DeviceState = {
  filterInfo: {},
  pageInfo: {},
  deviceInfo: {},
  deviceList: [],
  commandSocketList: [],
  scrshotList: [],
  pairingInfo: {},
  remoteUrl: '',
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  deviceFilter(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.filterInfo = {
      groupId: payload.groupId,
      deviceNm: payload.deviceNm,
    };
    state.isLoading = false;
    state.error = payload;
  },
  deviceList: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceListSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        (pageKey) => pageKey.startKey && pageKey.startKey.deviceId === state.pageInfo.curStartKey.deviceId
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.deviceList = payload.deviceList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceListFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceListClear: (state: DeviceState) => {
    state.pageInfo = {};
    state.deviceList = [];
  },
  deviceDetail: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceDetailSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.deviceInfo = payload.deviceInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceDetailFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceDetailClear: (state: DeviceState) => {
    state.deviceInfo = {};
  },
  deviceAdd: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceAddSuccess: (state: DeviceState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceAddFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceEdit: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceEditSuccess: (state: DeviceState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceEditFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceRemove: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceRemoveSuccess: (state: DeviceState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceRemoveFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  deviceCommand(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'COMMAND_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceCommandSuccess(state: DeviceState) {
    state.actionResult = 'COMMAND_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceCommandFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'COMMAND_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  deviceCommandSocketList(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'COMMAND_SOCKET_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceCommandSocketListSuccess(state: DeviceState, { payload }: PayloadAction<DeviceState>) {
    state.commandSocketList = payload.socketList;
    state.actionResult = 'COMMAND_SOCKET_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceCommandSocketListFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'COMMAND_SOCKET_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  deviceCommandSocketSend(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'COMMAND_SOCKET_SEND_REQ';
    state.isLoading = true;
    state.error = null;
  },
  deviceCommandSocketSendSuccess(state: DeviceState) {
    state.actionResult = 'COMMAND_SOCKET_SEND_OK';
    state.isLoading = false;
    state.error = null;
  },
  deviceCommandSocketSendFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'COMMAND_SOCKET_SEND_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  devicePowerUpdate(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'POWER_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  devicePowerUpdateSuccess(state: DeviceState) {
    state.actionResult = 'POWER_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  devicePowerUpdateFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'POWER_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  pairing: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.isLoading = true;
    state.error = null;
  },
  pairingSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.pairingInfo = payload.pairingInfo;
    state.isLoading = false;
    state.error = null;
  },
  pairingFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  pairingCancel: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.isLoading = true;
    state.error = null;
  },
  pairingUnset: (state: DeviceState, { payload }: PayloadAction<DevicePayload>) => {
    state.isLoading = true;
    state.error = null;
  },
  stop(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.isLoading = true;
    state.error = null;
  },
  stopSuccess(state: DeviceState, { payload }: PayloadAction<string>) {
    state.isLoading = false;
    state.error = null;
  },
  stopFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.isLoading = false;
    state.error = payload;
  },
  play(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.isLoading = true;
    state.error = null;
  },
  playSuccess(state: DeviceState, { payload }: PayloadAction<string>) {
    state.isLoading = false;
    state.error = null;
  },
  playFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.isLoading = false;
    state.error = payload;
  },
  playUnset(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.isLoading = true;
    state.error = null;
  },
  scrshotList: (state: DeviceState, { payload }: PayloadAction<ScrshotPayload>) => {
    state.actionResult = 'SCRSHOT_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  scrshotListSuccess: (state: DeviceState, { payload }: PayloadAction<DeviceState>) => {
    state.scrshotList = payload.scrshotList;
    state.actionResult = 'SCRSHOT_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  scrshotListFailure: (state: DeviceState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'SCRSHOT_LIST_ERR';
    state.error = action.payload;
  },
  scrshotListClear: (state: DeviceState) => {
    state.scrshotList = [];
  },
  groupMove(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'GROUP_MOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupMoveSuccess(state: DeviceState) {
    state.actionResult = 'GROUP_MOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupMoveFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'GROUP_MOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  remoteUrl(state: DeviceState, { payload }: PayloadAction<DevicePayload>) {
    state.actionResult = 'REMOTE_URL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  remoteUrlSuccess(state: DeviceState, { payload }: PayloadAction<DeviceState>) {
    state.deviceInfo.remoteUrl = payload.remoteUrl;
    state.actionResult = 'REMOTE_URL_OK';
    state.isLoading = false;
    state.error = null;
  },
  remoteUrlFailure(state: DeviceState, { payload }: PayloadAction<string>) {
    state.actionResult = 'REMOTE_URL_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  actionResultClear: (state: DeviceState) => {
    state.actionResult = '';
  },
};

const extraReducers = (builder) => {
  builder.addCase(deviceThunkAll.remoteUrl.pending, (state, { payload }) => {
    state.actionResult = 'REMOTE_URL_REQ';
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(deviceThunkAll.remoteUrl.fulfilled, (state, { payload }) => {
    state.deviceInfo.remoteUrl = payload.remoteUrl;
    state.actionResult = 'REMOTE_URL_OK';
    state.isLoading = false;
    state.error = null;
  });
  builder.addCase(deviceThunkAll.remoteUrl.rejected, (state, { payload }) => {
    state.actionResult = 'REMOTE_URL_ERR';
    state.isLoading = false;
    state.error = payload.error;
  });
};

const slice = createSlice({
  name: 'device',
  initialState: deviceInitialState,
  reducers: reducers,
  extraReducers: extraReducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: DeviceState) => state.pageInfo,
  (pageInfo) => pageInfo
);

// const selectDeviceList = createDraftSafeSelector(
//   (state: DeviceState) => state.deviceList,
//   deviceList => deviceList,
// );

const selectDeviceList = createDraftSafeSelector(
  (state: DeviceState) => state.filterInfo,
  (state: DeviceState) => state.deviceList,
  (filterInfo, deviceList) => {
    if (
      (!filterInfo.groupId || filterInfo.groupId.trim() === '') &&
      (!filterInfo.deviceNm || filterInfo.deviceNm.trim() === '')
    ) {
      return deviceList;
    }

    return deviceList.filter((device) => {
      let groupFilter = true;
      let deviceFilter = true;

      if (filterInfo.groupId && filterInfo.groupId !== '') {
        groupFilter = device.groupId === filterInfo.groupId;
      }

      if (filterInfo.deviceNm && filterInfo.deviceNm !== '') {
        deviceFilter = device.deviceNm.indexOf(filterInfo.deviceNm) >= 0;
      }

      return groupFilter && deviceFilter;
    });
  }
);

const selectDeviceInfo = createDraftSafeSelector(
  (state: DeviceState) => state.deviceInfo,
  (deviceInfo) => deviceInfo
);

const selectPairingDeviceList = createDraftSafeSelector(
  (state: DeviceState) => state.deviceList,
  (deviceList) => deviceList.filter((device) => device.pairingYn === 'Y')
);

const selectPairingInfo = createDraftSafeSelector(
  (state: DeviceState) => state.pairingInfo,
  (pairingInfo) => pairingInfo
);

const selectScrshotList = createDraftSafeSelector(
  (state: DeviceState) => state.scrshotList,
  (scrshotList) => scrshotList
);

const selectCommandSocketList = createDraftSafeSelector(
  (state: DeviceState) => state.commandSocketList,
  (commandSocketList) => commandSocketList
);

const selectStatus = createDraftSafeSelector(
  (state: DeviceState) => state.actionResult,
  (state: DeviceState) => state.isLoading,
  (state: DeviceState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error })
);

export const deviceSelector = {
  pageInfo: (state) => selectPageInfo(state[DEVICE]),
  deviceList: (state) => selectDeviceList(state[DEVICE]),
  deviceInfo: (state) => selectDeviceInfo(state[DEVICE]),
  pairingDeviceList: (state) => selectPairingDeviceList(state[DEVICE]),
  scrshotList: (state) => selectScrshotList(state[DEVICE]),
  commandSocketList: (state) => selectCommandSocketList(state[DEVICE]),
  pairingInfo: (state) => selectPairingInfo(state[DEVICE]),
  status: (state) => selectStatus(state[DEVICE]),
};

export const DEVICE = slice.name;
export const deviceReducer = slice.reducer;
export const deviceAction = slice.actions;
export const deviceThunk = deviceThunkAll;
