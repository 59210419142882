import * as apiLib from './api';

export const list = async ({ spaceId, startKey, limit }) => {
  try {
    const data = {
      spaceId,
      startKey,
      limit,
    };

    const result = await apiLib.fetchOperationApi('/store-brand/list', data);
    if (result.resultFlag) {
      return { lastKey: result.lastKey, brandList: result.brandList };
    } else {
      throw Object.assign(new Error('brand list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ brandId }) => {
  try {
    const data = {
      brandId: brandId,
    };

    const result = await apiLib.fetchOperationApi('/store-brand/detail', data);
    if (result.resultFlag) {
      return result.brandInfo;
    } else {
      throw Object.assign(new Error('brand detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  spaceId,
  brandType,
  brandNm,
  placeCount,
  placeLimit,
}) => {
  try {
    const data = {
      spaceId,
      brandType,
      brandNm,
      placeCount,
      placeLimit,
    };

    const result = await apiLib.fetchOperationApi('/store-brand/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('brand add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  brandId,
  spaceId,
  brandType,
  brandNm,
  placeCount,
  placeLimit,
  useYn,
  delYn,
}) => {
  try {
    const data = {
      brandId,
      spaceId,
      brandType,
      brandNm,
      placeCount,
      placeLimit,
      useYn,
      delYn,
    };

    const result = await apiLib.fetchOperationApi('/store-brand/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('brand edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ brandId }) => {
  try {
    const data = { brandId };

    const result = await apiLib.fetchOperationApi('/store-brand/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('brand remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
