import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface MemberPayload {
  placeId: string;
  accountEmail: string;
}

interface MemberInfo {
  placeId: string;
  accountId: string;
  regDt: string;
}

interface AccountInfo {
  placeId: string;
  accountId: string;
  accountNm: string;
  regDt: string;
}

interface MemberState {
  memberList: Array<MemberInfo>;
  memberInfo: MemberInfo;
  accountList: Array<AccountInfo>;
  accountInfo: AccountInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const memberInitialState: MemberState = {
  memberList: [],
  memberInfo: {},
  accountList: [],
  accountInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  list: (state: MemberState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (
    state: MemberState,
    { payload }: PayloadAction<MemberState>,
  ) => {
    state.memberList = payload.memberList;
    state.accountList = payload.accountList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: MemberState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: MemberState, action: PayloadAction<string>) => {
    state.memberList = [];
    state.accountList = [];
  },
  detail: (state: MemberState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: MemberState,
    { payload }: PayloadAction<MemberState>,
  ) => {
    state.memberInfo = payload.memberInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: MemberState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: MemberState, action: PayloadAction<string>) => {
    state.memberInfo = {};
    state.accountInfo = {};
  },
  add: (state: MemberState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: MemberState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: MemberState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: MemberState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: MemberState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: MemberState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: MemberState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: MemberState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: MemberState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'member',
  initialState: memberInitialState,
  reducers: reducers,
});

const selectMemberList = createDraftSafeSelector(
  (state: PlaceState) => state.memberList,
  (state: PlaceState) => state.accountList,
  (memberList, accountList) => {
    let newMemberList = [];
    for (let member of memberList) {
      let accountFilter = accountList.filter(
        account => account.accountId === member.accountId,
      );
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        newMemberList.push({ ...member, ...account });
      }
    }
    return newMemberList;
  },
);

const selectMemberInfo = createDraftSafeSelector(
  (state: PlaceState) => state.memberInfo,
  (state: PlaceState) => state.accountList,
  (memberInfo, accountList) => {
    if (accountList && accountList.length > 0) {
      let accountFilter = accountList.filter(
        account => account.accountId === memberInfo.accountId,
      );
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        return { ...memberInfo, ...account };
      } else {
        return memberInfo;
      }
    } else {
      return memberInfo;
    }
  },
);

const selectStatus = createDraftSafeSelector(
  (state: MemberState) => state.actionResult,
  (state: MemberState) => state.isLoading,
  (state: MemberState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const memberSelector = {
  memberList: state => selectMemberList(state[MEMBER]),
  memberInfo: state => selectMemberInfo(state[MEMBER]),
  status: state => selectStatus(state[MEMBER]),
};

export const MEMBER = slice.name;
export const memberReducer = slice.reducer;
export const memberAction = slice.actions;
