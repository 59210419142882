import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface PackagePayload {
    appId: string;
    appNm: string;
}

interface PackageInfo {
    packageId: string;
    devYn?: string;
    horizonResolution?: string;
    modDt?: string;
    modId?: string;
    packageNm?: string;
    packageType?: string;
    playlistType?: string;
    pricing?: string;
    regDt?: string;
    regId?: string;
    useYn?: string;
    verticalResolution?: string;
    spaceIdList?: any[];
}

interface PackageState {
    packageList: Array<PackageInfo>;
    packageInfo: PackageInfo;
    actionResult: string;
    isLoading: boolean;
    error: string;
}

const AppInitialState: PackageState = {
    packageList: [],
    packageInfo: {},
    actionResult: '',
    isLoading: false,
    error: '',
};

const reducers = {
    initPackageState: (state: PackageState) => {
        state.packageList = [];
        state.packageInfo = {};
        state.actionResult = '';
        state.isLoading = false;
        state.error = '';
    },
    actionResultClear: (state: AuthState) => {
        state.actionResult = '';
    },
    list: (state: PackageState, { payload }: PayloadAction<PackagePayload>) => {
        state.actionResult = 'LIST_REQ';
        state.isLoading = true;
        state.error = '';
    },
    listSuccess: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
        state.packageList = payload.packageList;
        state.actionResult = 'LIST_OK';
        state.isLoading = false;
        state.error = '';
    },
    listFailure: (state: PackageState, action: PayloadAction<string>) => {
        state.actionResult = 'LIST_ERR';
        state.isLoading = false;
        state.error = action.payload;
    },
    detail: (state: PackageState, { payload }: PayloadAction<PackagePayload>) => {
        state.actionResult = 'DETAIL_REQ';
        state.isLoading = true;
        state.error = '';
    },
    detailSuccess: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
        state.packageInfo = payload.packageInfo;
        state.actionResult = 'DETAIL_OK';
        state.isLoading = false;
        state.error = '';
    },
    detailFailure: (state: PackageState, action: PayloadAction<string>) => {
        state.actionResult = 'DETAIL_ERR';
        state.isLoading = false;
        state.error = action.payload;
    },
    detailClear: (state: PackageState, action: PayloadAction<string>) => {
        state.packageInfo = {};
    },
    updateSpaceIdList: (state: PackageState, { payload }: PayloadAction<PackagePayload>) => {
        state.actionResult = 'SPACE_LIST_REQ';
        state.isLoading = true;
        state.error = '';
    },
    updateSpaceIdListSuccess: (state: PackageState) => {
        state.actionResult = 'SPACE_LIST_OK';
        state.isLoading = false;
        state.error = '';
    },
    updateSpaceIdListFailure: (state: PackageState, action: PayloadAction<string>) => {
        state.actionResult = 'SPACE_LIST_ERR';
        state.isLoading = false;
        state.error = action.payload;
    },
    add: (state: PackageState, { payload }: PayloadAction<PackagePayload>) => {
        state.actionResult = 'ADD_REQ';
        state.isLoading = true;
        state.error = '';
    },
    addSuccess: (state: PackageState) => {
        state.actionResult = 'ADD_OK';
        state.isLoading = false;
        state.error = '';
    },
    addFailure: (state: PackageState, action: PayloadAction<string>) => {
        state.actionResult = 'ADD_ERR';
        state.isLoading = false;
        state.error = action.payload;
    },
    edit: (state: PackageState, { payload }: PayloadAction<PackagePayload>) => {
        state.actionResult = 'EDIT_REQ';
        state.isLoading = true;
        state.error = '';
    },
    editSuccess: (state: PackageState, { payload: { packageInfo } }: PayloadAction<PackagePayload>) => {
        state.packageInfo = packageInfo;
        state.actionResult = 'EDIT_OK';
        state.isLoading = false;
        state.error = '';
    },
    editFailure: (state: PackageState, action: PayloadAction<string>) => {
        state.actionResult = 'EDIT_ERR';
        state.isLoading = false;
        state.error = action.payload;
    },
    remove: (state: PackageState) => {
        state.actionResult = 'REMOVE_REQ';
        state.isLoading = true;
        state.error = null;
    },
    removeSuccess: (state: PackageState, { payload: { packageList } }: PayloadAction<PackagePayload>) => {
        state.packageList = packageList;
        state.actionResult = 'REMOVE_OK';
        state.isLoading = false;
        state.error = null;
    },
    removeFailure: (state: PackageState, action: PayloadAction<string>) => {
        state.actionResult = 'REMOVE_ERR';
        state.isLoading = false;
        state.error = action.payload;
    },
};

const slice = createSlice({
    name: 'package',
    initialState: AppInitialState,
    reducers: reducers,
});

const selectpackageList = createDraftSafeSelector(
    (state: PackageState) => state.packageList,
    packageList => packageList
);

const selectpackageInfo = createDraftSafeSelector(
    (state: PackageState) => state.packageInfo,
    packageInfo => packageInfo
);

const selectStatus = createDraftSafeSelector(
    (state: PackageState) => state.actionResult,
    (state: PackageState) => state.isLoading,
    (state: PackageState) => state.error,
    (actionResult, isLoading, error) => ({ actionResult, isLoading, error })
);

export const packageSelector = {
    packageList: state => selectpackageList(state[PACKAGE]),
    packageInfo: state => selectpackageInfo(state[PACKAGE]),
    status: state => selectStatus(state[PACKAGE]),
};

export const PACKAGE = slice.name;
export const packageReducer = slice.reducer;
export const packageAction = slice.actions;
