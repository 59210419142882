import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ShopPayload {
  shopId: string;
  spaceId: string;
  shopNm: string;
  filterValue: string;
}

interface PageKeyInfo {
  startKey: any;
  lastKey: any;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
  curStartKey: PageKeyInfo;
  curLastKey: PageKeyInfo;
  pageKeyList: Array<PageKeyInfo>;
}

interface ShopInfo {
  shopId: string;
  spaceId: string;
  shopNm: string;
  regDt: string;
}

interface MemberInfo {
  placeId: string;
  accountId: string;
  regDt: string;
}

interface AccountInfo {
  placeId: string;
  accountId: string;
  accountNm: string;
  regDt: string;
}

interface ShopState {
  pageInfo: PageInfo;
  shopList: Array<ShopInfo>;
  shopInfo: ShopInfo;
  memberList: Array<MemberInfo>;
  memberInfo: MemberInfo;
  accountList: Array<AccountInfo>;
  accountInfo: AccountInfo;
  filterValue: string;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const shopInitialState: ShopState = {
  pageInfo: {},
  shopList: [],
  shopInfo: {},
  memberList: [],
  memberInfo: {},
  accountList: [],
  accountInfo: {},
  filterValue: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  filter: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.filterValue = payload.filterValue;
  },
  list: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    if (state.pageInfo.rowsPerPage !== payload.limit) {
      state.pageInfo = {
        rowsPerPage: payload.limit,
        curPage: 1,
        curStartKey: payload.startKey,
        curLastKey: null,
        pageKeyList: [],
      };
    }
    state.pageInfo.curStartKey = payload.startKey;
    state.pageInfo.rowsPerPage = payload.limit;
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    if (state.pageInfo.pageKeyList.length === 0) {
      state.pageInfo.pageKeyList.push({
        startKey: state.pageInfo.curStartKey,
        lastKey: payload.lastKey,
      });
      state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
      state.pageInfo.curLastKey = payload.lastKey;
    } else if (state.pageInfo.curStartKey) {
      const findPageIndex = state.pageInfo.pageKeyList.findIndex(
        pageKey =>
          pageKey.startKey &&
          pageKey.startKey.shopId === state.pageInfo.curStartKey.shopId,
      );
      if (findPageIndex >= 0) {
        state.pageInfo.curPage = findPageIndex + 1;
        state.pageInfo.curLastKey = payload.lastKey;
      } else {
        state.pageInfo.pageKeyList.push({
          startKey: state.pageInfo.curStartKey,
          lastKey: payload.lastKey,
        });
        state.pageInfo.curPage = state.pageInfo.pageKeyList.length;
        state.pageInfo.curLastKey = payload.lastKey;
      }
    } else {
      state.pageInfo.curPage = 1;
      state.pageInfo.curLastKey = payload.lastKey;
    }

    state.shopList = payload.shopList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  listClear: (state: DeviceState) => {
    state.pageInfo = {};
    state.shopList = [];
  },
  detail: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: ShopState,
    { payload }: PayloadAction<ShopState>,
  ) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: ShopState, action: PayloadAction<string>) => {
    state.shopInfo = {};
  },
  add: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: ShopState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: ShopState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: ShopState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberList: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'MEMBER_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberListSuccess: (
    state: ShopState,
    { payload }: PayloadAction<ShopState>,
  ) => {
    state.memberList = payload.memberList;
    state.accountList = payload.accountList;
    state.actionResult = 'MEMBER_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberListFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.memberList = [];
    state.accountList = [];
    state.actionResult = 'MEMBER_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberDetail: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'MEMBER_DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberDetailSuccess: (
    state: ShopState,
    { payload }: PayloadAction<ShopState>,
  ) => {
    state.memberInfo = payload.memberInfo;
    state.actionResult = 'MEMBER_DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberDetailFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberAdd: (state: ShopState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'MEMBER_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberAddSuccess: (state: ShopState) => {
    state.actionResult = 'MEMBER_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberAddFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberEdit: (state: ShopState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'MEMBER_EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberEditSuccess: (state: ShopState) => {
    state.actionResult = 'MEMBER_EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberEditFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberRemove: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'MEMBER_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberRemoveSuccess: (state: ShopState) => {
    state.actionResult = 'MEMBER_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberRemoveFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'shop',
  initialState: shopInitialState,
  reducers: reducers,
});

const selectPageInfo = createDraftSafeSelector(
  (state: ShopState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectShopList = createDraftSafeSelector(
  (state: ShopState) => state.shopList,
  (state: ShopState) => state.filterValue,
  (shopList, filterValue) => {
    if (filterValue.trim() === '') {
      return shopList.slice().sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
    }

    return shopList
      .filter(shop => {
        let nameFilter = false;

        if (shop.shopNm.indexOf(filterValue) >= 0) {
          nameFilter = true;
        }

        return nameFilter;
      })
      .slice()
      .sort((l, r) => {
        return l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1;
      });
  },
);

const selectShopInfo = createDraftSafeSelector(
  (state: ShopState) => state.shopInfo,
  shopInfo => shopInfo,
);

const selectMemberInfo = createDraftSafeSelector(
  (state: ShopState) => state.memberInfo,
  (state: ShopState) => state.accountList,
  (memberInfo, accountList) => {
    if (accountList && accountList.length > 0) {
      let accountFilter = accountList.filter(
        account => account.accountId === memberInfo.accountId,
      );
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        return { ...memberInfo, ...account };
      } else {
        return memberInfo;
      }
    } else {
      return memberInfo;
    }
  },
);

const selectMemberList = createDraftSafeSelector(
  (state: ShopState) => state.memberList,
  (state: ShopState) => state.accountList,
  (memberList, accountList) => {
    let newMemberList = [];
    for (let member of memberList) {
      let accountFilter = accountList.filter(
        account => account.accountId === member.accountId,
      );
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        newMemberList.push({ ...member, ...account });
      }
    }
    return newMemberList;
  },
);

const selectStatus = createDraftSafeSelector(
  (state: ShopState) => state.actionResult,
  (state: ShopState) => state.isLoading,
  (state: ShopState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const storeShopSelector = {
  pageInfo: state => selectPageInfo(state[STORE_SHOP]),
  shopList: state => selectShopList(state[STORE_SHOP]),
  shopInfo: state => selectShopInfo(state[STORE_SHOP]),
  memberInfo: state => selectMemberInfo(state[STORE_SHOP]),
  memberList: state => selectMemberList(state[STORE_SHOP]),
  status: state => selectStatus(state[STORE_SHOP]),
};

export const STORE_SHOP = slice.name;
export const storeShopReducer = slice.reducer;
export const storeShopAction = slice.actions;
