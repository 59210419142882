import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { AUTH, authReducer } from './authSlice';
import { ADMIN, adminReducer } from './adminSlice';
import { ACCOUNT, accountReducer } from './accountSlice';
import { SPACE, spaceReducer } from './spaceSlice';
import { SPACEMEMBER, spaceMemberReducer } from './spaceMemberSlice';
import { PLACE, placeReducer } from './placeSlice';
import { PLACEAUTH, placeAuthReducer } from './placeAuthSlice';
import { MEMBER, memberReducer } from './memberSlice';
import { DEVICE, deviceReducer } from './deviceSlice';
import { SCRSHOT, scrshotReducer } from './scrshotSlice';
import { BOARD, boardReducer } from './boardSlice';
import { CONTENTS as PUBLICCONTENTS, contentsReducer as publicContentsReducer } from './publicContentsSlice';
import { APP, appReducer } from './appSlice';
import { PACKAGE, packageReducer } from './packageSlice';
import { TEMPLATE, templateReducer } from './templateSlice';
import { TEMPLATEGROUP, templateGroupReducer } from './templateGroupSlice';
import { LAYER, layerReducer } from './layerSlice';
import { LAYERHISTORY, layerHistoryReducer } from './layerHistorySlice';
import {
  LAYERCONTENTS as TEMPLATELAYERCONTENTS,
  layerContentsReducer as templateLayerContentsReducer,
} from './templateLayerContentsSlice';
import { ACTIVEITEM, activeItemReducer } from './activeItemSlice';
import { MENU, menuReducer } from './menuSlice';
import { CLIPBOARD, clipboardReducer } from './clipboardSlice';
import { STORE_CUSTOMER, storeCustomerReducer } from './storeCustomerSlice';
import { STORE_BRAND, storeBrandReducer } from './storeBrandSlice';
import { STORE_CATEGORY, storeCategoryReducer } from './storeCategorySlice';
import { STORE_GOODS, storeGoodsReducer } from './storeGoodsSlice';
import { STORE_SHOP, storeShopReducer } from './storeShopSlice';

// import authSaga from './authSaga';
import adminSaga from './adminSaga';
import accountSaga from './accountSaga';
import spaceSaga from './spaceSaga';
import placeSaga from './placeSaga';
import publicContentsSaga from './publicContentsSaga';
import memberSaga from './memberSaga';
import spaceMemberSaga from './spaceMemberSaga';
import deviceSaga from './deviceSaga';
import scrshotSaga from './scrshotSaga';
import boardSaga from './boardSaga';
import appSaga from './appSaga';
import templateSaga from './templateSaga';
import templateGroupSaga from './templateGroupSaga';
import layerSaga from './layerSaga';
import layerHistorySaga from './layerHistorySaga';
import templateLayerContentsSaga from './templateLayerContentsSaga';
import storeCustomerSaga from './storeCustomerSaga';
import storeBrandSaga from './storeBrandSaga';
import storeCategorySaga from './storeCategorySaga';
import storeGoodsSaga from './storeGoodsSaga';
import storeShopSaga from './storeShopSaga';
import menuSaga from './menuSaga';
import placeAuthSaga from './placeAuthSaga';
import packageSaga from './packageSaga';

import { managerBaseApi } from 'rtk/managerBaseApi';

const createRootReducer = () =>
  combineReducers({
    [AUTH]: authReducer,
    [ADMIN]: adminReducer,
    [ACCOUNT]: accountReducer,
    [SPACE]: spaceReducer,
    [SPACEMEMBER]: spaceMemberReducer,
    [PLACE]: placeReducer,
    [PLACEAUTH]: placeAuthReducer,
    [MEMBER]: memberReducer,
    [DEVICE]: deviceReducer,
    [SCRSHOT]: scrshotReducer,
    [PUBLICCONTENTS]: publicContentsReducer,
    [BOARD]: boardReducer,
    [APP]: appReducer,
    [TEMPLATE]: templateReducer,
    [TEMPLATEGROUP]: templateGroupReducer,
    [LAYER]: layerReducer,
    [LAYERHISTORY]: layerHistoryReducer,
    [TEMPLATELAYERCONTENTS]: templateLayerContentsReducer,
    [ACTIVEITEM]: activeItemReducer,
    [CLIPBOARD]: clipboardReducer,
    [STORE_CUSTOMER]: storeCustomerReducer,
    [STORE_BRAND]: storeBrandReducer,
    [STORE_CATEGORY]: storeCategoryReducer,
    [STORE_GOODS]: storeGoodsReducer,
    [STORE_SHOP]: storeShopReducer,
    [MENU]: menuReducer,
    [PACKAGE]: packageReducer,
    [managerBaseApi.reducerPath]: managerBaseApi.reducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    // authSaga(),
    adminSaga(),
    accountSaga(),
    spaceSaga(),
    placeSaga(),
    placeAuthSaga(),
    publicContentsSaga(),
    memberSaga(),
    spaceMemberSaga(),
    deviceSaga(),
    scrshotSaga(),
    boardSaga(),
    appSaga(),
    templateSaga(),
    templateGroupSaga(),
    layerSaga(),
    layerHistorySaga(),
    templateLayerContentsSaga(),
    storeCustomerSaga(),
    storeBrandSaga(),
    storeCategorySaga(),
    storeGoodsSaga(),
    storeShopSaga(),
    menuSaga(),
    packageSaga(),
  ]);
}

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: true,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      managerBaseApi.middleware,
      sagaMiddleware,
    ],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
