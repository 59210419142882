import * as apiLib from './api';

export const list = async ({ placeId }) => {
  try {
    const data = {
      placeId,
    };

    const result = await apiLib.fetchOperationApi('/member/list', data);
    if (result.resultFlag) {
      return { memberList: result.memberList, accountList: result.accountList };
    } else {
      throw Object.assign(new Error('member list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ placeId, accountId }) => {
  try {
    const data = {
      placeId,
      accountId,
    };

    const result = await apiLib.fetchOperationApi('/member/detail', data);
    if (result.resultFlag) {
      return result.memberInfo;
    } else {
      throw Object.assign(new Error('member detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ placeId, accountEmail, permissionType }) => {
  try {
    const data = {
      placeId,
      accountEmail,
      permissionType,
    };

    const result = await apiLib.fetchOperationApi('/member/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ placeId, accountId, permissionType }) => {
  try {
    const data = {
      placeId,
      accountId,
      permissionType,
    };

    const result = await apiLib.fetchOperationApi('/member/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ placeId, accountId }) => {
  try {
    const data = { placeId, accountId };

    const result = await apiLib.fetchOperationApi('/member/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
