import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface AdminPayload {
  adminId: string;
  adminNm: string;
}

interface ImagePayload {
  adminId: string;
  file: Array<any>;
}

interface AdminInfo {
  adminId: string;
  adminNm: string;
  adminEmail: string;
  imagePath: string;
  imageSize: string;
  regDt: string;
}

interface AdminState {
  adminList: Array<AdminInfo>;
  adminInfo: AdminInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const adminInitialState: AdminState = {
  adminList: [],
  adminInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  list: (state: AdminState, { payload }: PayloadAction<AdminPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = '';
  },
  listSuccess: (state: AdminState, { payload }: PayloadAction<AdminState>) => {
    state.adminList = payload.adminList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = '';
  },
  listFailure: (state: AdminState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detail: (state: AdminState, { payload }: PayloadAction<AdminPayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = '';
  },
  detailSuccess: (
    state: AdminState,
    { payload }: PayloadAction<AdminState>,
  ) => {
    state.adminInfo = payload.adminInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = '';
  },
  detailFailure: (state: AdminState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detailClear: (state: AdminState, action: PayloadAction<string>) => {
    state.adminInfo = {};
  },
  add: (state: AdminState, { payload }: PayloadAction<AdminPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = '';
  },
  addSuccess: (state: AdminState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = '';
  },
  addFailure: (state: AdminState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: AdminState, { payload }: PayloadAction<AdminPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = '';
  },
  editSuccess: (state: AdminState) => {
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = '';
  },
  editFailure: (state: AdminState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: AdminState, { payload }: PayloadAction<AdminPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: AdminState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: AdminState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  passwordChange: (
    state: AdminState,
    { payload }: PayloadAction<AdminPayload>,
  ) => {
    state.actionResult = 'PASSWORD_CHANGE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  passwordChangeSuccess: (state: AdminState) => {
    state.actionResult = 'PASSWORD_CHANGE_OK';
    state.isLoading = false;
    state.error = null;
  },
  passwordChangeFailure: (state: AdminState, action: PayloadAction<string>) => {
    state.actionResult = 'PASSWORD_CHANGE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  imageUpload: (
    state: AdminState,
    { payload }: PayloadAction<ImagePayload>,
  ) => {
    state.actionResult = 'UPLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  imageUploadSuccess: (
    state: AdminState,
    { payload }: PayloadAction<AdminState>,
  ) => {
    state.adminInfo = payload.adminInfo;
    state.actionResult = 'UPLOAD_OK';
    state.isLoading = false;
    state.error = null;
  },
  imageUploadFailure: (
    state: AdminState,
    { payload }: PayloadAction<String>,
  ) => {
    state.actionResult = 'UPLOAD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'admin',
  initialState: adminInitialState,
  reducers: reducers,
});

const selectAdminList = createDraftSafeSelector(
  (state: AdminState) => state.adminList,
  adminList => adminList,
);

const selectAdminInfo = createDraftSafeSelector(
  (state: AdminState) => state.adminInfo,
  adminInfo => adminInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: AdminState) => state.actionResult,
  (state: AdminState) => state.isLoading,
  (state: AdminState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const adminSelector = {
  adminList: state => selectAdminList(state[ADMIN]),
  adminInfo: state => selectAdminInfo(state[ADMIN]),
  status: state => selectStatus(state[ADMIN]),
};

export const ADMIN = slice.name;
export const adminReducer = slice.reducer;
export const adminAction = slice.actions;
